import React, { useContext, useState } from "react";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { Add, Remove } from "../Icons/Icons";
import SweetAlert from "react-bootstrap-sweetalert";
import { Tooltip } from "../Utils/Tooltips";
import ProtocolContext from "../../containers/Protocols/ProtocolContext/ProtocolContext";

const ConfirmRemove = (props) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <SweetAlert
                warning
                title={t("protocols.visit.formTemplate.confirmRemove_title")}
                onConfirm={() => props.onConfirm(props.templateId)}
                onCancel={() => { props.onClose() }}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="info"
                confirmBtnText={t("buttons.yes")}
                cancelBtnText={t("buttons.no")}
                showCancel
            >
            </SweetAlert>
        </React.Fragment>
    );
}

export const DynamicFormComponents = (props) => {
    const { t } = useTranslation();
    const [modal, setModal] = useState(null);

    const handleRemove = (templateId) => {
        setModal(
            <ConfirmRemove
                onClose={() => setModal(null)}
                onConfirm={confirmRemove}
                templateId={templateId}
            />
        );
    }
    const confirmRemove = (templateId) => {
        setModal(null);
        props.onRemoveTemplate(templateId);
    }

    const context = useContext(ProtocolContext);
    if (context.isDisabled || props.isDisabled) return null;

    return (
        <div className="card">
            <div className="header">
                <h4 className="title">{t('protocols.visit.formTemplate.componentsTitle')}</h4>
            </div>
            <div className="content" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                {modal}
                {
                    props.templates.map(function (template, index) {
                        return (
                            <div className="btn-group btn-full" role="group" key={`templates${index}`}>
                                <Tooltip tooltip="Agregar plantilla al formulario" id="tooltipAddTemplate" style={{}}>
                                    <button key={`button${index}`} name={template.type} type="button" className="btn btn-sm btn-primary btn-fill"
                                        onClick={() => props.onAddComponentClick(template)} style={{ marginBottom: '5px', textAlign: 'left', width: '80%' }}>
                                        <Add /> {template.title}
                                    </button>
                                </Tooltip>

                                {
                                    !template.readOnly &&
                                    <Tooltip tooltip="Eliminar plantilla" id="tooltipRemoveTemplate" style={{}}>
                                        <button className="btn btn-sm btn-fill btn-danger" onClick={() => handleRemove(template.id)} style={{ width: '20%' }}>
                                            <Remove />
                                        </button>
                                    </Tooltip>
                                }
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

DynamicFormComponents.propTypes = {
    onAddComponentClick: PropTypes.func.isRequired,
    onRemoveTemplate: PropTypes.func.isRequired,
};