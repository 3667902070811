import React, { useContext, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from "react-router-dom";
import { removeVisit, swapVisits } from '../../../api/protocol';
import { WithAuth } from '../../../components/Authorization/WithAuthProtocol';
import Card from '../../../components/Cards/Card';
import { CustomSelect } from '../../../components/CustomControls/CustomSelect';
import { Add, Copy, Down, Edit, Remove, Up, View } from '../../../components/Icons/Icons';
import { HandleApiError } from "../../../components/Notifications/APIErrorHandler";
import { Success } from '../../../components/Notifications/Notifications';
import { OffsetTypes, VisitStages } from '../../../components/Protocols/Visit/Enums';
import { Tooltip } from "../../../components/Utils/Tooltips";
import ProtocolContext from '../ProtocolContext/ProtocolContext';
import { AddVisitModal, CopyVisitModal } from './CreateVisit';
import { EditVisitModal } from './EditVisit';
import { RemoveModal } from './RemoveVisit';
import { useAllVisits } from './useVisits';

export const ProtocolVisitsContainer = ({ protocolId }) => {
    const { t } = useTranslation();
    const context = useContext(ProtocolContext);
    let history = useHistory();
    const [setData, data, query, totalSize, isLoading, handleTableChange, handleFilterChange] = useAllVisits(protocolId);

    const columns = [
        {
            dataField: 'sortViews',
            text: '',
            isDummyField: true,
            headerClasses: 'header-swap-btn',
            formatter: function (_cell, _row, rowIndex, _formatExtraData) {
                if (context.isDisabled) return null;
                return (
                    <div key={"sort" + rowIndex}>
                        <WithAuth protocolId={protocolId} requiredPermission={["Visits.Add"]}>
                            {
                                data.length !== rowIndex + 1 ?
                                    <button
                                        type="button"
                                        className="btn-table-actions btn-swap"
                                        style={{ paddingRight: '1px' }}
                                        onClick={() => handleSwapVisit(rowIndex, rowIndex + 1)} >
                                        <Down />
                                    </button>
                                    : ""
                            }
                            {
                                0 !== rowIndex ?
                                    <button
                                        type="button"
                                        className="btn-table-actions btn-swap"
                                        style={{ paddingRight: '1px' }}
                                        onClick={() => handleSwapVisit(rowIndex, rowIndex - 1)} >
                                        <Up />
                                    </button>
                                    : ""
                            }
                        </WithAuth>
                    </div>
                )
            }
        },
        { dataField: 'order', text: 'Orden' },
        { dataField: 'title', text: 'Nombre' },
        { dataField: 'version', text: 'Versión' },
        {
            dataField: 'offset', text: 'Offset',
            formatter: function (cell, row) {
                return `${cell} ${OffsetTypes().find(x => x.id === row.offsetType)?.title}`;
            }
        },
        {
            dataField: 'stage', text: 'Etapa',
            formatter: function (cell) {
                return VisitStages().find(x => x.id === cell)?.title;
            }
        },
        {
            dataField: 'view',
            text: 'Acciones',
            isDummyField: true,
            headerClasses: 'header-visit-actions',
            classes: 'row-visit-actions',
            formatter: function (_cell, row, rowIndex) {
                return (
                    <div key={rowIndex}>
                        {
                            !context.isDisabled &&
                            <WithAuth protocolId={protocolId} requiredPermission={["Visits.Add"]}>
                                <Tooltip tooltip="Editar" id={"tooltipedit-" + row.crossVersionId}>
                                    <button onClick={() => handleEditVisit(row.crossVersionId)} className="btn-table-actions">
                                        <Edit />
                                    </button>
                                </Tooltip>
                                <Tooltip tooltip="Copiar" id={"tooltipcopy-" + row.crossVersionId}>
                                    <button onClick={() => handleCopyVisit(row.crossVersionId)} className="btn-table-actions">
                                        <Copy />
                                    </button>
                                </Tooltip>
                            </WithAuth>
                        }

                        <Tooltip tooltip="Ver" id={"tooltipconfig-" + row.crossVersionId}>
                            <NavLink to={`/admin/protocols/${row.protocolId}/visitsTemplate/${row.crossVersionId}`} className="btn-table-actions" activeClassName="active">
                                <View />
                            </NavLink>
                        </Tooltip>

                        {
                            !context.isDisabled &&
                            <WithAuth protocolId={protocolId} requiredPermission={["Visits.Add"]}>
                                <Tooltip tooltip="Eliminar" id={"tooltipremove-" + row.crossVersionId}>
                                    <button className="btn-remove btn-table-actions" onClick={() => handleRemoveVisit(row.crossVersionId)}>
                                        <Remove />
                                    </button>
                                </Tooltip>
                            </WithAuth>
                        }
                    </div>
                )
            }
        }
    ];

    const handleSwapVisit = (oldIndex, newIndex) => {
        swapVisits(protocolId, data[oldIndex].crossVersionId, data[newIndex].crossVersionId)
            .then(res => {
                // Hago swap en cliente. 
                let copiedVisits = JSON.parse(JSON.stringify(data));
                copiedVisits[oldIndex].order = newIndex;
                copiedVisits[newIndex].order = oldIndex;

                setData(copiedVisits.sort((function (a, b) { return a.order - b.order; })));
            })
            .catch(error => {
                HandleApiError(error);
            });
    };

    const [modal, setModal] = useState(null);
    const handleRemoveVisit = (visitId) => {
        console.log(visitId)
        setModal(<RemoveModal
            id={visitId}
            title="¿Está seguro?"
            onClose={() => { setModal(null); }}
            onConfirm={handleConfirmRemove} />)
    }
    const handleConfirmRemove = (visitId) => {
        if (visitId) {
            removeVisit(protocolId, visitId)
                .then(() => {
                    let copiedData = JSON.parse(JSON.stringify(data));
                    setData(copiedData.filter(obj => obj.crossVersionId !== visitId));
                    Success("protocolNotifications.visit_Removed");
                    setModal(null);
                }).catch(error => {
                    console.error(error);
                    HandleApiError(error);
                    setModal(null);
                });
        }
        setModal(null);
    }

    const handleAddVisit = () => {
        setModal(<AddVisitModal
            protocolId={protocolId}
            hide={() => setModal(null)}
            submit={() => history.push(`/admin/protocols/${protocolId}/visitsTemplate/`)}
            isShowing={true} />);
    }

    const handleEditVisit = (visitId) => {
        setModal(<EditVisitModal
            protocolId={protocolId}
            visitId={visitId}
            hide={() => setModal(null)}
            submit={() => history.push(`/admin/protocols/${protocolId}/visitsTemplate/${visitId}`)}
            isShowing={true} />);
    }

    const handleCopyVisit = (visitId) => {
        setModal(<CopyVisitModal
            protocolId={protocolId}
            visitId={visitId}
            hide={() => setModal(null)}
            submit={() => history.push(`/admin/protocols/${protocolId}/visitsTemplate/`)}
            isShowing={true} />);
    }

    return (
        <Card
            header={
                <div className="row">
                    <div className="col-md-2">
                        <WithAuth protocolId={protocolId} requiredPermission={["Visits.Add"]}>
                            <button onClick={handleAddVisit} className="btn btn-primary" disabled={context.isDisabled}>
                                <Add /> {t('protocols.visit.addVisit')}
                            </button>
                        </WithAuth>
                    </div>
                    <div className="col-md-8">
                    </div>
                    <div className="col-md-2">
                        <CustomSelect
                            name="stage"
                            placeholder={"Etapas"}
                            onChange={handleFilterChange}
                            isMulti={false}
                            isClearable={true}
                            options={VisitStages()} />
                    </div>

                </div>
            }
            content={
                <div>
                    {modal}
                    <BootstrapTable
                        classes="table-spaced table-spaced-small"
                        wrapperClasses="table-responsive"
                        bordered={false}
                        hover
                        remote
                        keyField='id'
                        data={data}
                        columns={columns}
                        noDataIndication={() => <>Sin Información</>}
                        loading={isLoading}
                        overlay={overlayFactory({ spinner: true })}
                        pagination={paginationFactory({
                            page: query.pagination.page,
                            sizePerPage: query.pagination.sizePerPage,
                            totalSize: totalSize,
                            hideSizePerPage: true,
                        })}

                        onTableChange={handleTableChange}
                    />
                </div>
            }
        />
    );
}