import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { useTranslation, withTranslation } from 'react-i18next';
import { putProcedure } from "../../../api/medicalRecord";
import { AuditModal, CreateAudit, CreateAuditModal } from "../../../components/AuditTrail/AuditModal";
import { ExpandableContent } from "../../../components/CustomControls/ExpandableContent";
import { ProcedureForm } from "../../../components/MedicalRecords/Forms/ProceduresForm";
import { ModalDuplicatedItem } from '../../../components/MedicalRecords/ModalDuplicatedItem';
import { Procedures } from "../../../components/MedicalRecords/Procedures";
import { getMedicalRecordsSchema } from "../../../components/Validations/FormValidationSchema";
import { AuditTrail } from "../../Admin/AuditTrail";
import { useProcedure, useProcedures } from "../Hooks/useProcedures";

export const ProceduresContainer = (props) => {
    const [content, setContent] = useState(null);
    const { medicalRecordNumber, entryStatus, entryId, expandedAll } = props;
    const [procedures, query, totalSize, isLoading, handleTableChange, setReload] = useProcedures(medicalRecordNumber, entryId, true);
    const { t } = useTranslation();

    useEffect(() => {
        if (props.contentToReload?.contentName === "procedures")
            setReload(true);
    }, [props.contentToReload]);

    const handleEdit = (id) => {
        setContent(<ProcedureEditContainer
            medicalRecordNumber={medicalRecordNumber}
            entryId={entryId}
            id={id}
            onSubmit={handleSubmit}
            onCancel={handleClose}
        />);
    }

    const handleSubmit = async (data) => {

        if (!data || !data.procedures || data.procedures.length === 0)
            return;

        if ((entryStatus !== "Published"  && entryStatus !== "AutoPublished") && entryId == data.procedures[0].entryId && (!data.procedures[0].originalId || data.procedures[0].id == data.procedures[0].originalId)) {
            onConfirmSubmit(data);
        } else {
            setContent(<CreateAuditModal
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmit(data, reason, comments)}
            />)
        }
    }

    const onConfirmSubmit = async (data, reason, comments) => {
        try {
            if (data && data.procedures && data.procedures.length > 0) {
                data.procedures.forEach(x => (x.reason && (x.reasonId = x.reason.id))); // Mapeo Reason
                let procedure = data.procedures[0];
                if (reason) {
                    procedure.auditReason = reason;
                    procedure.auditComments = comments;
                }
                await putProcedure(entryId, procedure.id, procedure);
                setContent(null);
                setReload(true);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleClose = () => {
        setContent(null);
    }

    const handleAuditTrail = (entityId) => {
        setContent(
            <AuditModal
                onClose={() => { setContent(null); }}
                title="Auditoría"
            >
                <AuditTrail entity="Procedures" entityId={entityId} medicalRecordNumber={medicalRecordNumber} />
            </AuditModal>
        )
    }

    if (totalSize === 0)
        return null;

    return (
        <ExpandableContent title={t("medicalRecords.procedures.title")} expandedAll={expandedAll} content="procedures">
            {content}
            <Procedures
                withCard={false}
                entryId={entryId}
                data={procedures}
                query={query}
                totalSize={totalSize}
                isLoading={isLoading}
                onTableChange={handleTableChange}
                onEdit={handleEdit}
                onAuditTrail={handleAuditTrail}
            />
        </ExpandableContent>
    );
}

export const ProcedureCreateContainer = (props) => {
    const { entryStatus, entryId, medicalRecordNumber, onSubmit } = props;
    const [, masterData, isLoading, create] = useProcedure(medicalRecordNumber, entryId);
    const [content, setContent] = useState(null);

    const handleSubmit = async (data) => {

        if (entryStatus === "Published" || entryStatus === "AutoPublished") {
            setContent(<CreateAudit
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmit(data, reason, comments)}
            />)
        }
        else {
            onConfirmSubmit(data);
        }
    }

    const onConfirmSubmit = async (data, reason, comments) => {
        try {
            if (reason) {
                data.auditReason = reason;
                data.auditComments = comments;
            }
            if (data && data.procedures && data.procedures.length > 0) {
                data.procedures.forEach(x => (x.reason && (x.reasonId = x.reason.id))); // Mapeo Reason

                const resp = await create(entryId, data);
                if (resp.data.length > 0)
                    setContent(<ModalDuplicatedItem
                        onHide={() => { setContent(null); onSubmit("procedures"); }}
                        items={resp.data}
                        item="procedures"
                        medicalRecordNumber={props.medicalRecordNumber}
                    />);
                else {
                    setContent(null);
                    onSubmit("procedures"); // Indico al padre sobre q contenido hacer Reload. 
                }

                //await create(entryId, data);
                //setContent(null);
                //onSubmit("procedures");
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleCancel = () => { props.hide() }

    if (!masterData || isLoading)
        return null;

    return (
        <>
            {
                content ??
                <ProcedureContainerFormik
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    events={masterData}
                />
            }
        </>

    );
}

const ProcedureEditContainer = (props) => {
    const { medicalRecordNumber, entryId, id, onSubmit } = props;
    const [data, masterData, isLoading, , update] = useProcedure(medicalRecordNumber, entryId, id);

    if (!data || !masterData || isLoading)
        return null;

    return (
        <ProcedureContainerFormik
            onSubmit={onSubmit}
            onCancel={props.onCancel}
            procedure={data}
            events={masterData}
        />
    );
}

const ProcedureContainerFormik = withTranslation()(withFormik({
    mapPropsToValues: (props) => (props.procedure && { procedures: [props.procedure] }),

    validationSchema: getMedicalRecordsSchema().proceduresEdit,

    handleSubmit: (values, formikBag) => {
        formikBag.props.onSubmit(values);
    },

    displayName: 'ProcedureForm',

})(ProcedureForm));

ProcedureContainerFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    procedure: PropTypes.object.isRequired,
};