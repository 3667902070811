import PropTypes from 'prop-types';
import React from 'react';
import { Col, ControlLabel, Panel, PanelGroup, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { boolFormatter, dateTimeFormatter, extractContentFromHTML, objectFormatter, timeFormatter, yesNoFormatter, yesNoNotApplyFormatter } from '../../components/Utils/Formatter';

export const Audit = ({ medicalRecordNumber, audit, fields }) => {
    const { t } = useTranslation();

    const _getActionName = (actionCode) => {
        switch (actionCode) {
            case 0: return t('auditTrail.actions.detached');
            case 1: return t('auditTrail.actions.unchanged');
            case 2: return t('auditTrail.actions.deleted');
            case 3: return t('auditTrail.actions.modified');
            case 4: return t('auditTrail.actions.added');
            default:
                return t('auditTrail.actions.detached');
        }
    }

    const _getLocaleSuffix = (entity) => {
        switch (entity) {
            case "Protocol": return "protocols.brief.";
            case "ProtocolPractitioner": return "protocols.practitioner.";
            case "VisitTrackingForm":
            case "VisitTrackingExtra": return "protocols.visitTrackingFields.";
            case "Entry": return "medicalRecords.";
            case "AdverseEvents":
            case "Drugs":
            case "Procedures":
            case "Vitals":
            case "Anthropometrics":
            case "FamilyHistory":
            case "PersonalHistory":
            case "PersonalHabits":
            case "LaboratoryResult":
            case "Prescriptions":
            case "PrescriptionsItems":
            case "Orders":
            case "OrdersItems":
                return "medicalRecords";
            default:
                return "";
        }
    }

    const _uncapitalize = (text) => {
        return text.toString().charAt(0).toLowerCase() + text.toString().slice(1);
    }

    const _uncapitalizeAll = (text) => {
        return text.replace(/^([A-Z])|(\.[A-Z])/g, function (_, g1, g2) {
            return (typeof g1 === 'undefined') ? g2.toLowerCase() : g1.toLowerCase();
        });
    }

    const _getValueFormatted = (item, value, index) => {
        //console.log(`item: ${JSON.stringify(item)} value: ${value}`)
        switch (item._t?.toLowerCase()) {
            case "timefield":
                return timeFormatter(value);
            case "datetimefield":
            case "modified":
            case "datetime":
                return dateTimeFormatter(value);
            case "yesnofield":
                return yesNoNotApplyFormatter(value, t);
            case "yesno":
                return yesNoFormatter(value, t);
            case "booleanfield":
            case "checkboxfield":
                return boolFormatter(value, t);
            case "selectasyncfield":
                return objectFormatter(value);
            case "array": {
                return value?.map((element, idx) => {
                    let rows = [];
                    for (var propertyName in element) {
                        if (!element[propertyName] || propertyName.includes("ToYear") || propertyName.includes("ToMonth") || propertyName.includes("ToDay") ||
                            propertyName.includes("FromYear") || propertyName.includes("FromMonth") || propertyName.includes("FromDay") ||
                            propertyName.includes("EntryId") || propertyName.includes("Created") || propertyName.includes("CreatedBy"))
                            continue;

                        var elementValue = "";
                        if (element[propertyName] instanceof Object) {
                            elementValue = objectFormatter(element[propertyName]);
                        }
                        else {
                            elementValue = element[propertyName];
                        }
                        rows.push(
                            <div key={`${index}-array-row-${idx}-${propertyName}`}>
                                {
                                    t(`${item.localeSuffix}.${_uncapitalize(propertyName)}`)
                                }
                                :
                                {
                                    elementValue
                                }
                            </div>
                        );
                    }

                    return rows;
                })
            }
            case "arrayfield": {
                if (!Array.isArray(value))
                    return value;

                return value?.map((component, idx) => {
                    let rows = [];
                    component.Fields.forEach((field, childIdx) => {
                        var elementValue = _getValueFormatted(field, field["Value"], "recursive"); 
                        
                        rows.push(
                            <div key={`${index}-array-field-row-${idx}-${childIdx}`}>
                                {field["Label"]}
                                :
                                {elementValue}
                            </div>
                        );
                    });

                    return rows;
                })
            }
            case "arrayofstrings": {
                return value?.map((element, idx) => {
                    return <div key={`${index}-array-row-${idx}-${element}`}>
                        {element}
                    </div>;
                })
            }
            case "adverseeventsopenitemsfield":
            case "drugsopenitemsfield":
            case "proceduresopenitemsfield": {
                if (!Array.isArray(value))
                    return value?.toString();

                const rows = value?.map((item, idx) => {
                    return <div key={`${index}-openitem-field-row-${idx}`}>
                        {item.Name}
                        :
                        {`${t(`medicalRecords.to`)} ${item.Closed.ToDay ?? "UNK"}/${item.Closed.ToMonth ?? "UNK"}/${item.Closed.ToYear}`}
                    </div>
                })
                return rows;
            }
            default: {
                if (value) {
                    if (value instanceof Object)
                        return objectFormatter(value);
                    else
                        return extractContentFromHTML(value.toString())
                }

                return "";
            }
        }
    }

    const _getFieldTypeFromName = (propertyName) => {
        switch (propertyName) {
            case "Created":
            case "Modified":
            case "StartOfTask":
            case "EndOfTask":
                return "DateTimeField"
            default:
                return null;
        }
    }

    const _getFieldName = (entityName, propertyName) => {
        switch (entityName) {
            case "Protocol": return t(`protocols.brief.${_uncapitalizeAll(propertyName)}`);
            case "ProtocolPractitioner": return t(`protocols.practitioner.${_uncapitalizeAll(propertyName)}`);
            case "VisitTracking": return t(`protocols.visitTrackingFields.${_uncapitalizeAll(propertyName)}`);
            case "VisitTrackingExtra": return t(`protocols.visitTrackingFields.${_uncapitalizeAll(propertyName)}`);
            default:
                return t(`${_getLocaleSuffix(entityName)}.${_uncapitalizeAll(entityName)}.${_uncapitalizeAll(propertyName)}`);;
        }
    }

    const Reason = ({ reason }) => {
        if (!reason || reason === "")
            return '';

        return (
            <Row>
                <Col sm={3}>
                    <ControlLabel>
                        {t('auditTrail.reason')}
                    </ControlLabel>
                </Col>
                <Col sm={9}>
                    {t(`auditTrail.reasons.${reason}`)}
                </Col>
            </Row>
        );
    }

    const Comments = ({ comments }) => {
        if (!comments || comments === "")
            return '';

        return (
            <Row>
                <Col sm={3}>
                    <ControlLabel>
                        {t('auditTrail.comments')}
                    </ControlLabel>
                </Col>
                <Col sm={9}>
                    {comments}
                </Col>
            </Row>);
    }

    const Visit = ({ visitId }) => {
        if (!visitId || visitId === 0 || !medicalRecordNumber)
            return null;

        const url = medicalRecordNumber && `/admin/medicalRecords/${medicalRecordNumber}/entry/${visitId}`;
        return (
            <Row>
                <Col sm={12}>
                    <ControlLabel>
                        <NavLink to={url} activeClassName="active">
                            Ir a visita
                        </NavLink>
                    </ControlLabel>
                </Col>
            </Row>);
    }

    const _getFieldNameType = (row, field, propertyName) => {
        const indexes = [...propertyName.matchAll(/\d+/g)];
        if (propertyName.includes(".Components[")) { // Updated item in arrayField. 
            const componentField = field.components[indexes[1]].fields[indexes[2]];
            row.fieldName = field.componentsType === "LaboratoryResults" ? field.components[indexes[1]].fields[0]?.value?.description : componentField.label;
            row._t = field.componentsType;
        }
        else
            if (propertyName.includes("].Components")) { // New item in ArrayField                     
                row.fieldName = t(`medicalRecords.${_uncapitalize(field.componentsType)}.title`);
                row._t = field._t;
            }
            else {
                row.fieldName = field.label;
                row._t = field._t;
            }
    }

    const _getFieldVisitTrackingFormMetadata = (row, propertyName) => {
        const indexes = [...propertyName.matchAll(/\d+/g)];
        if (!indexes || indexes.length === 0) return;

        const field = fields[indexes[0]];
        if (!field) return;

        switch (field._t) {
            case "AdverseEventsOpenItemsField":
            case "DrugsOpenItemsField":
            case "ProceduresOpenItemsField":
                if (!field.value[indexes[1]]) {
                    row.fieldName = "Item Cerrado";
                } else
                    row.fieldName = `${field.value[indexes[1]].name}: ${_getFieldName("VisitTracking", propertyName.slice(propertyName.lastIndexOf(".") + 1))}`;
                row._t = field._t;
                return;
            default:
                _getFieldNameType(row, field, propertyName);
                return;
        }
    }

    const auditItem = audit.map((item, idx) => {
        const oldValues = item.oldValues;
        const currentValues = item.newValues;

        var rows = [];

        for (var propertyName in currentValues) {
            var row = {
                before: oldValues[propertyName],
                current: currentValues[propertyName]
            };

            try {
                // Ignoro campos internos. 
                if (
                    propertyName.includes(".Id") || propertyName.includes("Id")
                    || propertyName.includes(".ConceptId")
                    || propertyName.includes(".FileExtension")
                    || propertyName.includes(".Path")
                    || propertyName.includes(".Code")
                    || propertyName.includes("ToYear") || propertyName.includes("ToMonth") || propertyName.includes("ToDay")
                    || propertyName.includes("FromYear") || propertyName.includes("FromMonth") || propertyName.includes("FromDay")
                    || propertyName.includes("DiagnosisYear") || propertyName.includes("DiagnosisMonth") || propertyName.includes("DiagnosisDay")
                    || propertyName.includes("CreatedBy")
                )
                    continue;

                switch (item.entityName.toLowerCase()) {
                    case "visittrackingform":
                        _getFieldVisitTrackingFormMetadata(row, propertyName);
                        break;
                    default: {
                        if (Array.isArray(currentValues[propertyName])) {
                            row.fieldName = t(`${_getLocaleSuffix(item.entityName)}${_uncapitalize(propertyName)}.title`);
                            row.localeSuffix = `${_getLocaleSuffix(item.entityName)}${_uncapitalize(propertyName)}`;
                            row._t = propertyName === "StudyTasks" || propertyName === "Keywords" ? "ArrayOfStrings" : "Array";
                        }
                        else {
                            propertyName = propertyName.replace(/\[\d*\]/g, '');
                            row.fieldName = _getFieldName(item.entityName, propertyName);
                            row._t = _getFieldTypeFromName(propertyName);
                        }
                    }
                        break;
                }

                rows.push(row);
            }
            catch (error) {
                console.log(error);
            }
        }

        return (
            <Panel key={`accordion-audit-item-${idx}`} eventKey={`accordion-audit-item-${idx}`}>
                <Panel.Heading>
                    <Panel.Title toggle>{t("auditTrail.date")}: {dateTimeFormatter(item.dateTime)}UTC {t("auditTrail.action")}: {_getActionName(item.auditAction)} {t("auditTrail.user")}: {item.userId}</Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                    {
                        rows.length > 0 &&
                        <div>
                            <Reason reason={item.reason} />
                            <Comments comments={item.comments} />
                            <Visit visitId={item.parentKey} />
                            <Table responsive key={`table-${idx}`}>
                                <thead>
                                    <tr>
                                        <th>{t("auditTrail.field")}</th>
                                        <th>{t("auditTrail.before")}</th>
                                        <th>{t("auditTrail.after")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        rows.map((row, idx) => {
                                            return (
                                                <tr key={`table-row-${idx}`}>
                                                    <td>{row.fieldName}</td>
                                                    <td>{_getValueFormatted(row, row.before, `before-${idx}`)}</td>
                                                    <td>{_getValueFormatted(row, row.current, `current-${idx}`)}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    }
                </Panel.Body>
            </Panel>
        );
    });

    return (
        <PanelGroup accordion id="accordion-audit-items">
            {auditItem}
        </PanelGroup>
    );
}

Audit.propTypes = {
    audit: PropTypes.array.isRequired,
    fields: PropTypes.array,
};