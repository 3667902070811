import React, { Fragment, useContext } from "react";
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types';
import { NavLink } from "react-router-dom";
import { Alert } from 'react-bootstrap'

import { visitStatus, visitStage, patientInProtocolStatus } from "../../../variables/Enums";
import Card from "../../Cards/Card";
import { Save, Publish, Cancel, Warning, Audit, Back, Next, Drug, Sync } from "../../Icons/Icons";
import { WithAuth } from "../../Authorization/WithAuthProtocol";
import ScrollMenu from 'react-horizontal-scrolling-menu';
import ProtocolContext from "../../../containers/Protocols/ProtocolContext/ProtocolContext";

export const FormMenu = (props) => {

    return (
        <Card
            title=""
            subtitle=""
            content={
                props.forms.map(function (form, index) {
                    return (
                        <button
                            key={`button${index}`}
                            type="button"
                            className={`btn ${form.id === props.selectedFormId ? "btn-primary" : "btn-default"} btn-full btn-fill`}
                            onClick={() => props.onClick(form.id)}
                            style={{ marginBottom: '5px', textAlign: 'left' }}>

                            {form.status === 1 ? <Save style={{ marginRight: '5px' }} /> : ''}
                            {props.formsWithErrors?.find(x => x.id === form.id) ? <Warning style={{ marginRight: '5px' }} /> : ''}
                            {form.title}
                        </button>
                    );
                })
            }
        />
    );
}

FormMenu.propTypes = {
    onClick: PropTypes.func.isRequired,
    forms: PropTypes.array.isRequired,
    formsWithErrors: PropTypes.array.isRequired,
    selectedFormId: PropTypes.number,
};

export const FormActions = (props) => {
    const {
        onBack,
        onSave,
        onSkip,
        onScreenFailure,
        onLostToFollowUp,
        onAuditTrail,
        onWithoutDrugs,
        onReSync,
        visit,
        hasVisitTracking,
        isSubmitting
    } = props;

    const { t } = useTranslation();
    const context = useContext(ProtocolContext);
    const protocolId = context.protocolId;

    if (!visit)
        return null;

    return (
        <Card
            title={t('medicalRecords.actions')}
            className="card-actions"
            content={
                <Fragment>
                    <WithAuth protocolId={protocolId} requiredPermission={["VisitTracking.Add"]}>
                        {
                            hasVisitTracking && //(visit.status !== visitStatus.Failed && visit.status !== visitStatus.Skipped) &&
                            <button
                                type="button"
                                className="btn btn-primary btn-full "
                                onClick={() => onSave()}
                                disabled={isSubmitting || context.isDisabled}
                            >
                                <Save style={{ marginRight: '5px' }} />{t('protocols.visitTracking.saveChanges')}
                            </button>
                        }
                    </WithAuth>
                    <WithAuth protocolId={protocolId} requiredPermission={["VisitTracking.Publish"]}>
                        {
                            hasVisitTracking && (visit.status !== visitStatus.Failed && visit.status !== visitStatus.Skipped && visit.status !== visitStatus.Completed) &&
                            <button
                                type="submit"
                                name="publish"
                                className="btn btn-success btn-full"
                                disabled={isSubmitting || context.isDisabled || visit.status == visitStatus.Skipped || visit.status == visitStatus.Failed ? true : false}
                            >
                                <Publish style={{ marginRight: '5px' }} />
                                {visit.status === visitStatus.Changed && t('protocols.visitTracking.publishChanges')}
                                {visit.status !== visitStatus.Changed && t('protocols.visitTracking.publish')}
                            </button>
                        }
                        {
                            visit.status === visitStatus.Completed &&
                            <button
                                type="button"
                                name="publish"
                                className="btn btn-warning btn-full"
                                disabled={isSubmitting || context.isDisabled || visit.status !== visitStatus.Completed}
                                onClick={onReSync}
                            >
                                <Sync style={{ marginRight: '5px' }} />
                                {t('protocols.visitTracking.resync')}
                            </button>
                        }
                    </WithAuth>

                    <WithAuth protocolId={protocolId} requiredPermission={["VisitTracking.Add", "VisitTracking.Publish"]}>
                        <>
                            {
                                visit.stage !== visitStage.Random && (visit.status === undefined || visit.status === 0) && hasVisitTracking &&
                                <button
                                    type="button"
                                    className="btn btn-warning btn-full "
                                    onClick={onSkip}
                                    disabled={isSubmitting || context.isDisabled || visit.status >= 1 ? true : false}
                                >
                                    <Cancel style={{ marginRight: '5px' }} />{t('protocols.visitTracking.skipVisitTracking')}
                                </button>
                            }
                            {
                                visit.stage === visitStage.Screening && (visit.status === undefined || visit.status === 0) && hasVisitTracking &&
                                <button
                                    type="button"
                                    className="btn btn-danger btn-full "
                                    onClick={onScreenFailure}
                                    disabled={isSubmitting || context.isDisabled || visit.status >= 1 ? true : false}
                                >
                                    <Warning style={{ marginRight: '5px' }} />{t('protocols.visitTracking.screenFailure')}
                                </button>
                            }
                            {
                                (visit.stage === visitStage.Treatment || visit.stage === visitStage.FollowUp) && (visit.status === undefined || visit.status === 0) && hasVisitTracking &&
                                <button
                                    type="button"
                                    className="btn btn-danger btn-full "
                                    onClick={onLostToFollowUp}
                                    disabled={isSubmitting || context.isDisabled || visit.status >= 1 ? true : false}
                                >
                                    <Warning style={{ marginRight: '5px' }} />{t('protocols.visitTracking.lostToFollowUp')}
                                </button>
                            }

                            {
                                (visit.stage > visitStage.Random) && (visit.status === undefined || visit.status === 0) && hasVisitTracking && (props.patientInProtocolStatus !== patientInProtocolStatus.WithoutDrugs) &&
                                <button
                                    type="button"
                                    className="btn btn-info btn-full "
                                    onClick={onWithoutDrugs}
                                    disabled={isSubmitting || context.isDisabled || visit.status >= 1 ? true : false}
                                >
                                    <Drug style={{ marginRight: '5px' }} />{t('protocols.visitTracking.withoutDrugs')}
                                </button>
                            }
                        </>
                    </WithAuth>
                    <button className={`btn btn-primary btn-fill btn-full`} onClick={onBack} disabled={isSubmitting}>
                        <Back /> {t('buttons.back')}
                    </button>
                    {
                        <button className={`btn btn-primary btn-fill btn-full`} onClick={props.onBackToMR} disabled={isSubmitting}>
                            <Back /> Ir a H.C.
                        </button>
                    }

                    <button
                        type="button"
                        disabled={isSubmitting}
                        className="btn btn-audit btn-fill btn-full"
                        onClick={() => onAuditTrail()}
                    >
                        <Audit style={{ marginRight: '5px' }} />{t('protocols.visitTracking.auditTrail')}
                    </button>
                </Fragment>
            }
        />
    );
}

FormActions.propTypes = {
    onSave: PropTypes.func.isRequired,
    onSkip: PropTypes.func.isRequired,
    onScreenFailure: PropTypes.func.isRequired,
    onLostToFollowUp: PropTypes.func.isRequired,
    onWithoutDrugs: PropTypes.func.isRequired,
    onAuditTrail: PropTypes.func.isRequired,
    visit: PropTypes.object.isRequired,
    hasVisitTracking: PropTypes.bool.isRequired,
};

export const FormHeader = (props) => {
    const {
        protocolId,
        patientId,
        visits
    } = props;

    if (!visits)
        return <Card
            className="card-visitTracking-header"
            noHeader
            content={<></>}
        />;

    const Menu = () =>
        visits.map((visit, index) => {
            return (
                <div key={`header-visits-${index}`} className={"header-visit-container " + getNavLinkClass(`/admin/protocols/${protocolId}/visitTracking/${visit.crossVersionId}/patient/${patientId}`)}>
                    <NavLink to={`/admin/protocols/${protocolId}/visitTracking/${visit.crossVersionId}/patient/${patientId}`} className="nav-link" activeClassName="active">
                        {visit.title}
                    </NavLink>
                </div>
            );
        });

    const ArrowLeft = <Back />;
    const ArrowRight = <Next />;

    const getNavLinkClass = (path) => {
        return window.location.pathname === path ? 'active' : '';
    }

    return (
        <Card
            className="card-visitTracking-header"
            noHeader
            content={
                <ScrollMenu
                    data={Menu()}
                    arrowLeft={ArrowLeft}
                    arrowRight={ArrowRight}
                    selected={null}
                    onSelect={null}
                    hideSingleArrow={true}
                />
            }
        />
    );
}

FormHeader.propTypes = {
    protocolId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    visits: PropTypes.array,
};

export const VisitStatus = ({ status }) => {
    //const { t } = useTranslation();
    switch (status) {
        case visitStatus.Started:
            return (<Alert bsStyle="info">Visita Comenzada</Alert>);
        case visitStatus.Completed:
            return (<Alert bsStyle="success">Visita Completada</Alert>);
        case visitStatus.Skipped:
            return (<Alert bsStyle="warning">Visita Salteada</Alert>);
        case visitStatus.Failed:
            return (<Alert bsStyle="danger">Visita con Falla</Alert>);
        case visitStatus.Changed:
            return (<Alert bsStyle="warning">Visita con cambios pendientes de publicación</Alert>);
        case visitStatus.AutoCompleted:
            return (<Alert className="montoto">Visita Cerrada por Tiempo</Alert>);
        default:
            return null;
    }
}

VisitStatus.propTypes = {
    status: PropTypes.string,
};