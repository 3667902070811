import { ErrorMessage } from 'formik';
import React from 'react';
import { Col, ControlLabel, FormGroup, FormControl, Grid, Row } from 'react-bootstrap';
import { FormErrorMessage } from '../../Validations/FormValidationErrors';

export const AnthropometricsForm = props => {

    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        t
    } = props;

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <FormGroup>
                <Grid fluid>
                    <Row>
                        <Col componentClass={ControlLabel} sm={4}>
                            <ControlLabel>{t("medicalRecords.anthropometrics.weight")}</ControlLabel>
                        </Col>
                        <Col sm={8}>
                            <FormControl autoFocus type="text" name={`weight`} value={values.weight || null} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                            <ErrorMessage name={`weight`} component={FormErrorMessage} />
                        </Col>
                    </Row>
                    <Row>
                        <Col componentClass={ControlLabel} sm={4}>
                            <ControlLabel>{t("medicalRecords.anthropometrics.height")}</ControlLabel>
                        </Col>
                        <Col sm={8}>
                            <FormControl type="text" name={`height`} value={values.height || null} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                            <ErrorMessage name={`height`} component={FormErrorMessage} />
                        </Col>
                    </Row>
                    <Row>
                        <Col componentClass={ControlLabel} sm={4}>
                            <ControlLabel>{t("medicalRecords.anthropometrics.waist")}</ControlLabel>
                        </Col>
                        <Col sm={8}>
                            <FormControl type="text" name={`waist`} value={values.waist || null} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                        </Col>
                    </Row>
                    <Row>
                        <Col componentClass={ControlLabel} sm={4}>
                            <ControlLabel>{t("medicalRecords.anthropometrics.sagittal")}</ControlLabel>
                        </Col>
                        <Col sm={8}>
                            <FormControl type="text" name={`sagittal`} value={values.sagittal || null} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                        </Col>
                    </Row>
                    <Row>
                        <Col componentClass={ControlLabel} sm={4}>
                            <ControlLabel>{t("medicalRecords.anthropometrics.neck")}</ControlLabel>
                        </Col>
                        <Col sm={8}>
                            <FormControl type="text" name={`neck`} value={values.neck || null} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                        </Col>
                    </Row>
                    <Row>
                        <Col componentClass={ControlLabel} sm={4}>
                            <ControlLabel>{t("medicalRecords.anthropometrics.others")}</ControlLabel>
                        </Col>
                        <Col sm={8}>
                            <FormControl type="text" name={`others`} value={values.others || null} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <ControlLabel>{t("medicalRecords.anthropometrics.notes")}</ControlLabel>
                            <FormControl componentClass="textarea" value={values.notes || null} name={`notes`} onChange={handleChange} />
                        </Col>
                    </Row>
                </Grid>
            </FormGroup>
            <button type="button" className="btn btn-default" onClick={props.onCancel} disabled={isSubmitting}>{t("buttons.cancel")}</button>
            <button type="submit" className="btn btn-success btn-right" disabled={isSubmitting}>{t("buttons.save")}</button>
            <div className="clearfix"></div>
        </form>
    );
}