import PropTypes from 'prop-types'
import React from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { useTranslation } from 'react-i18next'

export const ModalConfirm = ({ title, onConfirm, onCancel }) => {
    const { t } = useTranslation();

    return (
        <SweetAlert
            warning
            title={title}
            onConfirm={onConfirm}
            onCancel={onCancel}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="info"
            confirmBtnText={t("buttons.yes")}
            cancelBtnText={t("buttons.no")}
            showCancel
        >
        </SweetAlert>
    );
}

ModalConfirm.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired
};