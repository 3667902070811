import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from "react";
import { withTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { createUser } from "../../api/identity";
import { useAuthorization } from '../../components/Authorization/useAuthorization';
import { HandleApiError } from '../../components/Notifications/APIErrorHandler';
import { Success } from '../../components/Notifications/Notifications';
import { UserForm } from "../../components/Users/Users";
import { Spinner } from '../../components/Utils/Loaders';
import { getUserSchema } from "../../components/Validations/FormValidationSchema";
import { useRoleMasterData } from "../Security/Authorization/useRoles";
import { useTenants } from '../Tenants/Hooks';

export const CreateUserContainer = () => {
    let history = useHistory();
    const [isLoading, data] = useRoleMasterData();
    const [tenants, isLoadingTenants, getAll] = useTenants();

    const [,, isAuthorized] = useAuthorization();

    useEffect(() => {
        let isSubscribed = true;
        const fetchData = async () => { await getAll(); }

        if (isAuthorized(["Tenant.List"]))
            fetchData();

        return () => isSubscribed = false;
    }, []);

    if (isLoading || isLoadingTenants) return <Spinner />;

    return (
        <CreateUserFormik
            onSubmit={() => history.push(`/admin/users`)}
            onCancel={() => history.goBack()}//(`/admin/users`)}
            roles={data ? data.roles : []}
            tenants={tenants ? tenants : []}
        />
    );
}

const CreateUserFormik = withTranslation()(withFormik({

    mapPropsToValues: () => ({
        given: null, 
        familyName: null,
        userName: null, 
        role: null, tenant: null, workEmail: null
    }),
    enableReinitialize: true,
    validationSchema: getUserSchema(),

    handleSubmit: (user, formikBag) => {
        createUser(user)
            .then(res => {
                formikBag.setSubmitting(false);
                Success("userNotifications.user_Created");
                formikBag.props.onSubmit();
            }).catch(error => {
                formikBag.setSubmitting(false);
                HandleApiError(error);
                //formikBag.props.onCancel();
            });
    },

    displayName: 'UserForm',

})(UserForm));

CreateUserFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    roles: PropTypes.array.isRequired,
    tenants: PropTypes.array.isRequired,
};