import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ExpandableContent } from "../../../components/CustomControls/ExpandableContent";
import { PersonalHistory } from "../../../components/MedicalRecords/PersonalHistory";
import { usePersonalHistories } from "../Hooks/usePersonalHistory";
import { AuditModal } from "../../../components/AuditTrail/AuditModal";
import { AuditTrail } from "../../Admin/AuditTrail";

export const PersonalHistoryListContainer = ({ entryId, medicalRecordNumber, expandedAll }) => {
    const { t } = useTranslation();
    const [personalHistory, query, totalSize, isLoading, handleTableChange] = usePersonalHistories(medicalRecordNumber, entryId);

    const [modal, setModal] = useState(null);
    const handleAuditTrail = (entityId) => {
        setModal(
            <AuditModal
                onClose={() => { setModal(null); }}
                title="Auditoría"
            >
                <AuditTrail entity="PersonalHistory" entityId={entityId} medicalRecordNumber={medicalRecordNumber} />
            </AuditModal>
        )
    }

    if (totalSize === 0)
        return null;
        
    return (
        <ExpandableContent title={t("medicalRecords.personalHistory.title")} content="personalHistory" expandedAll={expandedAll}>
            {modal}
            <PersonalHistory
                withCard={false}
                data={personalHistory}
                query={query}
                totalSize={totalSize}
                isLoading={isLoading}
                onTableChange={handleTableChange}
                onAuditTrail={handleAuditTrail}
            />
        </ExpandableContent>

    );
}