import React from "react";
import { MenuItem, NavDropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import avatar from "../../assets/img/default-avatar.png";
import { ApplicationPaths } from "../api-authorization/ApiAuthorizationConstants";

export const AdminNavbarUser = (props) => {
    const { user } = props;
    const settings = useSelector(state => state.settings.settings);
    const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };

    return (
        <>
            <NavDropdown
                eventKey={4}
                title={
                    <div className="pull-left">
                        <img src={avatar} alt="Avatar" className="current-user-image" />

                        {user.family_name ? <>{user.family_name} {user.given_name}</> : user.email}
                    </div>
                }
                //noCaret
                id="current-user-navbar"
                bsClass="current-user-dropdown dropdown"
            >
                <MenuItem eventKey={4.1} href={`/admin/users/${user.sub}`}>
                    Mi Perfil
                </MenuItem>
                <MenuItem eventKey={4.2} href={`/admin/users/${user.sub}/edit`}>
                    Editar Perfil
                </MenuItem>
                <MenuItem eventKey={4.3} href={`${settings?.identityApi}/Account/ChangePassword?returnUrl=${settings?.callBackUrl}`}>
                    Cambiar Contraseña
                </MenuItem>
                <MenuItem divider />
                <li role="presentation">
                    <NavLink to={logoutPath} role="menuitem">
                        Cerrar Sesión
                    </NavLink>
                </li>
            </NavDropdown>
        </>
    );
}