import moment from 'moment';
import React from 'react';
import { Disabled, Enabled } from '../Icons/Icons';
import { dateFormatter as date, labelFormatter as label } from '../Utils/Formatter';


export const dateFormatter = (cell, row, rowIndex, formatExtraData) => {
  return date(cell, 'DD/MM/YYYY');
}

export const dateMMMFormatter = (cell, row, rowIndex, formatExtraData) => {
  return date(cell, 'DD/MMM/YYYY');
}

export const dateTimeFormatter = (cell, row, rowIndex, formatExtraData) => {
  return (<div className="table-date"> {moment(cell).format('DD/MM/YYYY HH:mm')} </div>);
}

export const labelFormatter = (cell, row, rowIndex, formatExtraData) => {
  return label(cell);
}

export const statusFormatter = (cell, row, rowIndex, formatExtraData) => {

  if (cell)
    return (<div className="btn-table-actions"><Enabled className="status-active" /></div>);

  return (<div className="btn-table-actions"><Disabled className="status-inactive" /></div>);
}

export const customDateTimeFormatter = (dateTime) => {
  return (<>{moment(dateTime).format('DD/MM/YYYY HH:mm')} </>);
}
