import React from "react";
import { Nav } from "react-bootstrap";
import { LoginMenu } from "../api-authorization/LoginMenu";
import { useAuthorization } from "../Authorization/useAuthorization.js";
import AdminNavbarNotificationsContainer from "./AdminNavbarNotifications";
import { AdminNavbarUser } from "./AdminNavbarUser";

const HeaderLinks = () => {

  const [isAuthenticated, user] = useAuthorization();

  return (
    <div>
      <Nav pullRight>
        <AdminNavbarNotificationsContainer />
        <LoginMenu />
        {
          isAuthenticated && <AdminNavbarUser user={user} />
        }
      </Nav>
    </div>
  );
}
export default HeaderLinks;
