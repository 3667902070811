import React from "react";
import { useState } from "react";
import { AdverseEvents } from "../../../components/MedicalRecords/AdverseEvents";
import { useAdverseEvents } from "../Hooks/useAdverseEvents";
import { AuditModal } from "../../../components/AuditTrail/AuditModal";
import { AuditTrail } from "../../Admin/AuditTrail";
import { useTranslation } from "react-i18next";
import { ExpandableContent } from "../../../components/CustomControls/ExpandableContent";

export const AdverseEventsListContainer = ({ medicalRecordNumber, entryId, expandedAll }) => {
    const { t } = useTranslation();
    const [adverseEvents, query, totalSize, isLoading, handleTableChange,] = useAdverseEvents(medicalRecordNumber, entryId, false);

    const [modal, setModal] = useState(null);
    const handleAuditTrail = (entityId) => {
        setModal(
            <AuditModal
                onClose={() => { setModal(null); }}
                title="Auditoría"
            >
                <AuditTrail entity="AdverseEvents" entityId={entityId} medicalRecordNumber={medicalRecordNumber} />
            </AuditModal>
        )
    }

    if (totalSize === 0)
        return null;
        
    return (
        <ExpandableContent title={t("medicalRecords.adverseEvents.title")} content="adverseEvents" expandedAll={expandedAll}>
            {modal}
            <AdverseEvents
                withCard={false}
                data={adverseEvents}
                query={query}
                totalSize={totalSize}
                isLoading={isLoading}
                onTableChange={handleTableChange}
                onAuditTrail={handleAuditTrail}
            />
        </ExpandableContent>
    );
}