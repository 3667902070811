import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';
import { changePatientNumber, getVisitsTracking } from '../../../api/protocol';
import '../../../assets/sass/lbd/_visits-tracking.scss';
import { CreateAuditModal } from '../../../components/AuditTrail/AuditModal';
import { WithAuth } from '../../../components/Authorization/WithAuthProtocol';
import Card from '../../../components/Cards/Card';
import { CustomSearchBox } from '../../../components/CustomControls/CustomSearchBox';
import { Add, Edit } from '../../../components/Icons/Icons';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';
import { PatientStatusInProtocol } from '../../../components/Protocols/Patients';
import { VisitTrackingStageFilter, VisitTrackingStatusFilter } from '../../../components/Protocols/Tracking/Filters';
import { AddPatientModal } from '../Patients/AddPatients';
import { PatientNumberModal } from '../Patients/PatientNumber';
import ProtocolContext from '../ProtocolContext/ProtocolContext';
import { getVisitsTrackingColumns, getVisitsTrackingGrid } from './Services';

export const VisitsTrackingContainer = props => {
    const { t } = useTranslation();
    const { protocolId } = props;
    let history = useHistory();
    const context = useContext(ProtocolContext);

    const firstColumns = [
        {
            dataField: 'numberInProtocol', text: t('protocols.patient.nro'), sort: true, headerStyle: { width: '150px' }, formatter: function (cell, row, rowIndex) {
                return <>
                    {cell}
                    <WithAuth protocolId={protocolId} requiredPermission={["Patients.Edit"]}>
                        <button onClick={() => handlePatientNumberChange(row)} className="btn-table-actions">
                            <Edit style={{ height: '15px', width: '15px' }} />
                        </button>
                    </WithAuth>

                </>;
            }
        },
        {
            dataField: 'fullName', text: 'Int.', sort: true, headerStyle: { width: '150px' }, formatter: function (cell, row, rowIndex) {
                return <NavLink to={`/admin/medicalRecords/${row.medicalRecordNumber}`} key={`patient-${rowIndex}`}>
                    {cell}
                </NavLink>
            }
        },
        {
            dataField: 'statusInProtocol', text: 'Status', headerStyle: { width: '150px' }, formatter: function (cell, _row, rowIndex) {
                return (<PatientStatusInProtocol key={`statusrow-${rowIndex}`} status={cell} />)
            }
        },
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [modal, setModal] = useState(null);
    const [reload, setReload] = useState(false);
    const [columns, setColumns] = useState(firstColumns);
    const [selectedStage, setSelectedStage] = useState(null);
    const [data, setData] = useState([]);
    const [totalSize, setTotalSize] = useState(0);
    const [query, setQuery] = useState({
        pagination: {
            page: 1,
            sizePerPage: 10,
            totalSize: 0
        },
        sort: {
            sortField: 'numberinprotocol',
            //sortOrder: 'desc'
        },
        filters: {}
    });

    const [visitTemplates, setVisitTemplates] = useState([]);
    const [patientsData, setPatientsData] = useState([]);
    useEffect(() => {
        const fetchData = () => {
            setReload(false);
            getVisitsTracking(protocolId, query)
                .then(response => {
                    var visitTracking = response.data
                    //console.log(response.data)
                    let visits = visitTracking.visits;
                    setVisitTemplates(visits);
                    let patients = [];

                    const currentPatient = new URLSearchParams(props.location.search).get("patient");
                    if (currentPatient) {
                        patients = visitTracking.patients.data.filter(patient => (patient.medicalRecordNumber === currentPatient));
                    } else {
                        patients = visitTracking.patients.data;
                    }

                    setPatientsData(patients);

                    // Armo columnas a mostrar.
                    const cols = getVisitsTrackingColumns(protocolId, visits, history, t);
                    let allcolumns = firstColumns.concat(cols);
                    setColumns(allcolumns);
                    //console.log(allcolumns)
                    // Armo grilla
                    var data = getVisitsTrackingGrid(patients, visits);
                    setTotalSize(visitTracking.patients.count);
                    setData(data);
                    //console.log(data)
                })
                .catch(error => {
                    console.log(error);
                    HandleApiError(error);
                    setIsLoading(false);
                })
        }

        fetchData();
    }, [protocolId, query, reload]);

    const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {

        if (type === 'pagination') {
            let newQuery = JSON.parse(JSON.stringify(query));

            newQuery.pagination.page = page;
            newQuery.pagination.sizePerPage = sizePerPage;

            setQuery(newQuery);
        }
        if (type === 'sort') {
            let newQuery = JSON.parse(JSON.stringify(query));

            newQuery.sort.sortField = sortField;
            newQuery.sort.sortOrder = sortOrder;

            setQuery(newQuery);
        }
    }

    const handleFilterChange = (filterName, selectedValue) => {
        if (filterName) {

            if (filterName === "patientNumber") {
                var newQuery = JSON.parse(JSON.stringify(query));
                newQuery.filters[filterName] = selectedValue;
                setQuery(newQuery);
            }
            if (filterName === "status") {
                var newQuery = JSON.parse(JSON.stringify(query));
                newQuery.filters[filterName] = selectedValue != null ? selectedValue.id : selectedValue;
                setQuery(newQuery);
            }
            if (filterName === "stage") {
                setSelectedStage(selectedValue);

                let filteredVisits = [];
                if (selectedValue != null)
                    filteredVisits = visitTemplates.filter(x => x.stage === selectedValue.id);
                else
                    filteredVisits = visitTemplates;


                // Armo columnas a mostrar.
                const cols = getVisitsTrackingColumns(protocolId, filteredVisits, history, t);
                let allcolumns = firstColumns.concat(cols);
                setColumns(allcolumns);
                // Armo grilla
                var data = getVisitsTrackingGrid(patientsData, filteredVisits);
                setTotalSize(patientsData.count);
                setData(data);
            }
        }
    }

    const handleAddPatient = () => {
        setModal(<AddPatientModal
            protocolId={protocolId}
            hide={() => setModal(null)}
            submit={() => { setReload(true); setModal(null) }}
            isShowing={true} />);
    }

    const handlePatientNumberChange = (patient) => {
        setModal(<PatientNumberModal
            hide={() => setModal(null)}
            submit={(value) => { handleAuditChange(value) }}
            isShowing={true}
            patient={patient}
        />);
    }

    const handleAuditChange = (value) => {
        setModal(<CreateAuditModal
            onClose={() => setModal(null)}
            onSave={(reason, comments) => confirmChangePatientNumberSubmit(value, reason, comments)}
        />)
    }

    const confirmChangePatientNumberSubmit = async (patient, reason, comments) => {
        try {
            patient.audit = {
                reason: reason,
                comments: comments
            }
            await changePatientNumber(protocolId, patient.medicalRecordNumber, patient);
            setModal(null);
            setReload(true);
        }
        catch (error) {
            console.log(error);
            HandleApiError(error);
            setModal(null)
        }
    }

    return (
        <>
            <Card
                header={
                    <Fragment>
                        <Row>
                            <WithAuth protocolId={protocolId} requiredPermission={["Patients.Add"]}>
                                <Col md={3}>
                                    <button onClick={handleAddPatient} className="btn btn-primary" disabled={context.isDisabled}>
                                        <Add /> {t('protocols.patient.addPatientTitle')}
                                    </button>
                                </Col>
                            </WithAuth>
                            <Col md={3}>
                                <CustomSearchBox handleSearch={(value) => handleFilterChange("patientNumber", value)} placeholder={t('protocols.visitTracking.searchByNumber')}></CustomSearchBox>
                            </Col>
                            <Col md={3}>
                                <VisitTrackingStatusFilter onChangeFilter={handleFilterChange} />
                            </Col>
                            <Col md={3}>
                                <VisitTrackingStageFilter onChangeFilter={handleFilterChange} selectedStage={selectedStage} />
                            </Col>
                        </Row>
                    </Fragment>
                }
                content={
                    <>
                        {modal}

                        <BootstrapTable
                            classes="table-spaced table-spaced-small"
                            wrapperClasses="table-responsive visit-tracking-table"
                            bordered={false}
                            hover
                            striped
                            condensed
                            remote
                            keyField='medicalRecordNumber'
                            data={data}
                            columns={columns}
                            pagination={paginationFactory({
                                page: query.pagination.page,
                                sizePerPage: query.pagination.sizePerPage,
                                totalSize: totalSize,
                                hideSizePerPage: true,
                            })}

                            onTableChange={handleTableChange}
                            noDataIndication={() => <>Sin datos</>}
                            loading={isLoading}  //only loading is true, react-bootstrap-table will render overlay
                            overlay={overlayFactory({ spinner: true })}
                        />
                    </>
                }
            />
        </>
    );
}