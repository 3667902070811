import React, { useContext } from "react";
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { Add, Sync } from "../Icons/Icons";
import ProtocolContext from "../../containers/Protocols/ProtocolContext/ProtocolContext";

export const DynamicFormControls = (props) => {
    const { formFieldTypes, onAddFormFieldControlClick, onAddControlClick } = props;
    const fieldTypes = [
        'DividerField', 'TextField', 'SingleLineField', 'MultiLineField', 'IntegerField', 'DecimalField', 'YesNoField', 'BooleanField', 'DateField', 'TimeField', 'DateTimeField', 'CheckboxField',
        'MultiCheckBoxField', 'RadioField', 'SelectField', 'SelectAsyncField', 'SnomedField',
        'FileUploadField', 'AdverseEventsAsyncField'
    ];

    const context = useContext(ProtocolContext);
    if (context.isDisabled || props.isDisabled) return null;

    return (
        <div className="card">
            <div className="header">
                <h4 className="title">{i18n.t("protocols.visit.controls.title")}</h4>
            </div>
            <div className="content" style={{ maxHeight: '400px', overflowY: 'scroll' }}>
                {
                    formFieldTypes?.map(function (type, index) {
                        return (<button key={`button-formTypeFields${index}`} name={type.value} type="button"
                            className="btn btn-sm btn-success btn-full btn-fill"
                            onClick={() => onAddFormFieldControlClick(type)}
                            style={{ marginBottom: '5px', textAlign: 'left' }}>
                            <Sync /> {i18n.t(`protocols.visit.formTemplate.formTypeFields.${type.value}`)}
                        </button>);
                    })
                }
                {
                    fieldTypes.map(function (type, index) {
                        return (<button key={`button${index}`} name={type} type="button"
                            className="btn btn-sm btn-primary btn-full btn-fill"
                            onClick={onAddControlClick}
                            style={{ marginBottom: '5px', textAlign: 'left' }}>
                            <Add /> {i18n.t(`protocols.visit.controls.${type}`)}
                        </button>);
                    })
                }
            </div>
        </div>
    );
}

DynamicFormControls.propTypes = {
    onAddControlClick: PropTypes.func.isRequired,
    onAddFormFieldControlClick: PropTypes.func,
    formFieldTypes: PropTypes.array
};