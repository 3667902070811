import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    getVisitTrackingForm, getVisitTrackingForms, lostToFollowUpVisitTracking, publishVisitTracking, reSyncVisitTrackingAPI, saveVisitTracking, screenFailureVisitTracking, skipVisitTracking,
    uploadVisitTrackingAttachmentFormEndpoint, getVisitTrackingAttachmentFormEndpoint,
    withoutDrugsVisitTracking
} from '../../../api/protocol';
import { AuditModal, CreateAuditModal } from '../../../components/AuditTrail/AuditModal';
import { useAuthProtocol } from '../../../components/Authorization/useAuthorization';
import { WithAuth } from '../../../components/Authorization/WithAuthProtocol';
import { DocumentViewer } from '../../../components/CustomControls/DocViewer/DocumentViewer';
import { useFiles } from '../../../components/CustomControls/Files/Hooks';
import { SignWithOutInfo } from '../../../components/DigitalSign/Sign';
import { DynamicForm } from '../../../components/DynamicForm/DynamicForm';
import { MedicalRecord } from '../../../components/Icons/Icons';
import { ModalSpinner } from '../../../components/Modals/ModalSpinner';
import { HandleApiError, HandleMessageError } from '../../../components/Notifications/APIErrorHandler';
import { Success } from '../../../components/Notifications/Notifications';
import { Entry } from '../../../components/Protocols/Tracking/Entry';
import { FormActions, FormMenu, VisitStatus } from '../../../components/Protocols/Tracking/Forms';
import { ConfirmModal, LostToFollowUp, ScreenFailure, SkipVisitTracking, WithoutDrugs } from '../../../components/Protocols/Tracking/Modals';
import { Slider } from '../../../components/Slider/Slider';
import { Spinner } from '../../../components/Utils/Loaders';
import { getValidationSchema } from '../../../components/Validations/FormValidationSchema';
import { formStatus, visitStatus } from "../../../variables/Enums";
import { AuditTrail } from "../../Admin/AuditTrail";
import { ModalDigitalSign } from '../../DigitalSign/ModalDigitalSign';
import { EntryItemsListTabs } from '../../MedicalRecords/MedicalRecordItems/EntryItemsList';
import DocumentNotesContainer from '../DocumentNotes/DocumentNotes';
import { useAudits } from '../Hooks';
import MonitorNotesContainer from '../MonitorNotes/MonitorNotes';
import ProtocolContext from '../ProtocolContext/ProtocolContext';

export const VisitTracking = ({ protocolId, patientId, visitId }) => {
    let history = useHistory();
    const { t } = useTranslation();
    const [isAuthorized, isAuthLoading] = useAuthProtocol(protocolId);
    const settingsStore = useSelector(state => state.settings);
    const context = useContext(ProtocolContext);
    const [fileData, getBlobAPI, postAPI] = useFiles();

    const [modal, setModal] = useState(null);
    const [showHistory, setShowHistory] = useState(false);
    const [hasVisitTracking, setHasVisitTracking] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [forms, setForms] = useState([]);
    const [visitTracking, setVisitTracking] = useState(null);
    const [selectedForm, setSelectedForm] = useState(null);
    const [formsWithErrors, setFormsWithErrors] = useState([]);
    const [patientInfo, setPatientInfo] = useState(null);
    const [, , getLastChange] = useAudits("VisitTrackingForm", selectedForm?.id);
    const [, , getLastVisitTrackingChange] = useAudits("VisitTracking", visitTracking?.id);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            try {
                const response = await getVisitTrackingForms(protocolId, visitId, patientId);

                var visitTracking = response.data;
                var urlParams = new URLSearchParams(window.location.search);
                let formAux = visitTracking.forms[0];
                if (urlParams.has('formId') && visitTracking.forms.find(x => x.id == urlParams.get('formId'))) {
                    formAux = visitTracking.forms.find(x => x.id == urlParams.get('formId'));
                }
                formAux.text = visitTracking.visit.text;

                setSelectedForm(formAux);
                setForms(visitTracking.forms);
                setVisitTracking(visitTracking.visit);
                setHasVisitTracking(visitTracking.hasVisitTracking);
                setPatientInfo({
                    patientNumber: visitTracking.patientNumber,
                    patientInProtocolStatus: visitTracking.patientInProtocolStatus,
                    medicalRecordNumber: visitTracking.visit.patientId
                });
                setIsLoading(false);
            }
            catch (error) {
                console.log(error);
                HandleApiError(error);
                setIsLoading(false);
            }
        }

        fetchData();
    }, [visitId]);

    const handleSelectForm = (formId, isDirty) => {
        if (!isDirty || (isDirty && window.confirm("Hay cambios sin guardar, está seguro que desea continuar?"))) {
            setIsLoading(true);
            let selectedForms = forms.filter(x => { return x.id === formId });
            let formAux = selectedForms.length > 0 ? selectedForms[0] : {};
            formAux.text = selectedForm.text;
            setSelectedForm(formAux);
            setIsLoading(false);
        }
    }

    const _closeModal = () => { setModal(null); }

    const _updateData = (visit, updatedForm) => {
        if (!updatedForm || !updatedForm.id) {
            window.location.reload(false)
            return;
        }

        updatedForm.text = visit.text;
        let formsCopy = JSON.parse(JSON.stringify(forms));
        var index = formsCopy.findIndex(x => x.id === updatedForm.id);
        formsCopy.splice(index, 1, updatedForm); //Reemplazo form.
        setVisitTracking(visit);
        setForms(formsCopy);
        setSelectedForm(updatedForm);

        _closeModal();
        Success("protocolNotifications.visitTracking_FormSaved");
    }

    const _saveVisit = async (formVisitTracking, formikProps) => {
        try {
            if (formikProps.isSubmitting)
                return;

            formikProps.setSubmitting(true);
            setModal(<ModalSpinner isShowing={true} hide={null} />);

            const res = await saveVisitTracking(protocolId, visitId, patientId, formVisitTracking);
            const form = res.data.form;
            const visit = res.data.visit;

            // Upload attachments if any. 
            const fieldsWithFiles = formVisitTracking.patientForm.fields.filter(x => x._t === "FileUploadField" && x.value.file);
            if (fieldsWithFiles && fieldsWithFiles.length > 0) {
                try {
                    const attachs = fieldsWithFiles.map(x => x.value);
                    postAPI(attachs, uploadVisitTrackingAttachmentFormEndpoint(protocolId, visitId, form.id, patientId))
                        .then(() => {
                            getVisitTrackingForm(protocolId, visitId, form.id)
                                .then(resForm => {
                                    // Actualizo el form en FrontEnd.
                                    _updateData(visit, resForm?.data);
                                }).catch((error) => {
                                    console.log(error)
                                    window.location.reload(false)
                                })
                        }).catch((error) => { console.log(error) });
                }
                catch (error) {
                    console.log(error.response);
                }
            }
            else {
                _updateData(visit, form);
            }
        }
        catch (error) {
            _closeModal();
            console.log(error);
            HandleApiError(error);
        }
        finally {
            formikProps.setSubmitting(false);
        }
    }

    const _publishVisit = async (digitalSign, actions) => {
        try {
            setModal(<ModalSpinner isShowing={true} hide={null} />);
            const res = await publishVisitTracking(protocolId, visitId, patientId, digitalSign);
            _closeModal();
            setVisitTracking(res.data);
            actions.setSubmitting(false);
            Success("protocolNotifications.visitTracking_VisitPublished");
        }
        catch (error) {
            _closeModal();
            actions.setSubmitting(false);
            console.log(error);
            HandleApiError(error);
        }
    }

    const handleSaveVisit = (formVisitTracking, formikProps) => {
        if (visitTracking.status !== visitStatus.Started || formVisitTracking.status !== formStatus.Initial) {
            setModal(<CreateAuditModal
                onClose={_closeModal}
                onSave={(reason, comments) => _saveVisit({ patientForm: formVisitTracking, audit: { reason: reason, comments: comments } }, formikProps)}
            />)
        }
        else {
            _saveVisit({ patientForm: formVisitTracking }, formikProps);
        }
    }

    const handleSubmitFormik = (_formVisitTracking, actions) => {
        if (settingsStore.settings.digitalSignEnabled)
            setModal(<ModalDigitalSign
                isShowing={true}
                hide={() => { setModal(null); actions.setSubmitting(false); }}
                onSubmit={(pin) => _publishVisit(pin, actions)}
            />);

        else
            _publishVisit({ password: "" }, actions)
    }

    const handleSubmitForm = (evt, formikBag) => {
        evt.preventDefault();
        if (!formikBag.dirty || (formikBag.dirty && window.confirm("Hay cambios sin guardar, está seguro que desea continuar?"))) {
            // Valido errores en todos los formularios. 
            let withErrors = [];
            let showErrorValidations = false;
            forms.forEach(form => {
                if (!getValidationSchema().isValidSync(form)) {
                    withErrors.push({ id: form.id, title: form.title });
                    if (form.id === formikBag.values.id)
                        showErrorValidations = true;
                }
            });

            if (withErrors.length > 0) {
                setFormsWithErrors(withErrors);
                HandleMessageError("Los siguientes formularios poseen datos requeridos incompletos: " + withErrors.map(e => e.title).join(' - '));
                if (showErrorValidations)
                    formikBag.submitForm();
            } else {
                formikBag.submitForm();
            }
        }
    }

    const handleSkipVisit = (formikBag) => {

        if (!formikBag.dirty || (formikBag.dirty && window.confirm("Hay cambios sin guardar, está seguro que desea continuar?"))) {
            setModal(<SkipVisitTracking
                onClose={_closeModal}
                onConfirm={() => confirmSkipVisit(formikBag)}
            />)
        }
    }

    const confirmSkipVisit = (formikBag) => {
        if (settingsStore.settings.digitalSignEnabled)
            setModal(<ModalDigitalSign
                isShowing={true}
                isLoading={isLoading}
                hide={() => { setModal(null); formikBag.setSubmitting(false); }}
                onSubmit={(pin) => _publishSkipVisit(pin, formikBag)}
            />);

        else
            _publishSkipVisit({ password: "" }, formikBag)
    }

    const _publishSkipVisit = async (digitalSign, formikBag) => {
        try {
            setIsLoading(true);
            const res = await skipVisitTracking(protocolId, visitId, patientId, digitalSign);
            setIsLoading(false);
            _closeModal();
            setVisitTracking(res.data);
            Success("protocolNotifications.visitTracking_FormSaved");
            formikBag.setSubmitting(false);
        }
        catch (error) {
            _closeModal();
            formikBag.setSubmitting(false);
            console.log(error);
            HandleApiError(error);
            setIsLoading(false);
        }
    }

    const handleScreenFailure = (formikBag) => {

        if (!formikBag.dirty || (formikBag.dirty && window.confirm("Hay cambios sin guardar, está seguro que desea continuar?"))) {
            setModal(<ScreenFailure
                onClose={() => { setModal(null); formikBag.setSubmitting(false); }}
                onConfirm={() => confirmScreenFailure(formikBag)}
            />)
        }
    }

    const confirmScreenFailure = (formikBag) => {
        if (settingsStore.settings.digitalSignEnabled)
            setModal(<ModalDigitalSign
                isShowing={true}
                isLoading={isLoading}
                hide={() => { setModal(null); formikBag.setSubmitting(false); }}
                onSubmit={(pin) => _publishScreenFailure(pin, formikBag)}
            />);
        else
            _publishScreenFailure({ password: "" }, formikBag)
    }

    const _publishScreenFailure = async (digitalSign, formikBag) => {
        try {
            setIsLoading(true);
            const res = await screenFailureVisitTracking(protocolId, visitId, patientId, digitalSign);
            _closeModal();
            setIsLoading(false);
            setVisitTracking(res.data);
            Success("protocolNotifications.visitTracking_FormSaved");
            formikBag.setSubmitting(false);
        }
        catch (error) {
            setIsLoading(false);
            _closeModal();
            formikBag.setSubmitting(false);
            console.log(error);
            HandleApiError(error);
        }
    }

    const handleLostToFollowUp = (formikBag) => {

        if (!formikBag.dirty || (formikBag.dirty && window.confirm("Hay cambios sin guardar, está seguro que desea continuar?"))) {
            setModal(<LostToFollowUp
                onClose={() => { setModal(null); formikBag.setSubmitting(false); }}
                onConfirm={() => confirmLostToFollowUp(formikBag)}
            />)
        }
    }

    const confirmLostToFollowUp = (formikBag) => {
        if (settingsStore.settings.digitalSignEnabled)
            setModal(<ModalDigitalSign
                isShowing={true}
                isLoading={isLoading}
                hide={() => { setModal(null); formikBag.setSubmitting(false); }}
                onSubmit={(pin) => _publishLostToFollowUp(pin, formikBag)}
            />);
        else
            _publishLostToFollowUp({ password: "" }, formikBag)
    }

    const _publishLostToFollowUp = async (digitalSign, formikBag) => {
        try {
            setIsLoading(true);
            const res = await lostToFollowUpVisitTracking(protocolId, visitId, patientId, digitalSign);
            _closeModal();
            setIsLoading(false);
            setVisitTracking(res.data);
            Success("protocolNotifications.visitTracking_FormSaved");
            formikBag.setSubmitting(false);
        }
        catch (error) {
            setIsLoading(false);
            _closeModal();
            formikBag.setSubmitting(false);
            console.log(error);
            HandleApiError(error);
        }
    }

    const handleWithoutDrugs = async (formikBag) => {
        setModal(<WithoutDrugs
            onClose={() => { setModal(null); formikBag.setSubmitting(false); }}
            onConfirm={() => _setWithoutDrugs(formikBag)}
        />)
    }

    const _setWithoutDrugs = async (formikBag) => {
        try {
            setIsLoading(true);
            const res = await withoutDrugsVisitTracking(protocolId, visitId, patientId);
            _closeModal();
            setIsLoading(false);
            setVisitTracking(res.data);
            Success("protocolNotifications.visitTracking_FormSaved");
            formikBag.setSubmitting(false);
        }
        catch (error) {
            setIsLoading(false);
            _closeModal();
            formikBag.setSubmitting(false);
            console.log(error);
            HandleApiError(error);
        }
    }

    const handleAuditTrail = () => {
        setModal(
            <AuditModal
                onClose={_closeModal}
                title="Auditoría"
            >
                Campos:
                <AuditTrail entity="VisitTrackingForm" entityId={selectedForm.id} fields={selectedForm && selectedForm.fields} />

                Evolución:
                <AuditTrail entity="VisitTracking" entityId={visitTracking.id} />
            </AuditModal>
        )
    }

    const handleViewFile = async (attachName) => {
        try {
            await getBlobAPI(attachName, getVisitTrackingAttachmentFormEndpoint(protocolId, visitId, selectedForm.id, attachName));
        }
        catch (error) {
            console.error(error);
            HandleApiError(error);
        }
    }

    const handleReSync = () => {
        setModal(<ConfirmModal
            onClose={_closeModal}
            onConfirm={confirmReSync}
            title={t("protocolNotifications.visitTracking_reSync_title")}
            description={t("protocolNotifications.visitTracking_reSync_description")}
        />);
    }

    const confirmReSync = async () => {
        try {
            setIsLoading(true);
            const res = await reSyncVisitTrackingAPI(protocolId, visitId, patientId);
            _closeModal();
            setIsLoading(false);
            Success("protocolNotifications.visitTracking_reSyncOk");
        }
        catch (error) {
            setIsLoading(false);
            _closeModal();
            console.log(error);
            HandleApiError(error);
        }
    }

    if (!visitTracking || !selectedForm || !forms || isLoading || settingsStore.isLoading || isAuthLoading)
        return <Spinner />;

    const isDisabled = !isAuthorized(["VisitTracking.Add"]) || context.isDisabled || !hasVisitTracking;
    const showStatus = ((!isAuthorized(["VisitTracking.Add"]) && visitTracking?.status !== visitStatus.Started) || isAuthorized(["VisitTracking.Add"]));

    return (
        <>
            {modal}
            <DocumentViewer
                file={fileData?.blob}
                fileName={fileData?.fileName}
                inModal={true}
                isLoading={fileData?.isLoading}
            />
            <Formik
                initialValues={selectedForm}
                validationSchema={getValidationSchema}
                onSubmit={handleSubmitFormik}
                enableReinitialize={true}
                validateOnBlur={true}
                validateOnChange={true}
            >
                {props => (
                    <form onReset={props.handleReset} onSubmit={(evt) => handleSubmitForm(evt, props)}>

                        <Row>
                            <Col md={4} lg={3} className="sticky">
                                <Row>
                                    <Col md={12}>
                                        <FormMenu
                                            forms={forms}
                                            onClick={(formId) => handleSelectForm(formId, props.dirty)}
                                            selectedFormId={selectedForm?.id}
                                            formsWithErrors={formsWithErrors}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormActions
                                            visit={visitTracking}
                                            hasVisitTracking={hasVisitTracking}
                                            patientInProtocolStatus={patientInfo.patientInProtocolStatus}
                                            onBack={(evt) => { evt.preventDefault(); history.push(`/admin/protocols/${protocolId}/section/visitTracking`) }} // history.goBack();
                                            onSave={() => handleSaveVisit(props.values, props)}
                                            onSkip={() => handleSkipVisit(props)}
                                            onLostToFollowUp={() => handleLostToFollowUp(props)}
                                            onScreenFailure={() => handleScreenFailure(props)}
                                            onWithoutDrugs={() => handleWithoutDrugs(props)}
                                            onReSync={handleReSync}
                                            onAuditTrail={handleAuditTrail}
                                            onBackToMR={() => history.push(`/admin/medicalRecords/${visitTracking.patientId}`)}
                                            isSubmitting={props.isSubmitting}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4} lg={5}>
                                {showStatus && visitTracking && <VisitStatus status={visitTracking?.status} />}
                                <DynamicForm
                                    {...props}
                                    patientId={visitTracking.patientId}
                                    patientNumber={patientInfo.patientNumber}
                                    visitId={visitId}
                                    protocolId={protocolId}
                                    onDownload={handleViewFile}
                                    isDisabled={isDisabled}
                                    getLastChange={getLastChange}
                                />
                                <WithAuth protocolId={protocolId} requiredPermission={["InternalNotes.List"]}>
                                    {
                                        hasVisitTracking &&
                                        <DocumentNotesContainer
                                            protocolId={protocolId}
                                            documentId={selectedForm.id}
                                            visitId={visitId}
                                            patientId={patientInfo.patientNumber}
                                        />
                                    }
                                </WithAuth>
                                {/*
                                    visitTracking.status === visitStatus.Started && selectedForm &&
                                    <AutoSavingForm
                                        initialValues={selectedForm}
                                        valuesToSave={props.values}
                                        setInitialValues={setSelectedForm}
                                        onSave={autoSaveVisit}
                                        formikProps={props}
                                    />
                                */}
                            </Col>
                            <Col md={4} lg={4} className="sticky">
                                <button type="button"
                                    className="btn btn-primary btn-fill btn-full"
                                    onClick={() => { setShowHistory(!showHistory) }}
                                    style={{ marginBottom: '22px', textAlign: 'center' }}>
                                    <MedicalRecord /> Ver Historia Clínica
                                </button>
                                <Entry
                                    {...props}
                                    audit={getLastVisitTrackingChange("VisitText")}
                                    isDisabled={isDisabled}
                                    title="Evolución del Paciente"
                                    subtitle={patientInfo.patientNumber}
                                />
                                {
                                    (visitTracking?.status === visitStatus.Completed || visitTracking?.status === visitStatus.Skipped || visitTracking?.status === visitStatus.Failed) &&
                                    <SignWithOutInfo signedBy={visitTracking.createdById} created={visitTracking.created} />
                                }
                                <WithAuth protocolId={protocolId} requiredPermission={["ExternalNotes.List"]}>
                                    {
                                        hasVisitTracking &&
                                        <MonitorNotesContainer
                                            protocolId={protocolId}
                                            documentId={selectedForm.id}
                                            visitId={visitId}
                                            patientId={patientInfo.patientNumber}
                                        />
                                    }
                                </WithAuth>
                            </Col>
                            <Slider isOpen={showHistory}>
                                <Grid fluid>
                                    <button type="button"
                                        className="btn btn-primary btn-fill btn-full"
                                        onClick={() => { setShowHistory(!showHistory) }}
                                        style={{ margin: '22px 0', textAlign: 'center' }}>
                                        <MedicalRecord /> Ocultar Historia Clínica
                                    </button>
                                    <EntryItemsListTabs medicalRecordNumber={visitTracking.patientId} />
                                </Grid>
                            </Slider>
                        </Row>
                    </form>
                )}
            </Formik>
        </>
    );
}