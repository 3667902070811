import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import { exportToExcel } from '../../api/database';
import { WithAuth } from '../../components/Authorization/WithAuth';
import { Card } from '../../components/Cards/Card';
import { CustomSearchBox } from '../../components/CustomControls/CustomSearchBox';
import { CustomSelect } from '../../components/CustomControls/CustomSelect';
import { PractitionerSelect } from '../../components/CustomControls/PractitionerSelect';
import { ExpandedContent } from '../../components/Database/ExpandedContent';
import ExportToExcel from "../../components/Export/ExportToExcel";
import { Add, AddAppointment, View, ViewMR } from '../../components/Icons/Icons';
import { Tooltip } from '../../components/Utils/Tooltips';
import { useProtocols } from '../Protocols/Hooks';
import { usePatients } from './Hooks'; import Tippy from '@tippyjs/react';

export const Patients = () => {
    const { t } = useTranslation();

    const columns = [
        { dataField: 'medicalRecordNumber', text: 'N° de HC', sort: true, headerStyle: { width: '100px' } },
        { dataField: 'fullName', text: 'Nombre y Apellido', sort: true, classes: 'w900' },
        { dataField: 'cellPhone', text: 'Teléfono' },
        { dataField: 'medicalInsurance', text: 'Obra Social', sort: false },
        { dataField: 'medicalInsuranceNumber', text: 'N° Afiliado', sort: false },
        {
            dataField: 'view', text: 'Acciones', isDummyField: true,
            headerClasses: 'header-view-btn', classes: 'row-view-btn',
            formatter: function (cell, row, rowIndex) {
                return (
                    <div key={rowIndex}>
                        <Tooltip tooltip={t('database.viewMR')} id={"tooltipadd-" + row.medicalRecordNumber}>
                            <NavLink to={`/admin/medicalRecords/${row.medicalRecordNumber}`} activeClassName="active">
                                <ViewMR />
                            </NavLink>
                        </Tooltip>
                        <Tooltip tooltip={t('medicalRecords.actionNewAppointment')} id={"tooltipaddAppointment-" + row.medicalRecordNumber}>
                            <NavLink to={`/admin/agenda?medicalRecordNumber=${row.medicalRecordNumber}`} activeClassName="active">
                                <AddAppointment />
                            </NavLink>
                        </Tooltip>
                        <Tooltip tooltip={t('database.view')} id={"tooltipadd-" + row.medicalRecordNumber}>
                            <NavLink to={`/admin/database/${row.medicalRecordNumber}`} activeClassName="active">
                                <View />
                            </NavLink>
                        </Tooltip>
                    </div>
                )
            }
        }
    ];

    const expandedContent = (row) => (<ExpandedContent data={row} />)

    const [type, setType] = useState("PROTOCOL");
    const [query, setQuery] = useState({
        q: "",
        projects: [],
        protocols: [],
        professionals: [],
        type: {},
        pagination: {
            page: 1,
            sizePerPage: 10,
            totalSize: 0
        },
        sort: {
            sortField: "FamilyName",
            sortOrder: "asc"
        }
    });

    const [patients, isLoadingPatients, totalSize] = usePatients(query);
    const [protocols, , , isLoadingProtocols] = useProtocols();

    const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {

        var newQuery = {};
        if (type === 'pagination') {
            newQuery = JSON.parse(JSON.stringify(query));

            newQuery.pagination.page = page;
            newQuery.pagination.sizePerPage = sizePerPage;

            setQuery(newQuery);
        }

        if (type === 'sort') {
            newQuery = JSON.parse(JSON.stringify(query));

            newQuery.sort.sortField = sortField;
            newQuery.sort.sortOrder = sortOrder;

            setQuery(newQuery);
        }
    }

    const handleSearch = (q) => {
        var newQuery = JSON.parse(JSON.stringify(query));
        newQuery.q = q;
        setQuery(newQuery);
    }

    const handleProjectChange = (name, selectedValues) => {
        var newQuery = JSON.parse(JSON.stringify(query));
        newQuery.protocols = [];
        newQuery.projects = selectedValues;
        setQuery(newQuery);
    }

    const handleProtocolChange = (name, selectedValues) => {
        var newQuery = JSON.parse(JSON.stringify(query));
        newQuery.protocols = selectedValues;
        newQuery.projects = [];
        setQuery(newQuery);
    }

    const handleProfessionalChange = (name, selectedValues) => {
        var newQuery = JSON.parse(JSON.stringify(query));
        newQuery.professionals = selectedValues;
        setQuery(newQuery);
    }


    return (
        <Card
            header={
                <>
                    <Row>
                        <Col md={2}>
                            <WithAuth requiredPermission={["Database.Create"]}>
                                <NavLink to="/admin/database/create" className="btn btn-primary" activeClassName="active">
                                    <Add /> <span> {t('database.addPatient')}</span>
                                </NavLink>
                            </WithAuth>
                        </Col>
                        {/*<Col md={2}>
                            <CustomRadio
                                number="PROTOCOL"
                                option="PROTOCOL"
                                name="type"
                                onChange={handleTypeChange}
                                checked={type === "PROTOCOL"}
                                label="Protocolo"
                            />
                            <CustomRadio
                                disabled={true}
                                number="PROJECT"
                                option="PROJECT"
                                name="type"
                                onChange={handleTypeChange}
                                checked={type === "PROJECT"}
                                label="Proyecto"
                            />
                        </Col>*/}
                        {
                            type === "PROTOCOL" ?
                                <Col md={2}>
                                    <CustomSelect name="protocols" placeholder={t('filters.protocol')} isLoading={isLoadingProtocols} isMulti={true} options={protocols} onChange={handleProtocolChange} />
                                </Col>
                                :
                                <Col md={2}>
                                    <CustomSelect placeholder={t('filters.project')} isMulti={true} options={[]} onChange={handleProjectChange} />
                                </Col>
                        }

                        <Col md={2}>
                            <PractitionerSelect
                                name={`practitioners`}
                                placeholder={t('filters.professional')}
                                onChange={handleProfessionalChange}
                                isMulti={true}
                            />
                        </Col>
                        <Col md={3} mdOffset={1}>
                            <CustomSearchBox handleSearch={handleSearch} placeholder={t('database.patientSearch')}></CustomSearchBox>
                        </Col>
                        <Col md={2}>
                            <ExportToExcel filters={query} exportToExcel={exportToExcel} />
                        </Col>
                    </Row>
                </>
            }
            content={
                <BootstrapTable
                    bordered={false}
                    striped
                    hover
                    remote
                    keyField='medicalRecordNumber'
                    classes="table-spaced table-spaced-small"
                    wrapperClasses="table-responsive"

                    data={patients}
                    columns={columns}
                    expandRow={{
                        renderer: expandedContent,
                        onlyOneExpanding: true,
                    }}
                    pagination={paginationFactory({
                        page: query.pagination.page,
                        sizePerPage: query.pagination.sizePerPage,
                        totalSize: totalSize,
                        hideSizePerPage: true,
                        //sizePerPageRenderer
                    })}
                    loading={isLoadingPatients}
                    overlay={overlayFactory({ spinner: true })}
                    noDataIndication={() => <>Sin datos</>}
                    onTableChange={handleTableChange}
                />
            }
        />
    );
}