import { ErrorMessage } from 'formik';
import React from 'react';
import { Col, ControlLabel, FormGroup, FormControl, Grid, Row } from 'react-bootstrap';
import { FormErrorMessage } from '../../Validations/FormValidationErrors';

export const VitalsForm = props => {

    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        t
    } = props;

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <FormGroup>
                <Grid fluid>
                    <Row>
                        <Col componentClass={ControlLabel} sm={4}>
                            <ControlLabel>{t("medicalRecords.vitals.bloodPressureHigh")}</ControlLabel>
                        </Col>
                        <Col sm={8}>
                            <FormControl autoFocus type="text" name={`bloodPressureHigh`} value={values.bloodPressureHigh || null} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                            <ErrorMessage name={`bloodPressureHigh`} component={FormErrorMessage} />
                        </Col>
                    </Row>
                    <Row>
                        <Col componentClass={ControlLabel} sm={4}>
                            <ControlLabel>{t("medicalRecords.vitals.bloodPressureLow")}</ControlLabel>
                        </Col>
                        <Col sm={8}>
                            <FormControl type="text" name={`bloodPressureLow`} value={values.bloodPressureLow || null} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                            <ErrorMessage name={`bloodPressureLow`} component={FormErrorMessage} />
                        </Col>
                    </Row>
                    <Row>
                        <Col componentClass={ControlLabel} sm={4}>
                            <ControlLabel>{t("medicalRecords.vitals.heartRate")}</ControlLabel>
                        </Col>
                        <Col sm={8}>
                            <FormControl type="text" name={`heartRate`} value={values.heartRate || null} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                            <ErrorMessage name={`heartRate`} component={FormErrorMessage} />
                        </Col>
                    </Row>
                    <Row>
                        <Col componentClass={ControlLabel} sm={4}>
                            <ControlLabel>{t("medicalRecords.vitals.respiratoryRate")}</ControlLabel>
                        </Col>
                        <Col sm={8}>
                            <FormControl type="text" name={`respiratoryRate`} value={values.respiratoryRate || null} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                            <ErrorMessage name={`respiratoryRate`} component={FormErrorMessage} />
                        </Col>
                    </Row>
                    <Row>
                        <Col componentClass={ControlLabel} sm={4}>
                            <ControlLabel>{t("medicalRecords.vitals.temperatureC")}</ControlLabel>
                        </Col>
                        <Col sm={8}>
                            <FormControl type="text" name="temperatureC" value={values.temperatureC || null} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                            <ErrorMessage name={`temperatureC`} component={FormErrorMessage} />
                        </Col>
                    </Row>
                    <Row>
                        <Col componentClass={ControlLabel} sm={4}>
                            <ControlLabel>{t("medicalRecords.vitals.oxygenSaturation")}</ControlLabel>
                        </Col>
                        <Col sm={8}>
                            <FormControl type="text" name={`oxygenSaturation`} value={values.oxygenSaturation || null} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                            <ErrorMessage name={`oxygenSaturation`} component={FormErrorMessage} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <ControlLabel>{t("medicalRecords.vitals.notes")}</ControlLabel>
                            <FormControl componentClass="textarea" value={values.notes || null} name={`notes`} onChange={handleChange} />
                        </Col>
                    </Row>
                </Grid>
            </FormGroup>
            <button type="button" className="btn btn-default" onClick={props.onCancel} disabled={isSubmitting}>{t("buttons.cancel")}</button>
            <button type="submit" className="btn btn-success btn-right" disabled={isSubmitting} >{t("buttons.save")}</button>
            <div className="clearfix"></div>
        </form >
    );
}