import moment from 'moment';
import React, { useEffect, useState } from 'react';

const DateTimeWidget = () => {

    const [today, setDate] = useState(new Date());

    useEffect(() => {
        const timer = setInterval(() => { // Creates an interval which will update the current data every minute
            // This will trigger a rerender every component that uses the useDate hook.
            setDate(new Date());
        }, 60 * 1000);
        return () => {
            clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
        }
    }, []);

    return (
        <div className="datetime-widget">
            <div className="date">{moment(today).format("dddd DD [de] MMMM [de] YYYY")}</div>
            <div className="time">{moment(today).format("HH:mm")}</div>
        </div>
    );
}


export default DateTimeWidget;
