import React from 'react';
import { toast } from 'react-toastify';
import i18n from 'i18next';

const Msg = ({ closeToast, message }) => (
    <div>
        <span className="badge"><i className="pe-7s-info rbc-event-confirmed"></i></span> {message}
    </div>
)
const MsgWithInfo = ({ message, info }) => (
    <div>
        <span className="badge"><i className="pe-7s-info rbc-event-confirmed"></i></span> {message}: {info}
    </div>
)

export function Success(messageKey) {

    let message = i18n.t(messageKey);

    toast.success(<Msg message={message} />);
}

export function SuccessWithInfo(messageKey, info) {

    let message = i18n.t(messageKey);

    toast.success(<MsgWithInfo message={message} info={info} />);
}