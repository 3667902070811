import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DailyAgendaWidget from '../../components/Dashboard/DailyAgendaWidget';
import { ModalConfirm } from '../../components/Modals/ModalConfirm';
import { HandleApiError } from '../../components/Notifications/APIErrorHandler';
import { agendaActionCreators } from '../../store/Agenda';

const WaitingListWidgetContainer = () => {
    const [modal, setModal] = useState(null);
    const { t } = useTranslation();
    const agendaStore = useSelector(state => state.agenda);
    const dispatch = useDispatch();

    useEffect(() => {
        let isSubscribed = true;

        const initComponent = async () => {
            if (isSubscribed)
                await dispatch(agendaActionCreators.requestWaitingList());
        }

        initComponent();

        return () => isSubscribed = false;
    }, []); //Uso el cambio de notifications, para hacer reload de eventos. TODO: ¿Pasar sala de espera a redux?

    const handleAttendance = async (eventId) => {
        if (!eventId)
            return;

        setModal(<ModalConfirm
            title={t('calendar.attended')}
            onConfirm={() => { dispatch(agendaActionCreators.requestMarkAsAttended(eventId)); setModal(null) }}
            onCancel={() => { setModal(null) }}
        />);
    }

    if (!agendaStore.isLoading && agendaStore.error) {
        HandleApiError(agendaStore.error);
        dispatch(agendaActionCreators.clearErrors());
    }

    return (<>
        {modal}
        <DailyAgendaWidget events={agendaStore.events} isLoading={agendaStore.isLoading} onAttendance={handleAttendance} />
    </>);

}

export default WaitingListWidgetContainer;
