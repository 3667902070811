import React, { useEffect, useState } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { getForms, getVisit, getVisitHistory, printVisit, publishVisit, removeVisit, swapForms } from '../../../api/protocol';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';
import { Success } from '../../../components/Notifications/Notifications';
import { Actions } from '../../../components/Protocols/Actions';
import { ProtocolInfo } from '../../../components/Protocols/ProtocolInfo';
import { VisitForms } from '../../../components/Protocols/Visit/Forms';
import { VisitHeader } from '../../../components/Protocols/Visit/Header';
import { VisitHistory } from '../../../components/Protocols/Visit/VisitHistory';
import { useProtocolApprovedVisits } from '../Hooks';
import { ProtocolProvider } from '../ProtocolContext/ProtocolProvider';
import { EditVisitModal } from './EditVisit';
import { PublishModal, RemoveModal } from './RemoveVisit';

export const Visit = (props) => {
    const { match: { params: { protocolId, visitId } } } = props;
    const [isLoading, setIsLoading] = useState(false);
    const visits = useProtocolApprovedVisits(protocolId);
    const [reload, setReload] = useState(false);
    const [visit, setVisit] = useState(null);
    useEffect(() => {
        const fetchVisitData = () => {
            setIsLoading(true);
            getVisit(protocolId, visitId)
                .then(response => {
                    var visitResponse = response.data
                    //console.log("GET Visit: ", visitResponse);

                    setVisit(visitResponse);
                    setIsLoading(false);
                }).catch(error => {
                    HandleApiError(error);
                    setIsLoading(false);
                });
        }

        fetchVisitData();
    }, [protocolId, visitId, reload]);

    const [forms, setForms] = useState([]);
    useEffect(() => {
        const fetchFormsData = () => {
            setIsLoading(true);
            getForms(protocolId, visitId)
                .then(response => {
                    var forms = response.data
                    //console.log("GET Forms: ", forms);

                    setForms(forms.sort((function (a, b) { return a.order - b.order; })));
                    setIsLoading(false);
                }).catch(error => {
                    HandleApiError(error);
                    setIsLoading(false);
                });
        }

        fetchFormsData();
    }, [protocolId, visitId]);

    const [visitHistory, setVisitHistory] = useState([]);
    useEffect(() => {
        const fetchVisitHistory = () => {
            setIsLoading(true);
            setReload(false);
            getVisitHistory(protocolId, visitId)
                .then(response => {
                    var history = response.data
                    //console.log("GET VisitHistory: ", history);

                    setVisitHistory(history.sort((function (a, b) { return a.order - b.order; })));
                    setIsLoading(false);
                }).catch(error => {
                    HandleApiError(error);
                    setIsLoading(false);
                });
        }

        fetchVisitHistory();
    }, [protocolId, visitId]);

    const handleSwapForm = (oldIndex, newIndex) => {

        setIsLoading(true);
        swapForms(protocolId, visit.crossVersionId, forms[oldIndex].id, forms[newIndex].id)
            .then(res => {
                // Hago swap en cliente. 
                let copiedForms = JSON.parse(JSON.stringify(forms));
                let oldOrder = copiedForms[oldIndex].order;
                copiedForms[oldIndex].order = copiedForms[newIndex].order;
                copiedForms[newIndex].order = oldOrder;

                setForms(copiedForms.sort((function (a, b) { return a.order - b.order; })));
                setIsLoading(false);
            })
            .catch(error => {
                HandleApiError(error);
                setIsLoading(false);
            });
    };

    const [modal, setModal] = useState(null);
    const handleRemoveVisit = () => {
        setModal(<RemoveModal
            id={visitId}
            title="¡Está seguro?"
            onClose={() => { setModal(null); }}
            onConfirm={handleConfirmRemove} />)
    }
    const handleConfirmRemove = (visitId) => {
        removeVisit(protocolId, visitId)
            .then(res => {
                Success("protocolNotifications.visit_Removed");
                props.history.push(`/admin/protocols/${protocolId}`);
            }).catch(error => {
                console.error(error);
                HandleApiError(error);
                setModal(null);
            });
    }

    const handlePublishVisit = () => {
        setModal(<PublishModal
            id={visitId}
            title="Publicar visita"
            subtitle={"Ingrese los comentarios de la versión"} onClose={() => { setModal(null); }} onConfirm={handleConfirmPublish} />)
    }
    const handleConfirmPublish = (comments, visitId) => {
        publishVisit(protocolId, visitId, comments)
            .then(res => {
                Success("protocolNotifications.visit_Published");
                const visitResponse = res.data;
                const historyCopy = JSON.parse(JSON.stringify(visitHistory));
                let publishedVisit = historyCopy.find(x => x.crossVersionId === parseInt(visitId));
                publishedVisit.version = visitResponse.version;
                publishedVisit.versionComments = comments;
                setVisitHistory(historyCopy);
                setVisit(visitResponse);
                setModal(null);
            }).catch(error => {
                console.log(error);
                HandleApiError(error);
                setModal(null);
            });
    }

    const handleEditVisit = () => {
        setModal(<EditVisitModal
            protocolId={protocolId}
            visitId={visitId}
            hide={() => setModal(null)}
            submit={() => { setModal(null); setReload(true); }}
            isShowing={true} />);
    }

    const handlePrintVisit = async () => {
        try {
            var response = await printVisit(protocolId, visitId);

            const blob = new Blob([response.data], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.setAttribute('download', visit.title);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        }
        catch (error) {
            HandleApiError(error);
            console.log(error);
        };
    }

    return (
        <ProtocolProvider protocolId={protocolId}>
            <Grid fluid>
                <Row>
                    <Col md={12}>
                        <ProtocolInfo />
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <Actions
                            visit={visit}
                            onBack={() => props.history.push(`/admin/protocols/${protocolId}/section/visitsTemplate`)}
                            onEdit={handleEditVisit}
                            onPublish={
                                (visit?.version % 1) !== 0 ?
                                    handlePublishVisit
                                    : null
                            }
                            onRemove={handleRemoveVisit}
                            onPrint={handlePrintVisit}
                        />
                    </Col>
                    <Col md={10}>
                        <Row>
                            <Col md={12}>
                                {modal}
                                <VisitHeader
                                    visit={visit}
                                    visits={visits}
                                    onRemoveVisit={handleRemoveVisit}
                                    onPublishVisit={handlePublishVisit}
                                    onEditVisit={handleEditVisit}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <VisitForms
                                    isLoading={isLoading}
                                    forms={forms}
                                    protocolId={protocolId}
                                    visitId={visit?.crossVersionId}
                                    isPublished={(visit?.version % 1) === 0}
                                    onSwap={handleSwapForm}
                                />
                            </Col>
                            <Col md={8}>
                                <VisitHistory
                                    visitHistory={visitHistory}
                                    onRemoveVisit={handleRemoveVisit}
                                    onPublishVisit={handlePublishVisit}
                                    onEditVisit={handleEditVisit}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Grid>
        </ProtocolProvider>
    );
}