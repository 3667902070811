import axios from 'axios';
import { ApplicationPaths } from '../components/api-authorization/ApiAuthorizationConstants';
import authService from '../components/api-authorization/AuthorizeService';
import history from '../history';

const instance = axios.create({
    //baseURL: "api/v1"//process.env.ApiBaseUrl
});

// Add a request interceptor
instance.interceptors.request.use(async function (config) {
    const token = await authService.getAccessToken();
    config.headers.Authorization = `Bearer ${token}`;

    return config;
});

// Add response interceptor

instance.interceptors.response.use(response => {
    return response;
}, error => {

    console.log(JSON.stringify(error))

    switch (error.response?.status) {
        case 401:
            history.push(ApplicationPaths.Login);
            break;
        //case 403:
        //    history.push('/admin/forbidden');
        //    break;
        //case 404:
        //    history.push('/admin/not-found');
        //    break;
        default:
            break;
    }

    return Promise.reject(error);
});

export default instance;