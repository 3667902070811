import axios from './index';

export const getQueryString = (newActiveFilters, search) => {
    const searchParams = new URLSearchParams(search || '');

    searchParams.delete('currentDate');
    if (newActiveFilters.currentDate) {
        searchParams.append('currentDate', newActiveFilters.currentDate);
    }
    searchParams.delete('view');
    if (newActiveFilters.view) {
        searchParams.append('view', newActiveFilters.view);
    }

    searchParams.delete('projects');
    (newActiveFilters.projects || []).forEach(project => searchParams.append('projects', project));
    searchParams.delete('protocols');
    (newActiveFilters.protocols || []).forEach(protocol => searchParams.append('protocols', protocol));
    searchParams.delete('practitioners');
    (newActiveFilters.practitioners || []).forEach(practitioner => searchParams.append('practitioners', practitioner));
    searchParams.delete('type');
    if (newActiveFilters.type != null && newActiveFilters.type.id) {
        searchParams.append('type', newActiveFilters.type.id);
    }

    searchParams.delete('deleted');
    if (newActiveFilters.deleted) {
        searchParams.append('deleted', newActiveFilters.deleted);
    }
    searchParams.delete('medicalRecord');
    if (newActiveFilters.medicalRecord != null) {
        searchParams.append('medicalRecord', newActiveFilters.medicalRecord);
    }

    searchParams.delete('present');
    if (newActiveFilters.present != null) {
        searchParams.append('present', newActiveFilters.present);
    }

    return searchParams.toString();
}

export const getPagingQueryString = (newActiveFilters, search) => {
    const searchParams = new URLSearchParams(search || '');

    searchParams.delete('pageIndex'); // se resetea el paginado
    if (newActiveFilters.pagination.page && newActiveFilters.pagination.page !== 1) {
        searchParams.append('pageIndex', newActiveFilters.pagination.page);
    }
    searchParams.delete('pageSize');
    if (newActiveFilters.pagination.sizePerPage && newActiveFilters.pagination.sizePerPage !== 1) {
        searchParams.append('pageSize', newActiveFilters.pagination.sizePerPage);
    }
    searchParams.delete('sortField');
    if (newActiveFilters.sort.sortField) {
        searchParams.append('sortField', newActiveFilters.sort.sortField);
    }
    searchParams.delete('sortOrder');
    if (newActiveFilters.sort.sortOrder) {
        searchParams.append('sortOrder', newActiveFilters.sort.sortOrder);
    }

    return searchParams.toString();
}

export const getEvents = async filters => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(queryString ? `api/v1/agenda/events?${queryString}` : 'api/v1/agenda/events');
};
export const getWaitingList = () => {
    return axios.get(`api/v1/agenda/waitingList`);
};
export const getEvent = q => {
    return axios.get(`api/v1/agenda/event?${q}`);
};
export const getEventAPI = id => {
    return axios.get(`api/v1/agenda/events/${id}`);
};

export const createEvent = (event) => {
    return axios.post('api/v1/agenda/event', event);
};

export const updateEvent = (event) => {
    return axios.put(`api/v1/agenda/event/`, event);
};

export const updateEventAttendance = (eventId) => {
    return axios.put(`api/v1/agenda/event/${eventId}/attended`);
};

export const notAttendanceAppointment = (eventId, reason) => {
    return axios.put(`api/v1/agenda/event/${eventId}/notattendance/${reason}`);
};

export const updateEventStatus = (eventId, confirmed) => {
    return axios.put(`api/v1/agenda/event/${eventId}/status/${confirmed}`);
};

export const setPresent = (eventId) => {
    return axios.put(`api/v1/agenda/event/${eventId}/present`, eventId);
};

export const cancelEvent = (id, reason) => {
    return axios.put(`api/v1/agenda/event/${id}/${reason}`, id);
};

export const getPatient = id => {
    return axios.get(`api/v1/agenda/patient/${id}`);
};

export const searchPatients = q => {
    return axios.get('api/v1/database/patients/search', { params: { q } });
}

export const getProfessionals = () => {
    return axios.get('api/v1/users/practitioners?pageSize=1000');
};

export const getProfessionalsByProject = (projectId) => {
    return axios.get(`api/v1/agenda/project/${projectId}/professionals`);
};

export const getPractitionersByProtocol = (protocolId) => {
    return axios.get(`api/v1/protocols/${protocolId}/practitioners?sortField=FullName&sortOrder=desc&pageSize=50&onlyDoctor=true`);
};

export const searchProtocolsAPI = q => {
    return axios.get('api/v1/agenda/protocol/search', { params: { q } });
}
/*
export const getProjects = () => {
    return axios.get('api/v1/agenda/projects');
};

export const getProtocols = () => {
    return axios.get('api/v1/agenda/protocols');
};*/

export const exportToExcel = (filters) => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(queryString ? `api/v1/agenda/events/exportToExcel?${queryString}` : 'api/v1/agenda/events/exportToExcel', {
        responseType: 'blob',
        timeout: 300000,
    });
};

export const getMasterData = () => {
    return axios.get('api/v1/agenda/masterdata');
};

export const getMedicalServiceAPI = (id) => {
    return axios.get(`api/v1/agenda/medicalServices/${id}`);
};
export const getMedicalServicesAPI = () => {
    return axios.get(`api/v1/agenda/medicalServices`);
};
export const getMedicalServicePriceAPI = (medicalServiceId, medicalInsuranceId, medicalPlanId) => {
    return axios.get(`api/v1/agenda/medicalServices/${medicalServiceId}/medicalInsurance/${medicalInsuranceId}/medicalPlan/${medicalPlanId}`);
};
export const getPractitionersAPI = (medicalServiceId, medicalInsuranceId) => {
    return axios.get(`api/v1/agenda/medicalServices/${medicalServiceId}/medicalInsurance/${medicalInsuranceId}/practitioners`);
};
export const getAvailabilityAPI = (userId, medicalServiceId, medicalInsuranceId, medicalPlanId, isFirstTime, isOverShift) => {
    return axios.get(`api/v1/agenda/availability/medicalServices/${medicalServiceId}/medicalInsurance/${medicalInsuranceId}/medicalPlan/${medicalPlanId}/practitioners/${userId}/${isFirstTime}/${isOverShift}`);
};
export const getGlobalAvailabilityAPI = (userId) => {
    return axios.get(`api/v1/agenda/availability/practitioners/${userId}`);
};

export const getScheduleLocksAPI = (filters) => {
    const queryString = getPagingQueryString(filters, window.location.search);
    return axios.get(queryString ? `api/v1/agenda/scheduleLock?${queryString}` : 'api/v1/agenda/scheduleLock');
};
export const createScheduleLockAPI = (scheduleLock) => {
    return axios.post('api/v1/agenda/scheduleLock', scheduleLock);
};
export const deleteScheduleLockAPI = (id) => {
    return axios.delete(`api/v1/agenda/scheduleLock/${id}`);
};