import React from "react";
import { useTranslation } from 'react-i18next';
import { useUserInfo } from "../../containers/Users/Hooks";
import Card from "../Cards/Card";
import { dateTimeFormatter } from '../Utils/Formatter'

export const DigitalSign = props => {

    const { digitalSign } = props;
    const { t } = useTranslation();
    const [isLoading, userInfo] = useUserInfo(digitalSign?.signedBy);

    if (!digitalSign || !digitalSign.sign || isLoading)
        return null;

    const getDigitalSignStatus = (status) => {
        switch (status) {
            case 0: return <>{t('digitalSign.status.valid')}</>;
            case 1: return <>{t('digitalSign.status.invalid')}</>;
            case 2: return <>{t('digitalSign.status.verifError')}</>;
            case 3: return <>{t('digitalSign.status.notVerified')}</>;
            default:
                return <>{t('digitalSign.status.notVerified')}</>;
        }
    }

    return (
        <Card
            title={t('digitalSign.title')}
            className="card-actions"
            content={
                <div>
                    <div className="card-label">{t("digitalSign.state")}:</div>
                    <div className="card-text">{getDigitalSignStatus(digitalSign.signStatus)}</div>
                    <div className="card-label">{t("digitalSign.signedBy")}:</div>
                    <div className="card-text">{userInfo?.fullName}</div>
                    <div className="card-label">{t("digitalSign.signed")}:</div>
                    <div className="card-text">{dateTimeFormatter(digitalSign.signedDate)}</div>
                </div>
            }
        />
    );
}