import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Grid, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NavLink } from 'react-router-dom';
import { usePractitioners } from '../../containers/Users/Hooks';
import { typeOptions } from '../../variables/Enums';
import { WithAuth } from '../Authorization/WithAuth';
import Card from '../Cards/Card';
import { CustomSelect } from '../CustomControls/CustomSelect';
import { ReadMore } from '../CustomControls/ReadMore';
import { Edit, IconSVG, View, ViewVisit } from '../Icons/Icons';
import { getFullNameFromUserMention } from '../Utils/Commons';
import { dateFormatter } from "../Utils/Formatter";
import { Spinner } from '../Utils/Loaders';
import { EntryItemsTable } from './EntryItemsTable';

const getAction = (item) => {
    if (item.status === "Published") {

        let view = (<NavLink to={`/admin/medicalRecords/${item.medicalRecordNumber}/entry/${item.id}`} className="nav-link" activeClassName="active">
            <View />
        </NavLink>);

        let viewProtocol = null;
        if (item.groupCode === "PROTOCOL") {
            let visitUrl = item.typeId === 3 || item.typeId === 4 ? "visitTrackingExtra" : "visitTracking";

            viewProtocol = item.protocolId && (<NavLink to={`/admin/protocols/${item.protocolId}/${visitUrl}/${item.visitId}/patient/${item.numberInProtocol}`} className="nav-link" activeClassName="active">
                <ViewVisit />
            </NavLink>);
            view = (<NavLink to={`/admin/medicalRecords/${item.medicalRecordNumber}/entry/${item.id}`} className="nav-link" activeClassName="active">
                <View />
            </NavLink>);
        }

        return (<>{viewProtocol}{view}</>);
    }

    if (item.status !== "Published") {
        let edit = (<NavLink to={`/admin/medicalRecords/${item.medicalRecordNumber}/newentry/${item.id}`} className="nav-link" activeClassName="active">
            <Edit />
        </NavLink>);

        if (item.groupCode === "PROTOCOL") {

            let visitUrl = item.typeId === 3 || item.typeId === 4 ? "visitTrackingExtra" : "visitTracking";
            edit = (<NavLink to={`/admin/protocols/${item.protocolId}/${visitUrl}/${item.visitId}/patient/${item.numberInProtocol}`} className="nav-link" activeClassName="active">
                <Edit />
            </NavLink>);
        }

        return (<>
            <WithAuth requiredPermission={["MedicalRecords.Create"]}>
                {edit}
            </WithAuth>
        </>);
    }
}

export const Entries = (props) => {
    const { t } = useTranslation();

    const columns = [
        {
            dataField: 'created', text: t('medicalRecords.entries.created'), sort: true, formatter: ((cell) => (dateFormatter(cell, "DD/MM/YYYY", true)))
        },
        { dataField: 'practitionerFullName', text: t('medicalRecords.entries.practitioner'), classes: 'w900' },
        { dataField: 'practitionerSpeciality', text: t('medicalRecords.entries.speciality'), formatter: ((cell) => (<><IconSVG name="speciality" />{cell}</>)) },
        { dataField: 'group', text: t('medicalRecords.entries.group'), formatter: ((cell) => (<><IconSVG name="visitGroup" />{cell}</>)) },
        { dataField: 'type', text: t('medicalRecords.entries.type'), formatter: ((cell) => (<><IconSVG name="visitType" />{cell}</>)) },
        {
            dataField: 'view', text: '', isDummyField: true, headerClasses: 'header-view-btn', classes: 'row-view-btn',
            formatter: function (_cell, row) {
                return getAction(row)
            }
        }
    ];

    const rowClasses = (row) => {
        let classes = "draft";

        if (row.status === "Published") {
            classes = "published"
        }
        return classes;
    };

    const expandedContent = (row) => (
        <div>
            <h5>Evolución</h5>
            <p style={{ textAlign: 'initial', whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}><div dangerouslySetInnerHTML={{ __html: row.text }} /></p>
        </div >
    )

    return (<EntryItemsTable columns={columns} {...props} rowClasses={rowClasses} expandContent={expandedContent} />);
}

Entries.propTypes = {
    data: PropTypes.array.isRequired,
    query: PropTypes.object.isRequired,
    totalSize: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onTableChange: PropTypes.func.isRequired,
};

export const EntriesScrollable = (props) => {
    const { data, totalSize, getMore, onFilterChange } = props;
    const { t } = useTranslation();
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [users, isLoading] = usePractitioners();

    useEffect(() => {
        if (data && data.length > 0) {
            var montotos = [];
            if (props.currentPage > 1) // Si esoty en la primer página, refresco Items. 
                montotos = JSON.parse(JSON.stringify(items));

            setHasMore(totalSize !== montotos.length + data.length);
            setItems(montotos.concat(data));
        }

        if (data.length === 0)
            setItems([]);

    }, [data, totalSize]);

    const handleOnChangeFilter = async (name, value) => {
        //console.log(`handleOnChangeFilter ${name} - ${value}`)
        var filterValue = null;
        switch (name) {
            case "practitioner":
                filterValue = value && value.userId;
                break;
            case "speciality":
                filterValue = value && value.speciality;
                break;
            case "group":
                filterValue = value && value.code;
                break;
            default:
                break;
        }

        onFilterChange(name, filterValue);
    }

    return (
        <Card
            tabs
            noHeader
            content={
                <Grid fluid>
                    <Row style={{ marginBottom: '15px' }}>
                        <Col md={3}>
                            <CustomSelect
                                name="practitioner"
                                isLoading={isLoading}
                                isClearable={true}
                                placeholder={t('filters.professional')}
                                getOptionLabel={(option) => option.fullName}
                                getOptionValue={(option) => option.userId}
                                options={users}
                                onChange={handleOnChangeFilter}
                            />
                        </Col>
                        <Col md={3}>
                            <CustomSelect
                                name="speciality"
                                isClearable={true}
                                isLoading={isLoading}
                                placeholder={t('filters.speciality')}
                                options={[...new Map(users.map(item => [item.speciality, item])).values()]}
                                getOptionLabel={(option) => option.speciality}
                                getOptionValue={(option) => option.speciality}
                                onChange={handleOnChangeFilter}
                            />
                        </Col>
                        <Col md={3}>
                            <CustomSelect
                                name="group"
                                isClearable={true}
                                isLoading={isLoading}
                                placeholder={t('filters.entryGroup')}
                                options={typeOptions}
                                getOptionLabel={(option) => option.title}
                                getOptionValue={(option) => option.id}
                                onChange={handleOnChangeFilter}
                            />
                        </Col>
                    </Row>
                    {
                        data && data.length > 0 ?
                            <Row>
                                <Col md={12}>
                                    <InfiniteScroll
                                        dataLength={items.length}
                                        next={() => getMore()}
                                        hasMore={hasMore}
                                        loader={<Spinner />}
                                        endMessage={
                                            <p style={{ textAlign: "center" }}>
                                                <b>{t("medicalRecords.entries.noentries")}</b>
                                            </p>
                                        }
                                        height={400}
                                    >
                                        {items.map((item, index) => (
                                            <Grid fluid key={`entry-item-${index}`} className={`entry-item ${item.status === "Published" ? "published" : "draft"}`}>
                                                <Row>
                                                    <Col md={2}>{dateFormatter(item.created, "DD/MM/YYYY", true)}</Col>
                                                    <Col md={3} className="w900">{item.practitionerFullName}</Col>
                                                    <Col md={2}><IconSVG name="speciality" />{item.practitionerSpeciality}</Col>
                                                    <Col md={2}><IconSVG name="visitGroup" />{item.group}</Col>
                                                    <Col md={2}><IconSVG name="visitType" />{item.type}</Col>
                                                    <Col md={1} className="row-view-btn">{getAction(item)}</Col>
                                                    <Col md={12} className="entry-summary">
                                                        {
                                                            item.summary &&
                                                            <div><span className="w900">Resumen:</span> {getFullNameFromUserMention(item.summary.note)}</div>
                                                        }
                                                        {
                                                            item.text &&
                                                            <div><span className="w900">Evolución:</span> <ReadMore content={item.text} /></div>
                                                        }
                                                    </Col>
                                                </Row>
                                            </Grid>
                                        ))}
                                    </InfiniteScroll>
                                </Col>
                            </Row>
                            :
                            <p style={{ textAlign: "center" }}>
                                <b>{t("medicalRecords.entries.noentries")}</b>
                            </p>
                    }

                </Grid >
            }
        />

    );
}

EntriesScrollable.propTypes = {
    data: PropTypes.array.isRequired,
    currentPage: PropTypes.number.isRequired,
    totalSize: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    getMore: PropTypes.func.isRequired,
    onFilterChange: PropTypes.func.isRequired,
};

export const EntriesScrollableSmall = (props) => {
    const { data, totalSize, getMore, onFilterChange } = props;
    const { t } = useTranslation();
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [users, isLoading] = usePractitioners();

    useEffect(() => {
        if (data && data.length > 0) {
            var montotos = [];
            if (props.currentPage > 1) // Si estoy en la primer página, refresco Items. 
                montotos = JSON.parse(JSON.stringify(items));

            setHasMore(totalSize !== montotos.length + data.length);
            setItems(montotos.concat(data));
        }

        if (data.length === 0)
            setItems([]);

    }, [data, totalSize]);

    const handleOnChangeFilter = async (name, value) => {
        var filterValue = null;
        switch (name) {
            case "practitioner":
                filterValue = value && value.userId;
                break;
            case "speciality":
                filterValue = value && value.speciality;
                break;
            case "group":
                filterValue = value && value.code;
                break;
            default:
                break;
        }

        onFilterChange(name, filterValue);
    }

    return (<>
        <Row style={{ marginBottom: '15px' }}>
            <Col md={4}>
                <CustomSelect
                    name="practitioner"
                    isLoading={isLoading}
                    isClearable={true}
                    placeholder={t('filters.professional')}
                    getOptionLabel={(option) => option.fullName}
                    getOptionValue={(option) => option.userId}
                    options={users}
                    onChange={handleOnChangeFilter}
                />
            </Col>
            <Col md={4}>
                <CustomSelect
                    name="speciality"
                    isClearable={true}
                    isLoading={isLoading}
                    placeholder={t('filters.speciality')}
                    options={[...new Map(users.map(item => [item.speciality, item])).values()]}
                    getOptionLabel={(option) => option.speciality}
                    getOptionValue={(option) => option.speciality}
                    onChange={handleOnChangeFilter}
                />
            </Col>
            <Col md={4}>
                <CustomSelect
                    name="group"
                    isClearable={true}
                    isLoading={isLoading}
                    placeholder={t('filters.entryGroup')}
                    options={typeOptions}
                    getOptionLabel={(option) => option.title}
                    getOptionValue={(option) => option.id}
                    onChange={handleOnChangeFilter}
                />
            </Col>
        </Row>
        {
            data && data.length > 0 ?
                <Row>
                    <Col md={12}>
                        <InfiniteScroll
                            dataLength={items.length}
                            next={() => getMore()}
                            hasMore={hasMore}
                            loader={<Spinner />}
                            endMessage={
                                <p style={{ textAlign: "center" }}>
                                    <b>{t("medicalRecords.entries.noentries")}</b>
                                </p>
                            }
                            height={600}
                        >
                            {items.map((item, index) => (
                                <Grid fluid key={`entry-item-${index}`} className={`entry-item ${item.status === "Published" ? "published" : "draft"}`}>
                                    <Row>
                                        <Col md={4}>{dateFormatter(item.created, "DD/MM/YYYY", true)}</Col>
                                        <Col md={4} className="w900">{item.practitionerFullName}</Col>
                                        <Col md={3}><IconSVG name="speciality" />{item.practitionerSpeciality}</Col>
                                        <Col md={1} className="row-view-btn">{getAction(item)}</Col>
                                        <Col md={12} className="entry-summary">
                                            {
                                                item.summary &&
                                                <div><span className="w900">Resumen:</span> {getFullNameFromUserMention(item.summary.note)}</div>
                                            }
                                            {
                                                item.text &&
                                                <div><span className="w900">Evolución:</span> <ReadMore content={item.text} /></div>
                                            }
                                        </Col>
                                    </Row>
                                </Grid>
                            ))}
                        </InfiniteScroll>
                    </Col>
                </Row>
                :
                <p style={{ textAlign: "center" }}>
                    <b>{t("medicalRecords.entries.noentries")}</b>
                </p>
        }
    </>
    );
}

EntriesScrollableSmall.propTypes = {
    data: PropTypes.array.isRequired,
    currentPage: PropTypes.number.isRequired,
    totalSize: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    getMore: PropTypes.func.isRequired,
    onFilterChange: PropTypes.func.isRequired,
};