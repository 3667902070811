import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Card from '../Cards/Card';
import { Add, Audit, Chart, Copy, Edit, Print, RemoveCircle, View } from '../Icons/Icons';
import { Tooltip } from '../Utils/Tooltips';

export const EntryItemsTable = (props) => {
    const { data, columns, query, totalSize, isLoading, onTableChange, rowClasses, withCard } = props;
    const { t } = useTranslation();

    if (props.query || props.onAuditTrail || props.onEdit)
        columns.push({
            dataField: 'actions',
            text: t("commons.actions"),
            isDummyField: true,
            headerClasses: 'header-actions',
            classes: 'row-actions',
            formatter: function (_cell, row, rowIndex) {
                return (
                    <div key={`${Math.random()}-actions-${rowIndex}`}>
                        {
                            props.onHistory &&
                            <Tooltip tooltip="Histórico" id={"tooltiphistory-" + row.id}>
                                <button className="btn-table-actions" onClick={() => props.onHistory(row.id, props.query?.medicalRecordNumber)}>
                                    <Chart />
                                </button>
                            </Tooltip>
                        }
                        {
                            props.query && row.entryId != props.entryId && props.query.medicalRecordNumber !== 0 && props.query.medicalRecordNumber !== null &&
                            <Tooltip tooltip="Ir a visita" id={"tooltipview-" + row.id}>
                                <NavLink to={`/admin/medicalRecords/${props.query?.medicalRecordNumber}/entry/${row.entryId}`} className="nav-link" activeClassName="active">
                                    <View />
                                </NavLink>
                            </Tooltip>
                        }
                        {
                            row.hasAudit && props.onAuditTrail &&
                            <Tooltip tooltip="Ver auditoría" id={"tooltipaudit-" + row.id}>
                                <button className="btn-table-actions" onClick={() => props.onAuditTrail(row.originalId && row.originalId > 0 ? row.originalId : row.id)}>
                                    <Audit />
                                </button>
                            </Tooltip>
                        }
                        {
                            props.onEdit &&
                            <Tooltip tooltip="Editar" id={"tooltipedit-" + row.id}>
                                <button className="btn-table-actions" onClick={() => props.onEdit(row.id)}>
                                    <Edit />
                                </button>
                            </Tooltip>
                        }
                    </div>
                )
            }
        });

    const expandRow = {
        parentClassName: 'parent-table-expanded',
        className: 'table-expanded',
        renderer: props.expandContent,
        showExpandColumn: true,
        onlyOneExpanding: true,
        expanded: props.expandAll && data.map(x => x.id),
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            if (isAnyExpands)
                return <RemoveCircle style={{ cursor: 'hand' }} />
            else
                return <Add style={{ cursor: 'hand' }} />;
        },
        expandColumnRenderer: () => ("")
    };

    const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        onTableChange(type, { page, sizePerPage, sortField, sortOrder });
    }

    const content = (
        <BootstrapTable
            bordered={false}
            classes="table-spaced table-spaced-small"
            wrapperClasses="table-responsive"
            rowClasses={rowClasses ? rowClasses : ""}
            striped
            hover
            remote
            keyField='id'
            data={data ? data : []}
            columns={columns}
            pagination={query && query.pagination && paginationFactory({
                page: query.pagination.page,
                sizePerPage: query.pagination.sizePerPage,
                totalSize: totalSize,
                hideSizePerPage: true,
                hidePageListOnlyOnePage: true
            })}

            expandRow={props.expandContent && expandRow}
            onTableChange={handleTableChange}
            noDataIndication={() => <>Sin información</>}
            loading={isLoading}
            overlay={overlayFactory({ spinner: true })}
        />
    );

    if (withCard)
        return (
            <Card
                tabs
                noHeader
                content={content}
            />
        );
    else
        return content;
}


EntryItemsTable.defaultProps = {
    withCard: true
}
