import React from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import Card from '../Cards/Card';
import { CustomFileUpload } from '../CustomControls/CustomFileUpload';

export const UserSignForm = props => {
    const {
        isSubmitting,
        isDisabled,
        onUploadSign,
        values,
        setFieldValue,
        t,
    } = props;

    return (
        <fieldset>
            <legend>Firma del Profesional</legend>
            <Card
                className="card-patient-edit card-form"
                content={
                    <Grid fluid>
                        <Row>
                            <Col sm={6}>
                                <CustomFileUpload
                                    accept="image/*"
                                    disabled={isDisabled}
                                    value={values.sign}
                                    name={`sign`}
                                    placeholder={"Medidas 400x200"}
                                    onChange={setFieldValue}
                                    onDownload={() => { }}
                                />
                            </Col>
                            <Col sm={6}>
                                <button className="btn btn-success" type="button" disabled={isSubmitting || !values.sign} onClick={() => onUploadSign(values.sign)}>
                                    Subir Firma
                                </button>
                            </Col>
                        </Row>
                    </Grid>
                }
            />
        </fieldset>
    );
};