import { useEffect, useState } from 'react';
import { getDrugsData } from '../../../api/admin';
import {
    getAdverseEvents,
    getDrug, getDrugs, getPersonalHistories, postDrug, putDrug
} from '../../../api/medicalRecord';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';

export const useDrugs = (medicalRecordNumber, entryId, actual) => {

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [totalSize, setTotalSize] = useState(0);
    const [query, setQuery] = useState({
        medicalRecordNumber: medicalRecordNumber ? medicalRecordNumber : 0,
        entryId: entryId ? entryId : 0,
        pagination: {
            page: 1,
            sizePerPage: 10,
            totalSize: 0
        },
        actual: actual ?? false
    });
    const [reload, setReload] = useState(false);

    useEffect(() => {
        let isSubscribed = true
        const fetchData = async () => {
            try {
                setReload(false);
                setIsLoading(true);
                const paginatedItems = await getDrugs(query);

                if (isSubscribed) {
                    setTotalSize(paginatedItems.data.count);
                    setData(paginatedItems.data.data);
                    setIsLoading(false);
                }

            } catch (error) {
                HandleApiError(error);
                setIsLoading(false);
                setTotalSize(0);
                setData([]);
            }
        }

        fetchData();

        return () => isSubscribed = false;
    }, [query, reload]);

    const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        var newQuery = JSON.parse(JSON.stringify(query));

        switch (type) {
            case 'pagination': {
                newQuery.pagination.page = page;
                newQuery.pagination.sizePerPage = sizePerPage;
                setQuery(newQuery);
                break;
            }
            case 'sort': {
                newQuery.sort.sortField = sortField;
                newQuery.sort.sortOrder = sortOrder;
                setQuery(newQuery);
                break;
            }
            default: break;
        }
    }

    return [data, query, totalSize, isLoading, handleTableChange, setReload];
}

export const useDrug = (medicalRecordNumber, entryId, id) => {

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    const [masterData, setMasterData] = useState(null);

    useEffect(() => {
        let isSubscribed = true
        const fetchData = async () => {
            try {
                const res = await getDrug(entryId, id);

                if (isSubscribed) {
                    //console.log(res.data)
                    setData(res.data);
                    setIsLoading(false);
                }

            } catch (error) {
                HandleApiError(error);
                setIsLoading(false);
                setData(null);
            }
        }

        const getMasterData = async () => {
            var masterdata = {
                data: {
                    routesOfAdministration: [],
                    units: [],
                    frequencies: []
                },
                events: []
            };

            try {

                const res = await getDrugsData();
                masterdata.data = res.data;

                const query = {
                    medicalRecordNumber: medicalRecordNumber,
                    pagination: {
                        page: 1,
                        sizePerPage: 100,
                        totalSize: 0
                    },
                    actual: true
                };

                var groups = [
                    {
                        label: 'Eventos Adversos',
                        options: [],
                    },
                    {
                        label: 'Antecedentes Personales',
                        options: [],
                    },
                    {
                        label: 'Otros',
                        options: [{ id: 0, adverseEffect: "Profilaxis" }],
                    }
                ];

                const res2 = await getAdverseEvents(query);

                var resEvents = res2.data;
                resEvents.data.forEach(event => { event.reasonId = event.id; });
                groups[0].options = resEvents.data;

                const res3 = await getPersonalHistories(query);
                let resPersonalHistory = res3.data;
                resPersonalHistory.data.forEach(event => { event.reasonId = event.id; });
                groups[1].options = resPersonalHistory.data;
                masterdata.events = groups;
            }
            catch (error) {
                console.log(error)
            }

            setIsLoading(false);
            setMasterData(masterdata);
        }

        getMasterData();

        if (id)
            fetchData();

        return () => isSubscribed = false;
    }, [entryId, id, medicalRecordNumber]);

    const create = (entryId, data) => {
        //data.reason ? data.reasonId = data.reason.id : data.reasonId = 0
        return postDrug(entryId, data);
    }

    const update = (entryId, id, data) => {
        //data.reason ? data.reasonId = data.reason.id : data.reasonId = 0
        return putDrug(entryId, id, data);
    }

    return [data, masterData, isLoading, create, update];
}