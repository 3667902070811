import React from "react";
import Card from "../components/Cards/Card";
import { EditUserContainer } from "../containers/Users/EditUser";

const UserProfile = (props) => {
  
  return (
    <>
      <Card
        title="¡Bienvenido!"
        subtitle="Al parecer hay datos de tu perfil que están incompletos. Por favor, completalos para poder hacer un uso correcto de la aplicación. "
      />
      <EditUserContainer {...props} hideCancel={true} />
    </>
  );
}

export default UserProfile;