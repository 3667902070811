import React from "react";
import { useHistory } from "react-router-dom";
//import PropTypes from 'prop-types'
import { Formik } from 'formik';

import { useEffect, useRef } from "react";
import { createProtocol, uploadAttachmentEndpoint } from '../../../api/protocol';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';
import { Success } from '../../../components/Notifications/Notifications';
import { BriefForm } from "../../../components/Protocols/Brief";
import { getProtocolBriefSchema } from "../../../components/Validations/FormValidationSchema";
import { useStudyRoles } from "../../Security/Authorization/useStudyRoles";
import { useBriefMasterData } from "../Hooks";
import { useFiles } from "../../../components/CustomControls/Files/Hooks";
import { uploadStatusEnum } from "../../../components/CustomControls/Files/Utils";

export const ProtocolCreateBriefContainer = () => {
    let history = useHistory();

    const [data, , postAPI] = useFiles();
    const masterData = useBriefMasterData();
    const [studyRoles] = useStudyRoles();
    const protocolId = useRef(null);

    useEffect(() => {
        if (data.status === uploadStatusEnum.Uploaded && protocolId.current) {
            Success("protocolNotifications.protocol_Created");
            history.push(`/admin/protocols/${protocolId.current}`);
        }

    }, [data.status])

    const handleSubmit = async (values, formikBag) => {
        values.brief.studyRole.permissions = null;

        try {
            const res = await createProtocol(values.brief);
            var brief = res.data;
            protocolId.current = brief.id;

            // Upload attachments if any. 
            if (values.brief.attachments && values.brief.attachments.length > 0) {
                try {
                    await postAPI(values.brief.attachments, uploadAttachmentEndpoint(brief.id));
                }
                catch (error) {
                    console.log("handleSubmit", error);
                }
            }
            else {
                formikBag.setSubmitting(false);
                Success("protocolNotifications.protocol_Created");
                history.push(`/admin/protocols/${brief.id}`);
            }
        }
        catch (error) {
            console.log(error);
            formikBag.setSubmitting(false);
            HandleApiError(error);
        }
    }

    const handleCancel = () => {
        history.push(`/admin/protocols`);
    }

    return (
        <Formik
            onSubmit={handleSubmit}
            validationSchema={getProtocolBriefSchema}
        >
            {props => (
                <BriefForm
                    {...props}
                    onCancel={handleCancel}
                    masterData={masterData}
                    studyRoles={studyRoles}
                />
            )}
        </Formik>
    );
}