import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../../../components/Cards/Card.jsx';
import { Add, RemoveCircle, Summary } from '../../../components/Icons/Icons.jsx';
import { SummaryForm, Summaries, SummariesFlat, SummaryFlat } from '../../../components/MedicalRecords/Summary';
import { useSummaries } from '../Hooks/useSummaries.js';

export const SummariesContainer = (props) => {
    const { t } = useTranslation();
    const { medicalRecordNumber, entryId } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [, , getForEntry, getAllForEntry, , create, update] = useSummaries();
    const [summaries, setSummaries] = useState([]);
    const [summary, setSummary] = useState(null);
    const [collapse, setCollapse] = useState(true);

    useEffect(() => {
        let isSubscribed = true;

        const fetchData = async () => {
            setIsLoading(true);
            try {
                const [summary, summaries] = await Promise.all([
                    getForEntry(medicalRecordNumber, entryId),
                    getAllForEntry(medicalRecordNumber, entryId)
                ]);

                if (isSubscribed) {
                    setSummaries(summaries.data);
                    setSummary(summary.data);
                    setIsLoading(false);
                }
            }
            catch (error) {
                if (isSubscribed) {
                    setSummaries([]);
                    setIsLoading(false);
                }
            }
        }
        fetchData();

        return () => isSubscribed = false;
    }, [medicalRecordNumber, entryId]);

    const handleCreateSummary = async (vm) => {
        setIsLoading(true);
        const createdSummary = await create(medicalRecordNumber, entryId, { Note: vm });
        setIsLoading(false);

        setSummary(createdSummary.data);
    }

    const handleSummaryEdited = async (summaryId, text) => {
        setIsLoading(true);
        const updated = await update(medicalRecordNumber, summaryId, { Note: text });
        setIsLoading(false);

        if (updated) {
            setSummary(updated.data);
        }

    }

    return (
        <Card
            title={
                <div>
                    <button className="btn-table-actions" style={{ verticalAlign: 'middle' }} type="button" onClick={() => setCollapse(!collapse)}>
                        {collapse ? <Add /> : <RemoveCircle />}
                    </button>
                    <span>{t("medicalRecords.summary.summaries_Title")}</span>
                </div>
            }
            content={
                <>
                    {
                        !collapse &&
                        <>
                            <SummaryForm
                                medicalRecordNumber={medicalRecordNumber}
                                summary={summary}
                                isLoading={isLoading}
                                onCreate={handleCreateSummary}
                                onEdit={handleSummaryEdited}
                            />
                            <Summaries
                                summaries={summaries}
                            />
                        </>
                    }
                </>
            }
        />
    );
}

export const SummariesList = (props) => {
    const { t } = useTranslation();
    const { medicalRecordNumber, entryId } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [, getAll] = useSummaries();
    const [summaries, setSummaries] = useState([]);

    useEffect(() => {
        let isSubscribed = true;

        const fetchData = async () => {
            setIsLoading(true);
            try {
                const resp = await getAll(medicalRecordNumber);
                if (isSubscribed) {
                    setSummaries(resp.data);
                    setIsLoading(false);
                }
            }
            catch (error) {
                if (isSubscribed) {
                    setSummaries([]);
                    setIsLoading(false);
                }
            }
        }
        fetchData();

        return () => isSubscribed = false;
    }, [medicalRecordNumber, entryId]);

    if (isLoading)
        return null;

    return (
        <Card
            header={
                <h4>
                    <Summary style={{ fontSize: '27px', color: '', marginRight: '10px', verticalAlign: 'bottom' }} fill='rgb(36, 125, 189)' />{t("medicalRecords.summary.summaries_Title")}
                </h4>
            }
            content={
                <SummariesFlat
                    summaries={summaries}
                />
            }
        />
    );
}

export const SummaryContainer = (props) => {
    const { t } = useTranslation();
    const { medicalRecordNumber, entryId } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [, , getForEntry] = useSummaries();
    const [summary, setSummary] = useState(null);

    useEffect(() => {
        let isSubscribed = true;

        const fetchData = async () => {
            setIsLoading(true);
            try {
                const resp = await getForEntry(medicalRecordNumber, entryId);
                if (isSubscribed) {
                    setSummary(resp.data);
                    setIsLoading(false);
                }
            }
            catch (error) {
                if (isSubscribed) {
                    setSummary({});
                    setIsLoading(false);
                }
            }
        }
        fetchData();

        return () => isSubscribed = false;
    }, [medicalRecordNumber, entryId]);

    if (isLoading || !summary)
        return null;

    return (
        <Card
            header={
                <h4>
                    <Summary style={{ fontSize: '27px', color: '', marginRight: '10px', verticalAlign: 'bottom' }} fill='rgb(36, 125, 189)' />{t("medicalRecords.summary.summaries_Title")}
                </h4>
            }
            content={
                <SummaryFlat
                    summary={summary}
                />
            }
        />
    );
}