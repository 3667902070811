import axios from './index';

export const getBlob = async (endpoint) => {
    return axios.get(endpoint, { responseType: 'blob' });
};

export const postFile = (endpoint, formData) => {
    return axios.post(endpoint, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}