import React, { useState } from "react";

import { useDrugs } from "../Hooks/useDrugs";
import { Drugs } from "../../../components/MedicalRecords/Drugs";
import { AuditModal } from "../../../components/AuditTrail/AuditModal";
import { AuditTrail } from "../../Admin/AuditTrail";
import { ExpandableContent } from "../../../components/CustomControls/ExpandableContent";
import { useTranslation } from "react-i18next";

export const DrugsListContainer = ({ medicalRecordNumber, entryId, expandedAll }) => {
    const { t } = useTranslation();
    const [drugs, query, totalSize, isLoading, handleTableChange,] = useDrugs(medicalRecordNumber, entryId);

    const [modal, setModal] = useState(null);
    const handleAuditTrail = (entityId) => {
        setModal(
            <AuditModal
                onClose={() => { setModal(null); }}
                title="Auditoría"
            >
                <AuditTrail entity="Drugs" entityId={entityId} medicalRecordNumber={medicalRecordNumber} />
            </AuditModal>
        )
    }

    if (totalSize === 0)
        return null;

    return (
        <ExpandableContent title={t("medicalRecords.drugs.title")} content="drugs" expandedAll={expandedAll}>
            {modal}
            <Drugs
                withCard={false}
                data={drugs}
                query={query}
                totalSize={totalSize}
                isLoading={isLoading}
                onTableChange={handleTableChange}
                onAuditTrail={handleAuditTrail}
            />
        </ExpandableContent>
    );
}