import { useState } from "react";
import { getMonitorNotes, getMonitorNote, createMonitorNote, updateMonitorNote, removeMonitorNote, changeStatusMonitorNote } from "../../../api/protocol";
import { HandleApiError } from "../../../components/Notifications/APIErrorHandler";

export const useMonitorNotes = () => {
    const [isLoading, setIsLoading] = useState(true);

    const getNotes = async (protocolId, documentId) => {
        try {
            return getMonitorNotes(protocolId, documentId);
        } catch (error) {
            HandleApiError(error);
            setIsLoading(false);

            return null;
        }
    }

    const getNote = async (protocolId, noteId) => {
        try {
            setIsLoading(true);
            const note = await getMonitorNote(protocolId, noteId);
            setIsLoading(false);

            return note;

        } catch (error) {
            HandleApiError(error);
            setIsLoading(false);

            return null;
        }
    }

    const createNote = async (documentId, protocolId, visitId, patientId, note) => {
        try {
            setIsLoading(true);
            const documentNote = await createMonitorNote(documentId, protocolId, visitId, patientId, { note: note });
            setIsLoading(false);

            return documentNote;

        } catch (error) {
            HandleApiError(error);
            setIsLoading(false);

            return null;
        }
    }

    const updateNote = async (protocolId, noteId, note) => {
        try {
            setIsLoading(true);
            const documentNote = await updateMonitorNote(protocolId, noteId, { note: note });
            setIsLoading(false);

            return documentNote;

        } catch (error) {
            HandleApiError(error);
            setIsLoading(false);

            return null;
        }
    }

    const changeStatusNote = async (protocolId, noteId, status, response) => {
        try {
            setIsLoading(true);
            const documentNote = await changeStatusMonitorNote(protocolId, noteId, { status: status, response: response });
            setIsLoading(false);

            return documentNote;

        } catch (error) {
            HandleApiError(error);
            setIsLoading(false);

            return null;
        }
    }

    const deleteNote = async (protocolId, noteId) => {
        try {
            setIsLoading(true);
            var res = await removeMonitorNote(protocolId, noteId);
            setIsLoading(false);

            return res;

        } catch (error) {
            HandleApiError(error);
            setIsLoading(false);

            return null;
        }
    }

    return [isLoading, getNotes, getNote, createNote, updateNote, deleteNote, changeStatusNote];
}