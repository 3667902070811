import PropTypes from 'prop-types';
import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import overlayFactory from 'react-bootstrap-table2-overlay';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { WithAuth } from '../Authorization/WithAuth';
import { Edit, Remove } from '../Icons/Icons';
import { statusFormatter } from '../Table/Formatters';
import { Tooltip } from "../Utils/Tooltips";

export const Tenants = props => {

    const { data, isLoading } = props;
    const { t } = useTranslation();

    const columns = [
        { dataField: 'tenantId', text: t('security.tenant.id') },
        { dataField: 'tenantName', text: t('security.tenant.name') },
        { dataField: 'tenantDomain', text: t('security.tenant.domain') },
        { dataField: 'servicePlan', text: t('security.tenant.servicePlan') },
        { dataField: 'isActive', text: t('security.tenant.isActive'), formatter: statusFormatter, headerStyle: { width: '100px' } },
        {
            dataField: 'view', text: 'Acciones', isDummyField: true, headerStyle: { width: '150px' },
            formatter: function (_cell, row, rowIndex, _formatExtraData) {
                return (
                    <div key={rowIndex}>
                        <WithAuth requiredPermission={["Tenant.Edit", "Tenant.Delete"]}>
                            <NavLink to={`/admin/tenant/${row.tenantId}`} activeClassName="active" className="btn-table-actions">
                                <Tooltip tooltip={t('tenant.edit')} id={"tooltipadd-" + row.id}>
                                    <Edit />
                                </Tooltip>
                            </NavLink>
                            <NavLink to={`/admin/tenant/${row.tenantId}`} activeClassName="active" className="btn-table-actions">
                                <Tooltip tooltip={t('tenant.remove')} id={"tooltipadd-" + row.id}>
                                    <Remove />
                                </Tooltip>
                            </NavLink>
                        </WithAuth>
                    </div>
                )
            }
        }
    ];

    return (
        <BootstrapTable
            bordered={false}
            hover
            remote
            keyField='tenantId'
            classes="table-spaced table-spaced-small"
            wrapperClasses="table-responsive"

            data={data}
            columns={columns}
            noDataIndication={() => <>Sin datos</>}
            loading={isLoading}
            overlay={overlayFactory({ spinner: true })}
        />
    );
}

Tenants.propTypes = {
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

