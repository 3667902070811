import { ErrorMessage } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, ControlLabel, FormControl, FormGroup, Grid, Modal, Row } from 'react-bootstrap';
import { default as Datetime } from 'react-datetime';
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { getPatient, getPractitionersByProtocol } from '../../api/agenda';
import { getProtocols } from '../../api/protocol';
import { useAgenda, useMedicalServices } from '../../containers/Agenda/Hooks';
import Card from '../Cards/Card';
import { CustomFormControl } from '../CustomControls/CustomFormControl';
import { CustomSelect } from "../CustomControls/CustomSelect";
import { PatientPicker } from '../CustomControls/PatientPicker';
import { YesNo } from '../CustomControls/YesNoControls';
import { Save } from "../Icons/Icons";
import { HandleApiError, HandleError } from '../Notifications/APIErrorHandler';
import { ModalSpinner } from '../Utils/Loaders';
import { FormErrorMessage } from '../Validations/FormValidationErrors';
import { MedicalServiceInfo } from './MedicalServiceInfo';
import { colourStyles, getAvailableTimes, isValidEventStart, validateAvailabilityDate } from './Utils';

export const AppointmentForm = (props) => {
    const {
        values,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        handleSubmit,
        resetForm,
        formType,
    } = props;
    const { t } = useTranslation();

    const calendarView = useRef("days");
    //const [projects, setProjects] = useState([]);
    const [protocols, setProtocols] = useState([]);
    const [types, setTypes] = useState([]);
    const [medicalInsurances, setMedicalInsurances] = useState([]);
    const [isEditLoading, setIsEditLoading] = useState(formType === "edit");
    const [isLoading, setIsLoading,
        practitioners, getPractitioners, setPractitioners,
        availability, getAvailability, setAvailability] = useAgenda();
    const [isMSLoading, setIsMSLoading, , , ,
        medicalServices, getMedicalServices, setMedicalServices,
        medicalServicePrice, getMedicalServicePrice, setMedicalServicePrice] = useMedicalServices();

    const [availableTimes, setAvailableTimes] = useState([]);

    useEffect(() => {
        if (formType === "edit") { // Estoy editando.
            _getMedicalServices();

            _getMedicalServicesData(values.medicalServiceId, values.medicalInsuranceId, values.medicalInsurancePlanId);

            _getPatientData(null, null, values.patient.medicalRecordNumber);

            _getPractitionersForMedicalService(values.practitionerId, values.medicalInsuranceId);

            _getAvailability(
                values.practitioner.userId, 
                values.medicalServiceId ?? 0, 
                values.medicalInsuranceId ?? 0, 
                values.medicalInsurancePlanId ?? 0, 
                values.isFirstTime ?? false,
                values.visitType?.code.toUpperCase() === "OVERSHIFT"
                );

            setIsEditLoading(false);
        }
    }, []);

    const _getPatientData = async (controlName, selected, medicalRecordNumber) => {

        // Obtengo datos del paciente: Si está en protocolo, Proyecto y muestro los Tipos Disponibles. 
        try {
            const response = await getPatient(medicalRecordNumber ?? selected.medicalRecordNumber);
            const patient = response.data;
            if (controlName)
                setFieldValue(controlName, patient);

            var options = props.masterdata;
            if (!patient.projects || patient.projects.length === 0)
                options = options.filter(x => x.code.toLowerCase() !== "project");

            if (!patient.protocols || patient.protocols.length === 0)
                options = options.filter(x => x.code.toLowerCase() !== "protocol");

            setTypes(selected === null ? [] : options);
            setTypes(options);
            //setProjects(patient.projects);
            setProtocols(patient.protocols);
            let insurances = [{ id: 0, title: "Particular", plan: 0 }];
            if (patient.medicalInsurance) {
                if (patient.medicalInsurancePlan)
                    insurances.push({ id: patient.medicalInsuranceId, title: `${patient.medicalInsurance} - ${patient.medicalInsurancePlan}`, plan: patient.medicalInsurancePlanId });
                else
                    insurances.push({ id: patient.medicalInsuranceId, title: `${patient.medicalInsurance}`, plan: patient.medicalInsurancePlanId });
            }

            setMedicalInsurances(insurances);

            if (formType === "edit") {
                let insuranceId = values.medicalInsuranceId ?? 0;
                setFieldValue("medicalInsurance", insurances.filter(x => x.id == insuranceId));
            }
        } catch (error) {
            console.log(error);
        }
    }

    const _getProtocolPractitioners = async (protocolId) => {
        // Obtengo Profesionales del Protocolo.
        try {
            setIsLoading(true);
            const response = await getPractitionersByProtocol(protocolId);
            setPractitioners(response.data.data);
            setIsLoading(false);
        } catch (error) {
            HandleApiError(error);
            console.log(error);
            setIsLoading(false);
        }
    }

    const _getAllProtocols = async () => {
        // Obtengo todos los Protocolos.
        try {
            const response = await getProtocols({
                pagination: { page: 1, sizePerPage: 100, totalSize: 0 },
                sort: { sortField: "Created", sortOrder: "desc" },
                filters: { notStatusId: 4 }
            });
            if (response.data)
                return response.data.data
            return [];

        } catch (error) {
            HandleApiError(error);
            console.log(error);
            return [];
        }
    }

    const _getMedicalServices = async () => {
        // Obtengo Médicos que atiendan al Servicio Médico.
        try {
            setIsMSLoading(true);
            var res = await getMedicalServices();
            setMedicalServices(res.data?.data);
            if (formType === "edit") {
                let serviceId = values.medicalServiceId ?? 0;
                setFieldValue("medicalService", res.data?.data.filter(x => x.id == serviceId));
            }
            setIsMSLoading(false);
        }
        catch (ex) {
            console.log(ex)
            setIsMSLoading(false);
        }
    }

    const _getMedicalServicesData = async (medicalServiceId, medicalInsuranceId, medicalInsurancePlanId) => {
        // Obtengo Servicios
        if (!medicalServiceId && !medicalInsuranceId)
            return;

        try {
            setIsMSLoading(true);
            var res2 = await getMedicalServicePrice(medicalServiceId, medicalInsuranceId, medicalInsurancePlanId ?? 0);
            setMedicalServicePrice(res2.data.data);
            setIsMSLoading(false);
        }
        catch (ex) {
            console.log(ex)
            setIsMSLoading(false);
        }
    }

    const _getPractitionersForMedicalService = async (practitionerId, medicalInsuranceId) => {
        // Obtengo Médicos que atiendan al Servicio Médico.
        if (!practitionerId) {
            setPractitioners([]);
            return;
        }

        try {
            setIsLoading(true);
            var res = await getPractitioners(practitionerId, medicalInsuranceId);
            var users = res.data?.data.map(x => x.practitioner); // Formateo data
            setPractitioners(users ?? []);
            setIsLoading(false);
        }
        catch (ex) {
            console.log(ex)
            setPractitioners([]);
            setIsLoading(false);
        }
    }

    const _getAvailability = async (userId, medicalServiceId, medicalInsuranceId, medicalInsurancePlanId, isFirstTime, isOverShift) => {
        // Obtengo disponibilidad del médico. 
        try {
            setIsLoading(true);
            var res = await getAvailability(userId, medicalServiceId, medicalInsuranceId, medicalInsurancePlanId, isFirstTime, isOverShift);
            setAvailability(res.data);
            setIsLoading(false);
        }
        catch (ex) {
            console.log(ex)
            setIsLoading(false);
        }
    }


    const handlePatientChange = (controlName, selected) => {

        if (selected !== null)
            _getPatientData(controlName, selected);
        else {
            resetForm();
            //setFieldValue("patient", null);
        }
    }

    const handleTypeChange = async (controlName, selectedValue) => {

        if (selectedValue === null)
            return;

        setFieldValue(controlName, selectedValue);
        setFieldValue("isFirstTime", false);
        setFieldValue("medicalInsurance", null);
        setFieldValue("medicalService", null);
        setFieldValue("practitioner", null);
        setFieldValue("selectedDate", null);
        setFieldValue("start", null);
        setFieldValue("end", null);

        if (selectedValue.code.toLowerCase() === "protocol") {
            if (protocols.length === 1) {
                if (values.protocol)
                    _getProtocolPractitioners(values.protocol.id);
            }
        }

        if (selectedValue.code.toLowerCase() === "prescreening") {
            var allProtocols = await _getAllProtocols();
            console.log(allProtocols)
            setProtocols(allProtocols);
        }

        _getMedicalServices();
    }

    const handleProtocolChange = (controlName, selectedValue) => {
        setFieldValue(controlName, selectedValue);

        if (selectedValue)
            _getProtocolPractitioners(selectedValue.id);
    }

    const handleMedicalInsurance = async (controlName, selectedValue) => {
        setFieldValue(controlName, selectedValue);

        setFieldValue("medicalService", null);
        setFieldValue("practitioner", null);
        setFieldValue("selectedDate", null);
        setFieldValue("start", null);
        setFieldValue("end", null);
    }

    const handleMedicalService = async (controlName, selectedValue) => {
        setFieldValue(controlName, selectedValue);

        if (values.practitioner) {
            setFieldValue("practitioner", null);
            setFieldValue("selectedDate", null);
            setFieldValue("start", null);
            setFieldValue("end", null);
        }

        // Obtengo Costos del Servicio Médico.
        _getMedicalServicesData(selectedValue?.id, values.medicalInsurance?.id, values.medicalInsurance?.plan);

        // Obtengo Médicos que atiendan al Servicio Médico.
        _getPractitionersForMedicalService(selectedValue?.id, values.medicalInsurance?.id);
    }

    const handlePractitionerChange = async (controlName, selectedValue) => {
        setFieldValue(controlName, selectedValue);
        setAvailability([]);

        // Obtengo disponibilidad del médico. 
        _getAvailability(selectedValue.userId, 
            values.medicalService?.id, 
            values.medicalInsurance?.id, 
            values.medicalInsurance?.plan, 
            values.isFirstTime ?? false, 
            values.visitType?.code.toUpperCase() === "OVERSHIFT");
    }

    const handleDate = (date) => {
        if (!moment.isMoment(date))
            return;

        setFieldValue("selectedDate", date);

        if (values.visitType.code.toLowerCase() === "order") {
            let times = getAvailableTimes('08:00', '19:40', 20);
            setAvailableTimes(times);
            return;
        }
        else {
            var availableDate = availability.availableDates.filter(x => moment(x.date).format('DD/MM/YYYY') === date.format('DD/MM/YYYY') && x.available);
            if (availableDate.length > 0) {
                var availableTimesAux = availableDate[0].availableAppointments.filter(x => x.available);
                setAvailableTimes(availableTimesAux);
                return;
            }
        }

        setAvailableTimes([]);
    }

    const handleTime = (_controlName, selectedValue) => {

        if (values.selectedDate) {
            let datastart = values.selectedDate.toDate();
            let dataend = values.selectedDate.toDate();

            datastart.setHours(selectedValue.appointmentInit.split(":")[0]);
            datastart.setMinutes(selectedValue.appointmentInit.split(":")[1]);
            dataend.setHours(selectedValue.appointmentEnd.split(":")[0]);
            dataend.setMinutes(selectedValue.appointmentEnd.split(":")[1]);

            setFieldValue("start", datastart);
            setFieldValue("end", dataend);
        }
    }

    const handleAvailabilityDate = (current, selected) => {
        if (calendarView.current !== "days" || values.visitType?.code.toLowerCase() === "order")
            return true;

        return validateAvailabilityDate(current, selected, availability)
    }

    const onNavigate = (selectedView) => {
        calendarView.current = selectedView;
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (!isValidEventStart(values.start)) {
            HandleError("calendarErrors.Event_Create_StartLowerThanNow");
            return;
        }
        else {
            handleSubmit();
        }
    }

    if (isEditLoading)
        return <ModalSpinner isShowing={true} />;

    return (
        <Modal show={true} onHide={props.onClose} backdrop='static' bsSize="large">
            <Modal.Header closeButton>
                <Modal.Title>
                    {
                        formType === "new" ?
                            t('calendar.newAppointmentTitle') :
                            <>
                                {t('calendar.editAppointmentTitle')}: {values.patient?.fullName} {values.start && moment(values.start, 'HH:mm').format('HH:mm')} - {values.end && moment(values.end, 'HH:mm').format('HH:mm')}
                            </>}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={onSubmit} autoComplete="off">
                    <Grid fluid>
                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <ControlLabel>Paciente</ControlLabel>
                                    {
                                        formType === "edit" ? <FormControl type="text" readOnly value={values.title} /> :
                                            <PatientPicker
                                                autoFocus
                                                id="agenda-patient-picker"
                                                name="patient"
                                                multiple={false}
                                                placeholder={t("filters.patient")}
                                                onChange={handlePatientChange}
                                                value={values.patient ? [values.patient] : []}
                                            />
                                    }
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                {
                                    formType !== "edit" &&
                                    <>
                                        <br />
                                        <NavLink to="/admin/database/create?redirectTo=agenda" className="nav-link" activeClassName="active">
                                            Nuevo Paciente
                                        </NavLink>
                                    </>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <ControlLabel>Tipo de Consulta</ControlLabel>
                                    <CustomSelect
                                        value={values.visitType}
                                        name="visitType"
                                        placeholder={t('commons.select')}
                                        isDisabled={!values.patient || !types}
                                        options={types}
                                        onChange={handleTypeChange}
                                        isMulti={false}
                                        styles={colourStyles}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                {
                                    values.visitType && (values.visitType.code.toLowerCase() !== "studies" && values.visitType.code.toLowerCase() !== "order") &&
                                    <FormGroup>
                                        {
                                            values.visitType.code.toLowerCase() == "protocol" ?
                                                <ControlLabel style={{ marginLeft: '10px' }}>¿Es visita prolongada?</ControlLabel> :
                                                <ControlLabel style={{ marginLeft: '10px' }}>¿Es primera vez?</ControlLabel>
                                        }
                                        <br />
                                        <YesNo
                                            value={values.isFirstTime}
                                            onChange={setFieldValue}
                                            name="isFirstTime" />
                                        <ErrorMessage name="isFirstTime" component={FormErrorMessage} />
                                    </FormGroup>
                                }
                            </Col>
                        </Row>

                        {
                            values.visitType && (values.visitType.code.toLowerCase() === "protocol" || values.visitType.code.toLowerCase() === "prescreening") &&
                            <Row>
                                <Col sm={6}>
                                    <FormGroup>
                                        <ControlLabel>Protocolo</ControlLabel>
                                        <CustomSelect
                                            name="protocol"
                                            isDisabled={values.isFirstTime === undefined || values.isFirstTime === null}
                                            placeholder={t('filters.protocol')}
                                            options={protocols}
                                            onChange={handleProtocolChange}
                                            isMulti={false} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        }

                        {
                            values.visitType && (values.visitType.code.toLowerCase() !== "protocol" && values.visitType.code.toLowerCase() !== "prescreening") &&
                            <Row>
                                <Col sm={6}>
                                    <FormGroup>
                                        <ControlLabel>Particular u O.S.</ControlLabel>
                                        <CustomSelect
                                            value={values.medicalInsurance}
                                            name="medicalInsurance"
                                            placeholder={t('commons.select')}
                                            isDisabled={!values.patient || values.isFirstTime === undefined || values.isFirstTime === null}
                                            options={medicalInsurances}
                                            onChange={handleMedicalInsurance}
                                            isMulti={false} />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <ControlLabel>Servicio Médico</ControlLabel>
                                        <CustomSelect
                                            value={values.medicalService}
                                            name="medicalService"
                                            //value={values.medicalServiceId ? medicalServices.filter(x => x.id == values.medicalServiceId) : values.medicalService}
                                            placeholder={t('commons.select')}
                                            isDisabled={!values.patient || !values.medicalInsurance}
                                            options={medicalServices}
                                            isLoading={isMSLoading}
                                            getOptionLabel={(option) => option.serviceNameReference}
                                            getOptionValue={(option) => option.id}
                                            onChange={handleMedicalService}
                                            isMulti={false} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        }
                        {
                            medicalServicePrice && (values.visitType.code.toLowerCase() !== "protocol" && values.visitType.code.toLowerCase() !== "prescreening") &&
                            <Card
                                className="card-patient"
                                content={
                                    <MedicalServiceInfo medicalService={medicalServicePrice} isLoading={isMSLoading} />
                                } />
                        }
                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <ControlLabel>Profesional</ControlLabel>
                                    <CustomSelect
                                        name="practitioner"
                                        value={values.practitioner}
                                        isLoading={isLoading}
                                        isDisabled={!values.patient || !values.visitType}
                                        placeholder={t('filters.professional')}
                                        options={practitioners}
                                        getOptionLabel={(option) => option.fullName}
                                        getOptionValue={(option) => option.userId}
                                        onChange={handlePractitionerChange}
                                        isMulti={false} />
                                </FormGroup>
                            </Col>

                            {
                                values.practitioner &&
                                <>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <ControlLabel>Día y Horario</ControlLabel>
                                            <div className="row">
                                                <div className="col-xs-5">
                                                    <Datetime
                                                        name="selectedDate"
                                                        timeFormat={false}
                                                        inputProps={{ placeholder: "Fecha" }}
                                                        onChange={handleDate}
                                                        isValidDate={handleAvailabilityDate}
                                                        onNavigate={onNavigate}
                                                        closeOnSelect={true}
                                                    />
                                                    <ErrorMessage name="selectedDate" component={FormErrorMessage} />
                                                </div>
                                                <div className="col-xs-7">
                                                    {
                                                        !availableTimes || availableTimes.length === 0 ?
                                                            <ControlLabel>No hay horario disponible para la fecha seleccionada</ControlLabel> :
                                                            <CustomSelect
                                                                value={values.selectedTime}
                                                                name="selectedTime"
                                                                placeholder={t('commons.select')}
                                                                getOptionLabel={(option) => option.appointmentInit}
                                                                getOptionValue={(option) => option.appointmentInit}
                                                                options={availableTimes}
                                                                onChange={handleTime}
                                                                isMulti={false} />
                                                    }
                                                    <ErrorMessage name="start" component={FormErrorMessage} />
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <ControlLabel>Notas</ControlLabel>
                                            <CustomFormControl
                                                value={values.desc}
                                                type="textarea"
                                                name="desc"
                                                placeholder={t('calendar.notes')}
                                                rows={5}
                                                onChange={setFieldValue}
                                                onBlur={setFieldTouched}

                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <ControlLabel>Ubicación</ControlLabel>
                                            <CustomFormControl
                                                value={values.location}
                                                type="text"
                                                name="location"
                                                placeholder={t('calendar.location')}
                                                onChange={setFieldValue}
                                                onBlur={setFieldTouched}
                                            />
                                        </FormGroup>
                                    </Col>
                                </>
                            }
                        </Row>
                    </Grid>
                    <div className="modal-footer">
                        <Button
                            bsStyle="success"
                            type="submit"
                            disabled={!values.practitioner || !values.start || !values.end || isSubmitting}
                        >
                            <Save /> {t("buttons.save")}
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal >
    );
}

AppointmentForm.propTypes = {
    masterdata: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    currentPatient: PropTypes.string,
    formType: PropTypes.string,
};