import React from 'react'
import PropTypes from 'prop-types'
import Tippy from '@tippyjs/react';

export function Popover({ id, title, children, popover, trigger, cssClass }) {

    const Children = React.forwardRef((props, ref) => <div {...props} ref={ref} style={{ display: 'inline-block' }} />)
    const Content = (
        <div>
            <h6 style={{ minWidth: '500px' }}>{title}</h6>
            <hr />
            <p>{popover}</p>
        </div>
    );

    return <Tippy
        theme="light"
        content={Content}
        trigger={trigger}
        delay={[300, 150]}
        animateFill={true}
        interactive={true}
        className={cssClass}
    >
        <Children>
            {children}
        </Children>
    </Tippy >;
}

Popover.defaultProps = {
    trigger: "click"
}

Popover.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    popover: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
        PropTypes.any
    ]).isRequired,
    cssClass: PropTypes.string
};