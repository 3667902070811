import PropTypes from 'prop-types';
import React from 'react';
import Card from '../Cards/Card';
import { IconSVG } from '../Icons/Icons';
import { customDateTimeFormatter } from '../Table/Formatters';
import { AuditTooltip } from '../CustomControls/AuditTooltip';

const EntryView = (props) => {
    const { entry, audit } = props;

    const getHeader = () => {
        if (!entry)
            return null;

        switch (entry.groupCode) {
            case "PROJECT": return (<>
                <IconSVG name="project" />
                <span>{`${entry.group}: ${entry.project}`} {customDateTimeFormatter(entry.created)}</span>
            </>);
            case "PROTOCOL": return (<>
                <IconSVG name="protocol" />
                <span>{`${entry.group}: ${entry.protocol}`} {customDateTimeFormatter(entry.created)}</span>
            </>);
            default:
                return (<>
                    <IconSVG name="visitGroup" />
                    <span>Evolución {entry.group} {customDateTimeFormatter(entry.created)}</span>
                </>);
        }
    }

    if (!entry)
        return null;

    return (
        <Card
            className="card-evolution-text"
            header={<div className="category">
                {getHeader()}
                {audit && <AuditTooltip id="visitText" text={audit} />}
            </div>}
            content={
                <div dangerouslySetInnerHTML={{ __html: entry.text }} />
            }
        />
    );
}
EntryView.defaultProps = {
    rows: 5
};

EntryView.propTypes = {
    entry: PropTypes.object.isRequired,
    audit: PropTypes.object,
};

export default EntryView;

