var bloodGroup = [
    { id: 1, title: "A" },
    { id: 2, title: "B" },
    { id: 3, title: "AB" },
    { id: 4, title: "O" }

];
var bloodFactor = [
    { id: 1, title: "Rh+" },
    { id: 2, title: "Rh-" },
];

var typeOptions = [
    { id: 1, title: 'Consultorios Externos', code: "EXTERNAL" },
    { id: 2, title: 'Otros', code: "OTHER" },
    { id: 3, title: 'Investigación', code: "PROTOCOL" },
    //{ id: 4, title: 'Proyectos', code: "PROJECT" }
];

var relatives = [
    { id: 1, title: 'Madre' },
    { id: 2, title: 'Padre' },
    { id: 3, title: 'Hermano' },
    { id: 4, title: 'Abuelos' },
    { id: 5, title: 'Otros' },
];

var routeOfAdministrations = [
    { id: 1, title: 'Oral' },
    { id: 2, title: 'Sublingual' },
    { id: 3, title: 'Tópica' },
    { id: 4, title: 'Transdérmica' },
    { id: 5, title: 'Oftalmológica' },
    { id: 6, title: 'Inhalatoria' },
    { id: 7, title: 'Rectal' },
    { id: 8, title: 'Vaginal' },
    { id: 9, title: 'Parental' }
];

var intensity = [
    { id: 1, title: 'Leve' },
    { id: 2, title: 'Moderado' },
    { id: 3, title: 'Severo' }
];

var units = [
    { id: 1, title: 'mg' },
    { id: 2, title: 'mL' },
    { id: 3, title: 'ug' },
    { id: 4, title: 'g' },
    { id: 5, title: 'U' },
    { id: 6, title: 'Other' }
];

var snomedExpressions = {
    Hallazgos: "404684003",
    Medicamentos: "781405001", // Producto, por ejemplo, Amoxidal, Ibupirac.
    Sustancia: "105590001", // Por ejemplo: Ibuprofeno, Amoxicilina. 
    Producto_O_Sustancia: "< 781405001 OR < 105590001",
    ViaAdministracion: "736479009",
    Procedimiento: "71388002",
    Laboratorio: "122869004" //   procedimiento de medición (procedimiento) 122869004
}

var hcVisitStatus = {
    Draft: "Draft",
    Saved: "Saved",
    Published: "Published",
    AutoPublished: "AutoPublished"
}

var visitStatus = {
    Started: 0,
    Completed: 1,
    Skipped: 2,
    Failed: 3,
    Changed: 4,
    AutoCompleted: 5
}

var formStatus = {
    Initial: 0,
    Saved: 1,
    Draft: 2,
    Published: 9
}

var visitTypes = {
    ProtocolVisit: 0,
    ExtraProtocolVisit: 1,
    Note: 2
}

var patientInProtocolStatus = {
    Screening: 0,
    Random: 1,
    Treatment: 2,
    FollowUp: 3,
    Failed: 4,
    LostToFollowUp: 5,
    WithoutDrugs: 6
}

var visitStage = {
    Screening: 0,
    Random: 1,
    Treatment: 2,
    FollowUp: 3
}

var visitOffsetType = {
    Days: 0,
    Weeks: 1,
    Months: 2
}

const noteEnum = {
    Initial: 0,
    Fixed: 1,
    Invalid: 2
}

const formTypes = [
    { label: "NoSync", value: "NoSync" },
    { label: "Anthropometrics", value: "Anthropometrics" },
    { label: "Vitals", value: "Vitals" },
    { label: "AdverseEvents", value: "AdverseEvents" },
    { label: "Drugs", value: "Drugs" },
    { label: "Procedures", value: "Procedures" },
    { label: "LaboratoryResults", value: "LaboratoryResults" },
    { label: "Habits", value: "Habits" },
]

const formTypeFields = [
    {
        type: "Anthropometrics",
        fields: [
            { value: "Weight", fieldType: "DecimalField" },
            { value: "Height", fieldType: "DecimalField" },
            { value: "Waist", fieldType: "DecimalField" },
            { value: "Sagittal", fieldType: "DecimalField" },
            { value: "Neck", fieldType: "DecimalField" },
            { value: "Others", fieldType: "SingleLineField" },
            { value: "Notes", fieldType: "MultiLineField" }
        ]
    },
    {
        type: "Vitals",
        fields: [
            { value: "BloodPressureHigh", fieldType: "IntegerField" },
            { value: "BloodPressureLow", fieldType: "IntegerField" },
            { value: "HeartRate", fieldType: "SingleLineField" },
            { value: "RespiratoryRate", fieldType: "SingleLineField" },
            { value: "TemperatureC", fieldType: "DecimalField" },
            { value: "OxygenSaturation", fieldType: "DecimalField" },
            { value: "Notes", fieldType: "MultiLineField" }
        ]
    },
    {
        type: "AdverseEvents",
        fields: [
            { value: "AdverseEvent", fieldType: "SnomedField" },
            { value: "Intensity", fieldType: "SelectAsyncField" },
            { value: "Serious", fieldType: "BooleanField" },
            { value: "Reported", fieldType: "BooleanField" },
            { value: "Relationwithdrug", fieldType: "SelectAsyncField" },
            { value: "Action", fieldType: "SelectAsyncField" },
            { value: "From", fieldType: "DateField" },
            { value: "To", fieldType: "DateField" },
            { value: "Notes", fieldType: "MultiLineField" }
        ]
    },
    {
        type: "Drugs",
        fields: [
            { value: "Drug", fieldType: "SnomedField" },
            { value: "RouteOfAdministration", fieldType: "SelectAsyncField" },
            { value: "Reason", fieldType: "AdverseEventsAsyncField" },
            { value: "Dose", fieldType: "IntegerField" },
            { value: "Unit", fieldType: "SelectAsyncField" },
            { value: "Frequency", fieldType: "SelectAsyncField" },
            { value: "From", fieldType: "DateField" },
            { value: "To", fieldType: "DateField" },
            { value: "Notes", fieldType: "MultiLineField" }
        ]
    },
    {
        type: "Procedures",
        fields: [
            { value: "Procedure", fieldType: "SnomedField" },
            { value: "Reason", fieldType: "AdverseEventsAsyncField" },
            { value: "From", fieldType: "DateField" },
            { value: "To", fieldType: "DateField" },
            { value: "Notes", fieldType: "MultiLineField" }
        ]
    }
];

module.exports = {
    formTypes,
    formTypeFields,
    bloodGroup,
    bloodFactor,
    typeOptions,
    relatives,
    routeOfAdministrations,
    intensity,
    units,
    snomedExpressions,
    hcVisitStatus,
    formStatus,
    visitStatus,
    visitTypes,
    visitOffsetType,
    visitStage,
    patientInProtocolStatus,
    noteEnum,
};