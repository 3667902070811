import moment from 'moment';
import React, { useState } from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datetime/css/react-datetime.css";
import { useTranslation } from 'react-i18next';
import { cancelEvent, createEvent, createScheduleLockAPI, exportToExcel, notAttendanceAppointment, setPresent, updateEvent, updateEventAttendance, updateEventStatus } from '../../api/agenda';
import '../../assets/sass/lbd/_react-big-calendar.scss';
import { Event, EventAgenda, EventMonth } from "../../components/Agenda/Event";
import { ConfirmRemove } from '../../components/Agenda/Modals';
import { ScheduleLockAppointmentsTaken } from '../../components/Agenda/ScheduleLock';
import { colourStyles, visitTypeColorGetter } from '../../components/Agenda/Utils';
import { ViewEvent } from "../../components/Agenda/ViewEvent";
import { useAuthorization } from '../../components/Authorization/useAuthorization';
import { WithAuth } from '../../components/Authorization/WithAuth';
import { Card } from '../../components/Cards/Card';
import { CustomSelect } from '../../components/CustomControls/CustomSelect';
import { PatientPicker } from '../../components/CustomControls/PatientPicker';
import { PractitionerSelect } from '../../components/CustomControls/PractitionerSelect';
import { ProtocolPicker } from '../../components/CustomControls/ProtocolPicker';
import ExportToExcel from "../../components/Export/ExportToExcel";
import { IconSVG, Lock } from '../../components/Icons/Icons';
import { HandleApiError, HandleError } from '../../components/Notifications/APIErrorHandler';
import { Success } from '../../components/Notifications/Notifications';
import { getQueryParam } from '../../components/Utils/Commons';
import { ModalSpinner } from '../../components/Utils/Loaders';
import { AddAppointmentContainer } from './AddAppointment';
import { EditAppointmentContainer } from './EditAppointment';
import { useEvents, useMasterData } from './Hooks';
import { ScheduleLockContainer } from './ScheduleLock';

require('moment/locale/es.js');

// Setup the localizer by providing the moment Object to the correct localizer.
moment.locale('es', { week: { dow: 0, doy: 0, }, });

const localizer = momentLocalizer(moment);

export const Agenda = (props) => {
    const { t } = useTranslation();
    const currentPatient = getQueryParam(props.location.search, "medicalRecordNumber");
    const [, , isAuthorized] = useAuthorization();

    const [modal, setModal] = useState(null);
    const [query, setQuery] = useState({ view: "week", currentDate: moment().format("YYYY-MM-DD"), projects: [], protocols: [], practitioners: [], type: {}, medicalRecord: currentPatient });
    const [events, , setEvents] = useEvents(query);
    const [masterdata] = useMasterData();

    const isValidEventStart = (startDate) => {
        if (moment().isSameOrAfter(startDate)) {
            return false;
        }
        return true;
    }

    const handleNavigate = (currentDate, currentView) => {
        var newQuery = JSON.parse(JSON.stringify(query));
        newQuery.view = currentView;
        newQuery.currentDate = moment(currentDate).format("YYYY-MM-DD");

        setQuery(newQuery);
    }

    const handleViewChange = (currentView) => {
        var newQuery = JSON.parse(JSON.stringify(query));
        newQuery.view = currentView;
        setQuery(newQuery);
    }

    const handleFilterChange = (name, selectedValues) => {
        var newQuery = JSON.parse(JSON.stringify(query));
        switch (name) {
            case "practitioners":
                newQuery[name] = selectedValues ? selectedValues.map(x => x.userId) : [];
                break;
            case "protocols":
                newQuery[name] = selectedValues ? [selectedValues.id] : [];
                break;
            case "projects":
                newQuery[name] = selectedValues ? selectedValues.map(x => x.id) : [];
                break;
            case "medicalRecord":
                newQuery[name] = selectedValues ? selectedValues.medicalRecordNumber : null;
                break;
            default:
                newQuery[name] = selectedValues;
                break;
        }

        setQuery(newQuery);
    }

    const handleNewAppointment = () => {

        if (!isAuthorized(["Agenda.Create"]))
            return;

        setModal(
            <AddAppointmentContainer
                onClose={handleModalClose}
                onSubmit={handleSubmit}
                currentPatient={currentPatient}
                masterdata={masterdata.visitTypes}
            />
        );
    }

    const handleSubmit = async (selectedAppointment) => {

        if (!isValidEventStart(selectedAppointment.start)) {
            HandleError("calendarErrors.Event_Create_StartLowerThanNow");
            setModal(
                <AddAppointmentContainer
                    onClose={handleModalClose}
                    onSubmit={handleSubmit}
                    currentPatient={currentPatient}
                    masterdata={masterdata.visitTypes}
                />
            );
            return;
        }

        const event = {
            title: selectedAppointment.patient.fullName,
            patient: selectedAppointment.patient,
            visitType: selectedAppointment.visitType,
            medicalInsuranceId: selectedAppointment.medicalInsurance?.id,
            medicalInsurancePlanId: selectedAppointment.medicalInsurance?.plan,
            medicalServiceId: selectedAppointment.medicalService?.id,
            start: selectedAppointment.start,
            end: selectedAppointment.end,
            practitioner: selectedAppointment.practitioner,
            project: selectedAppointment.project,
            protocol: selectedAppointment.protocol,
            location: selectedAppointment.location,
            desc: selectedAppointment.desc,
            isFirstTime: selectedAppointment.isFirstTime
        };

        //console.log(event);
        //return;

        try {
            setModal(<ModalSpinner isShowing={true} />);
            var res = await createEvent(event);
            event.id = res.data.id;

            setEvents([].concat(events, event));
            setModal(null);
            Success("calendarNotifications.Event_Created");

            if (currentPatient) {
                const source = getQueryParam(props.location.search, "source");
                if (source)
                    props.history.push(source)
            }
        }
        catch (error) {
            HandleApiError(error);
            console.log(error);
            setModal(
                <AddAppointmentContainer
                    onClose={handleModalClose}
                    onSubmit={handleSubmit}
                    currentPatient={currentPatient}
                    masterdata={masterdata.visitTypes}
                />
            );
        }
    }

    const handleNewScheduleLock = () => {

        if (!isAuthorized(["Agenda.Create"]))
            return;

        setModal(
            <ScheduleLockContainer
                onClose={handleModalClose}
                onSubmit={handleSubmitScheduleLock}
            />
        );
    }

    const handleSubmitScheduleLock = async (scheduleLock) => {

        try {
            setModal(<ModalSpinner isShowing={true} />);
            var res = await createScheduleLockAPI(scheduleLock);
            if (res && res.data && res.data.appointmentsTaken?.length > 0)
                setModal(<ScheduleLockAppointmentsTaken appointmentsTaken={res.data.appointmentsTaken} onClose={handleModalClose} />)
            else
                setModal(null);
            setQuery(JSON.parse(JSON.stringify(query)));
            Success("calendarNotifications.ScheduleLock_Created");
        }
        catch (error) {
            HandleApiError(error);
            console.log(error);
            setModal(null);
        }
    }

    const handleSelectedEvent = (event) => {
        setModal(
            <ViewEvent
                event={event}
                handleModalClose={handleModalClose}
                handleViewEdit={handleViewEdit}
                handleAttended={handleAttended}
                handleNotAttendance={handleNotAttendance}
                handleEventStatus={handleEventStatus}
                handleCancelEvent={handleCancelEvent}
                handleSetPresent={handleSetPresent}
            />
        );
    }

    const handleEventStatus = (eventId, confirmed) => {

        const event = events.find(x => x.id === eventId);
        const idx = events.findIndex(x => x.id === eventId);

        if (!isValidEventStart(event.start)) {
            HandleError("calendarErrors.Event_UpdateStatus_StartLowerThanNow");
            return;
        }

        const updatedEvent = { ...event, confirmed: confirmed };

        updateEventStatus(eventId, confirmed).then(res => {
            const nextEvents = [...events];
            nextEvents.splice(idx, 1, updatedEvent);
            setEvents(nextEvents);
            setModal(null);
        }).catch(error => {
            HandleApiError(error);
        });
    }

    const handleAttended = (eventId) => {

        const event = events.find(x => x.id === eventId);
        const idx = events.findIndex(x => x.id === eventId);

        if (moment().isSameOrBefore(event.start)) {
            return false;
        }

        const updatedEvent = {
            ...event,
            attended: true
        }

        updateEventAttendance(eventId).then(res => {

            const nextEvents = [...events];
            nextEvents.splice(idx, 1, updatedEvent);
            setEvents(nextEvents);
            setModal(null);
        }).catch(error => {
            HandleApiError(error);
        });
    }

    const handleNotAttendance = (eventId) => {
        setModal(
            <ConfirmRemove
                onClose={() => setModal(null)}
                onConfirm={onNotAttendance}
                id={eventId}
                title={t("calendar.notAssistModalTitle")}
            />
        );
    }

    const onNotAttendance = (eventId, reason) => {

        const event = events.find(x => x.id === eventId);
        const idx = events.findIndex(x => x.id === eventId);

        if (moment().isSameOrBefore(event.start)) {
            return false;
        }

        const updatedEvent = {
            ...event,
            attended: false
        }

        notAttendanceAppointment(eventId, reason).then(res => {
            const nextEvents = [...events];
            nextEvents.splice(idx, 1, updatedEvent);
            setEvents(nextEvents);
            setModal(null);
        }).catch(error => {
            HandleApiError(error);
        });
    }

    const handleViewEdit = (event) => {
        if (!isValidEventStart(event.start)) {
            HandleError("calendarErrors.calendarErrors.Event_Update_StartLowerThanNow");
            return;
        }

        if (!isAuthorized(["Agenda.Edit"]))
            return;

        setModal(
            <EditAppointmentContainer
                onSubmit={editEvent}
                onClose={handleModalClose}
                masterdata={masterdata.visitTypes}
                appointment={event}
            />
        );
    }

    const editEvent = (event) => {
        if (!isValidEventStart(event.start)) {
            HandleError("calendarErrors.Event_Update_StartLowerThanNow");
            return;
        }

        const updatedEvent = {
            ...event,
            start: event.start,
            end: event.end,
            desc: event.desc,
            location: event.location,
            isFirstTime: event.isFirstTime
        }

        updateEvent(updatedEvent).then(res => {
            setQuery(JSON.parse(JSON.stringify(query)));
            setModal(null);
            Success("calendarNotifications.Event_Updated")
        }).catch(error => {
            HandleApiError(error);
            setModal(
                <EditAppointmentContainer
                    onSubmit={editEvent}
                    onClose={handleModalClose}
                    masterdata={masterdata.visitTypes}
                    appointment={event}
                />);
            console.log(error);
        });
    }

    const handleCancelEvent = (event) => {
        setModal(
            <ConfirmRemove
                onClose={() => setModal(null)}
                onConfirm={onCancelEvent}
                id={event.id}
                title={t("calendar.cancelModalTitle")}
            />
        );
    }

    const onCancelEvent = (eventId, reason) => {
        setModal(null);

        const event = events.find(x => x.id === eventId);

        if (!isValidEventStart(event.start)) {
            HandleError("calendarErrors.Event_Delete_StartLowerThanNow");
            return;
        }

        const idx = events.findIndex(x => x.id === eventId);

        cancelEvent(event.id, reason).then(res => {
            const nextEvents = [...events];
            nextEvents.splice(idx, 1);
            setEvents(nextEvents);
            setModal(null);
            Success("calendarNotifications.Event_Deleted")

        }).catch(error => {
            HandleApiError(error);
        });
    }

    const handleModalClose = () => {
        if (currentPatient) {
            const source = getQueryParam(props.location.search, "source");
            if (source)
                props.history.push(source)
        }
        setModal(null);
        setQuery(JSON.parse(JSON.stringify(query)));
    }

    const eventStyleGetter = (event) => {
        return { className: visitTypeColorGetter(event.visitType?.code, event, false) }
    }

    const handleSetPresent = async (eventId) => {
        if (!eventId)
            return;

        try {
            const res = await setPresent(eventId);
            setModal(null);
            Success("calendarNotifications.Event_Present")
        }
        catch (error) {
            setModal(null);
            HandleApiError(error);
        }

    }

    const formats = {
        eventTimeRangeFormat: () => null,
        dayFormat: (date, culture, localizer) => {
            const day = localizer.format(date, 'dddd D', culture);
            return day.charAt(0).toUpperCase() + day.slice(1)
        },
        dayRangeHeaderFormat: ({ start, end }, culture, localizer) => {
            const startFormat = localizer.format(start, 'D MMMM', culture);
            const endFormat = localizer.format(end, 'D MMMM YYYY', culture);
            return `${startFormat} - ${endFormat}`;
        },
        dayHeaderFormat: (date, culture, localizer) => {
            return localizer.format(date, 'D [de] MMMM [de] YYYY', culture);
        },
        agendaHeaderFormat: ({ start }, culture, localizer) => {
            return localizer.format(start, 'dddd D [de] MMMM [de] YYYY', culture);
        }

    };

    const messages = {
        previous: "<",
        next: ">",
        today: t('calendar.today'),
        month: t('calendar.month'),
        week: t('calendar.week'),
        day: t('calendar.day'),
        date: t('calendar.date'),
        time: t('calendar.time'),
        event: t('calendar.event'),
        noEventsInRange: t('calendar.noEventsInRange'),
        showMore: total => `+${total} ${t('calendar.more')}`,
    };

    return (
        <Card
            header={
                <div className="row">
                    <div className="col-md-3">
                        <WithAuth requiredPermission={["Agenda.Create"]}>
                            <button className={`btn btn-primary`} onClick={handleNewAppointment}>
                                <IconSVG name="Plus" /> {t('calendar.newAppointmentTitle')}
                            </button>
                            <button className={`btn btn-primary btn-right`} onClick={handleNewScheduleLock}>
                                <Lock /> {t('calendar.scheduleLock')}
                            </button>
                        </WithAuth>
                    </div>
                    <div className="col-md-2">
                        <CustomSelect name="type" placeholder={t('filters.type')} options={masterdata.visitTypes ?? []} onChange={handleFilterChange} isClearable styles={colourStyles} />
                    </div>
                    <div className="col-md-2">
                        <ProtocolPicker
                            id="agenda-protocol-picker"
                            name="protocols"
                            multiple={false}
                            placeholder={t("filters.protocol")}
                            onChange={handleFilterChange} />
                    </div>
                    <div className="col-md-2">
                        <PatientPicker
                            id="agenda-patient-picker"
                            name="medicalRecord"
                            multiple={false}
                            placeholder={t("filters.patient")}
                            onChange={handleFilterChange} />
                    </div>
                    <div className="col-md-2">
                        <PractitionerSelect name="practitioners" placeholder={t('filters.professional')} isMulti={true} onChange={handleFilterChange} />
                    </div>
                    <div className="col-md-1">
                        <ExportToExcel filters={query} exportToExcel={exportToExcel} />
                    </div>
                </div>
            }

            content={
                <>
                    {modal}
                    <div className="row">
                        <div className="col-md-12" style={{ height: '500pt' }}>
                            <BigCalendar
                                popup
                                events={events}
                                localizer={localizer}
                                culture="es"
                                length={1} //Agenda days
                                step={20}
                                defaultView="week"
                                //selectable
                                resizable
                                scrollToTime={new Date()}
                                onSelectEvent={event => handleSelectedEvent(event)}
                                onView={handleViewChange}
                                onNavigate={handleNavigate}
                                eventPropGetter={(eventStyleGetter)}
                                components={{
                                    event: Event,
                                    month: { event: EventMonth },
                                    agenda: { event: EventAgenda },
                                    timeGutterHeader: () => (t('calendar.time')),
                                }}

                                formats={formats}
                                messages={messages}
                            />
                        </div>
                    </div>
                </>
            }
        />
    );
}