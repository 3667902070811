import React from "react";
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { Spinner } from "../Utils/Loaders";

export const MedicalServiceInfo = ({ medicalService, isLoading }) => {
    const { t } = useTranslation();

    if (isLoading)
        return <Spinner />

    if (!medicalService || !medicalService.service)
        return null;

    return (
        <Row>
            <Col sm={12}>
                <div className="info">
                    <div><span className="card-label">Servicio Médico:</span> <span className="card-text">{medicalService.service?.serviceNameReference}</span></div>
                    <div><span className="card-label">Cubre la Obra Social:</span> <span className="card-text">{medicalService.isCoveredByMedicalInsurance ? "Si" : "No"}</span></div>
                    {
                        medicalService.isCoveredByMedicalInsurance && medicalService.coverRequirements &&
                        <div><span className="card-label">Requiere Aprobación:</span> <span className="card-text">{medicalService.coverRequirements?.requiresApproval ? "Si" : "No"}</span></div>
                    }
                    {
                        medicalService.coverRequirements && medicalService.coverRequirements.requiredCopago &&
                        <div><span className="card-label">Requiere Copago:</span> <span className="card-text">{medicalService.coverRequirements?.copagoComments}</span></div>
                    }
                    {
                        !medicalService.isCoveredByMedicalInsurance && medicalService.price &&
                        <>
                            <div><span className="card-label">Precio:</span> <span className="card-text">{medicalService.service?.serviceNameReference}</span></div>
                            <div>
                                <span className="card-label">El importe informado puede variar al momento de realizar la visita. </span>
                            </div>
                        </>
                    }
                </div>
            </Col>
        </Row>
    );
}