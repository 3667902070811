import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { useTranslation, withTranslation } from 'react-i18next';
import { putFamilyHistory } from "../../../api/medicalRecord";
import { AuditModal, CreateAudit, CreateAuditModal } from "../../../components/AuditTrail/AuditModal";
import { ExpandableContent } from "../../../components/CustomControls/ExpandableContent";
import { FamilyHistory } from "../../../components/MedicalRecords/FamilyHistory";
import { FamilyHistoryForm } from "../../../components/MedicalRecords/Forms/FamilyHistoryForm";
import { getMedicalRecordsSchema } from "../../../components/Validations/FormValidationSchema";
import { AuditTrail } from "../../Admin/AuditTrail";
import { useFamilyHistories, useFamilyHistory } from "../Hooks/useFamilyHistory";

export const FamilyHistoryContainer = (props) => {
    const [content, setContent] = useState(null);
    const { entryStatus, entryId, expandedAll } = props;
    const [data, query, totalSize, isLoading, handleTableChange, setReload] = useFamilyHistories(null, entryId);
    const { t } = useTranslation();

    useEffect(() => {
        if (props.contentToReload?.contentName === "familyHistory")
            setReload(true);
    }, [props.contentToReload]);

    const handleEdit = (id) => {
        setContent(<FamilyHistoryEditContainer
            entryId={entryId}
            id={id}
            onSubmit={handleSubmit}
            onCancel={handleClose}
        />);
    }

    const handleSubmit = async (data) => {

        if (entryStatus === "Published" || entryStatus === "AutoPublished") {
            setContent(<CreateAuditModal
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmit(data, reason, comments)}
            />)
        }
        else {
            onConfirmSubmit(data);
        }
    }

    const onConfirmSubmit = async (data, reason, comments) => {
        try {            
            if (data && data.familyHistories && data.familyHistories.length > 0) {
                let familyHistory = data.familyHistories[0];
                if (reason) {
                    familyHistory.auditReason = reason;
                    familyHistory.auditComments = comments;
                }

                await putFamilyHistory(entryId, familyHistory.id, familyHistory);
                setContent(null);
                setReload(true);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleClose = () => {
        setContent(null);
    }

    const handleAuditTrail = (entityId) => {
        setContent(
            <AuditModal
                onClose={() => { setContent(null); }}
                title="Auditoría"
            >
                <AuditTrail entity="FamilyHistory" entityId={entityId} />
            </AuditModal>
        )
    }

    if (totalSize === 0)
        return null;

    return (
        <ExpandableContent title={t("medicalRecords.familyHistory.title")} expandedAll={expandedAll} content="familyHistory">
            {content}
            <FamilyHistory
                data={data}
                entryId={entryId}
                withCard={false}
                query={query}
                totalSize={totalSize}
                isLoading={isLoading}
                onTableChange={handleTableChange}
                onEdit={handleEdit}
                onAuditTrail={handleAuditTrail}
            />
        </ExpandableContent>
    );
}

export const FamilyHistoryCreateContainer = (props) => {
    const { entryStatus, entryId, onSubmit } = props;
    const [, , create] = useFamilyHistory(entryId);
    const [content, setContent] = useState(null);

    const handleSubmit = async (data) => {

        if (entryStatus === "Published" || entryStatus === "AutoPublished") {
            setContent(<CreateAudit
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmit(data, reason, comments)}
            />)
        }
        else {
            onConfirmSubmit(data);
        }
    }

    const onConfirmSubmit = async (data, reason, comments) => {
        try {
            if (reason) {
                data.auditReason = reason;
                data.auditComments = comments;
            }
            console.log(data)
            await create(entryId, data);
            setContent(null);
            onSubmit("familyHistory")
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleCancel = () => { props.hide() }

    return (
        <>
            {
                content ??
                <FamilyHistoryContainerFormik
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                />
            }
        </>

    );
}

const FamilyHistoryEditContainer = (props) => {
    const { entryId, id, onSubmit } = props;
    const [data, isLoading] = useFamilyHistory(entryId, id);

    if (!data || isLoading)
        return null;

    return (
        <FamilyHistoryContainerFormik
            onSubmit={onSubmit}
            onCancel={props.onCancel}
            familyHistory={data}
        />
    );
}

const FamilyHistoryContainerFormik = withTranslation()(withFormik({
    mapPropsToValues: (props) => (props.familyHistory && { familyHistories: [props.familyHistory] }),

    validationSchema: getMedicalRecordsSchema().familyHistory,

    handleSubmit: (values, formikBag) => {
        formikBag.props.onSubmit(values);
    },

    displayName: 'FamilyHistoryForm',

})(FamilyHistoryForm));

FamilyHistoryContainerFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    familyHistory: PropTypes.object.isRequired,
};