import PropTypes from 'prop-types';
import React from 'react';
import Card from '../Cards/Card';
import { RichTextControl } from '../CustomControls/RichTextControl';
import { IconSVG } from '../Icons/Icons';

const Entry = (props) => {
    const {
        entry,
        text,
        onEntryChange,
        rows,
        isDisabled,
        style
    } = props;

    const onChangeText = (_name, value) => {
        onEntryChange(value);
    }

    const getGroup = () => {
        if (!entry)
            return null;

        switch (entry.groupCode) {
            case "PROJECT": return (<>
                <IconSVG name="project" />
                <span>{`${entry.group}: ${entry.project}`}</span>
            </>);
            case "PROTOCOL": return (<>
                <IconSVG name="protocol" />
                <span>{`${entry.group} - ${entry.type}: ${entry.protocol}`}</span>
            </>);
            default:
                return (<>
                    <IconSVG name="visitGroup" />
                    <span>Evolución {entry.group}</span>
                </>);
        }
    }

    if (!entry)
        return null;

    return (
        <Card
            className="card-evolution-text"
            header={<div className="category">
                {getGroup()}
            </div>}
            content={
                <RichTextControl
                    autoFocus
                    name="text"
                    style={style}
                    value={text ?? ""}
                    isDisabled={isDisabled}
                    onChange={onChangeText}
                />
            }
        />
    );
}

Entry.defaultProps = {
    rows: 5,
    isDisabled: false
};

Entry.propTypes = {
    entry: PropTypes.object.isRequired,
    rows: PropTypes.number,
    isDisabled: PropTypes.bool,
};

export default Entry;

