import { useState } from 'react';
import { getSummaries, getSummaryForEntry, getSummariesForEntry, getSummary, createSummary, updateSummary } from '../../../api/mrSummaries';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';


export const useSummaries = () => {
    const [isLoading, setIsLoading] = useState(true);

    const getAll = async (medicalRecordNumber) => {
        try {
            setIsLoading(true);
            const summaries = getSummaries(medicalRecordNumber);
            setIsLoading(false);
            return summaries;
        } catch (error) {
            HandleApiError(error);
            setIsLoading(false);
            return null;
        }
    }

    const getForEntry = async (medicalRecordNumber, entryId) => {
        try {
            setIsLoading(true);
            const summary = await getSummaryForEntry(medicalRecordNumber, entryId);
            setIsLoading(false);
            return summary;
        } catch (error) {
            HandleApiError(error);
            setIsLoading(false);
            return null;
        }
    }

    const getAllForEntry = async (medicalRecordNumber, entryId) => {
        try {
            setIsLoading(true);
            const summaries = await getSummariesForEntry(medicalRecordNumber, entryId);
            setIsLoading(false);
            return summaries;
        } catch (error) {
            HandleApiError(error);
            setIsLoading(false);
            return null;
        }
    }

    const get = async (medicalRecordNumber, summaryId) => {
        try {
            return getSummary(medicalRecordNumber, summaryId);
        } catch (error) {
            HandleApiError(error);
            setIsLoading(false);

            return null;
        }
    }

    const create = async (medicalRecordNumber, entryId, vm) => {
        try {
            setIsLoading(true);
            const summary = await createSummary(medicalRecordNumber, entryId, vm);
            setIsLoading(false);

            return summary;

        } catch (error) {
            HandleApiError(error);
            setIsLoading(false);

            return null;
        }
    }

    const update = async (medicalRecordNumber, summaryId, vm) => {
        try {
            setIsLoading(true);
            const summary = await updateSummary(medicalRecordNumber, summaryId, vm);
            setIsLoading(false);

            return summary;

        } catch (error) {
            HandleApiError(error);
            setIsLoading(false);

            return null;
        }
    }

    return [isLoading, getAll, getForEntry, getAllForEntry, get, create, update];
}