import { ErrorMessage, withFormik } from 'formik'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { Col, ControlLabel, FormControl, Grid, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { NavLink } from "react-router-dom"
import ProtocolContext from '../../containers/Protocols/ProtocolContext/ProtocolContext'
import { formTypes } from '../../variables/Enums'
import Card from '../Cards/Card'
import { CustomSelect } from '../CustomControls/CustomSelect'
import { Help, Remove } from '../Icons/Icons'
import { dateFormatter } from '../Utils/Formatter'
import { Popover } from '../Utils/Popover'
import { Tooltip } from '../Utils/Tooltips'
import { FormErrorMessage } from '../Validations/FormValidationErrors'

const Header = props => {
    const {
        handleSubmit,
        handleBlur,
        handleChange,
        setFieldValue,
        setFieldTouched,
        onTypeChange,
        values,
        t
    } = props;

    const context = useContext(ProtocolContext);
    const isDisabled = context.isDisabled || props.isDisabled;
    const emptyHeader = {
        title: '',
        type: null,
    };
    if (!values.form) {
        values.form = emptyHeader;
    }

    const handleRemove = () => {
        props.onRemove(values.form.id);
    }

    const handleTypeChange = (name, value) => {

        if (value)
            onTypeChange(value.value)

        setFieldValue(name, value, false);
    }

    const handleSaveAsTemplate = () => {
        props.onSaveAsTemplate();
    }

    const title = (
        <div>
            {t("protocols.visit.formTemplate.headerTitle")}: {values.form.title}
            {
                values.form.id && !isDisabled &&
                <>
                    <Tooltip tooltip="Eliminar formulario" id="tooltipremoveForm">
                        <button className="btn btn-fill btn-danger btn-right" onClick={handleRemove}>
                            <Remove style={{ fontSize: '20px' }} />
                        </button>
                    </Tooltip>
                </>
            }
        </div>
    );
    const subtitle = (values.form.created && (<div><ControlLabel>{t("commons.created")}:</ControlLabel> {dateFormatter(values.form.created, "DD/MMM/YYYY HH:mm")} - <ControlLabel>{t("commons.createdBy")}:</ControlLabel> {values.form.createdBy?.fullName}</div>));

    return (
        <Card
            title={title}
            subtitle={subtitle}
            content={
                <form onSubmit={handleSubmit} autoComplete="off">
                    <Grid fluid>
                        <Row>
                            <Col componentClass={ControlLabel} sm={2}>
                                <ControlLabel>{t("protocols.visit.formTemplate.formTitle")}</ControlLabel>
                            </Col>
                            <Col sm={6}>
                                <FormControl type="text" name={`form.title`} value={values.form.title} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                <ErrorMessage name={`form.title`} component={FormErrorMessage} />
                            </Col>
                        </Row>
                        <Row>
                            <Col componentClass={ControlLabel} sm={2}>
                                <ControlLabel>{t("protocols.visit.formTemplate.formType")}</ControlLabel>
                            </Col>
                            <Col sm={2}>
                                <CustomSelect
                                    value={props.formType ? formTypes.filter(x => x.value == props.formType) : null}
                                    onChange={handleTypeChange}
                                    onBlur={setFieldTouched}
                                    name={`form.type`}
                                    placeholder={t("commons.select")}
                                    options={formTypes}
                                    getOptionLabel={x => t(`protocols.visit.formTemplate.formTypes.${x.value}`)}
                                    getOptionValue={x => x.value}
                                    isDisabled={isDisabled}
                                />
                                <ErrorMessage name={`form.type`} component={FormErrorMessage} />
                            </Col>
                            <Col sm={1}>
                                <Popover
                                    id="help-tooltip"
                                    trigger='hover focus'
                                    title="Información"
                                    popover="Al seleccionar un tipo de formulario, los campos visualizados en verde se sincronizarán a la Historia Clínica."
                                >
                                    <>
                                        <Help style={{ fontSize: '24px', marginTop: '7px', marginLeft: '-10px' }} />
                                    </>
                                </Popover>
                            </Col>
                        </Row>
                    </Grid>
                    {
                        values.form.id &&
                        <button type="button" className="btn btn-fill btn-primary" onClick={handleSaveAsTemplate} disabled={isDisabled}>
                            {t("buttons.saveAsTemplate")}
                        </button>
                    }
                    <button type="submit" className="btn btn-success btn-right" disabled={isDisabled}>{t("buttons.save")}</button>
                    <NavLink to={`/admin/protocols/${props.protocolId}/visitsTemplate/${props.visitId}`} className="btn btn-default btn-right">
                        {t("buttons.back")}
                    </NavLink>
                    <div className="clearfix"></div>
                </form>
            }
        />
    );
};

export const DynamicFormHeader = withTranslation()(withFormik({

    displayName: 'DynamicFormHeader',
    enableReinitialize: true,
    mapPropsToValues: (props) => ({ form: props.form }),
    handleSubmit: (formData, formikBag) => {
        formData.form.type = formData.form.type?.value;
        formikBag.props.onSubmit(formData.form);
    },
})(Header));

DynamicFormHeader.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onSaveAsTemplate: PropTypes.func.isRequired,
    protocolId: PropTypes.string.isRequired,
    visitId: PropTypes.string.isRequired,
    form: PropTypes.object.isRequired,
    formType: PropTypes.string,
}