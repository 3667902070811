import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from 'react';
import { Bar, Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    ChartDataLabels,
    Title,
    Tooltip,
    Legend
);

export const Chart = ({ data, type, options }) => {

    if (!data || data.length === 0)
        return null;

    switch (type) {
        case "bar":
            return <Bar options={options} data={data} />;
        case "line":
            return <Line options={options} data={data} />;

        default:
            return <Bar options={options} data={data} />;
    }
}