import axios from './index';

// DocumentNotes
export const getSummaries = async (medicalRecordNumber) => {
    return axios.get(`api/v1/MedicalRecordsSummary/${medicalRecordNumber}`);
};
export const getSummaryForEntry = async (medicalRecordNumber, entryId) => {
    return axios.get(`api/v1/MedicalRecordsSummary/${medicalRecordNumber}/entry/${entryId}`);
};
export const getSummariesForEntry = async (medicalRecordNumber, entryId) => {
    return axios.get(`api/v1/MedicalRecordsSummary/${medicalRecordNumber}/entry/${entryId}/all`);
};
export const getSummary = async (medicalRecordNumber, summaryId) => {
    return axios.get(`api/v1/MedicalRecordsSummary/${medicalRecordNumber}/summary/${summaryId}`);
};
export const createSummary = async (medicalRecordNumber, entryId, vm) => {
    return axios.post(`api/v1/MedicalRecordsSummary/${medicalRecordNumber}/entry/${entryId}`, vm);
};
export const updateSummary = async (medicalRecordNumber, summaryId, summary) => {
    return axios.put(`api/v1/MedicalRecordsSummary/${medicalRecordNumber}/summary/${summaryId}`, summary);
};