import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import { addPractitioners, uploadPractitionerAttachment } from '../../../api/protocol';
import { HandleApiError } from "../../../components/Notifications/APIErrorHandler";
import { Success } from "../../../components/Notifications/Notifications";
import { PractitionersForm } from "../../../components/Protocols/Practitioners";
import { getProtocolPractitionersSchema } from "../../../components/Validations/FormValidationSchema";
import { useStudyRoles } from "../../Security/Authorization/useStudyRoles";
import { useStudyTasks } from '../Hooks';


export const AddPractitionerModal = (props) => {
    const { isShowing, hide, protocolId, submit } = props;
    const { t } = useTranslation();

    return isShowing ? ReactDOM.createPortal(
        <React.Fragment>
            <BootstrapModal show={true} onHide={hide} bsSize="large" backdrop="static" >
                <BootstrapModal.Header closeButton>
                    <BootstrapModal.Title>{t('protocols.practitioner.addPractitionerTitle')}</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    <ProtocolAddPractitionersContainer protocolId={protocolId} onClose={hide} onSubmit={submit} />
                </BootstrapModal.Body>
            </BootstrapModal>
        </React.Fragment>, document.body
    ) : null;
};

const ProtocolAddPractitionersContainer = (props) => {
    const protocolId = props.protocolId;
    const studyTasks = useStudyTasks(protocolId);
    const [studyRoles] = useStudyRoles();

    return (
        <ProtocolAddPractitionersFormik
            protocolId={protocolId}
            studyTasks={studyTasks}
            roles={studyRoles ? studyRoles : []}
            onSubmit={props.onSubmit}
            onCancel={props.onClose}
        />
    );
}

const ProtocolAddPractitionersFormik = withTranslation()(withFormik({
    mapPropsToValues: () => ({}),

    validationSchema: getProtocolPractitionersSchema(),

    handleSubmit: (values, formikBag) => {
        //console.log("Posting Practitioners:", values.practitioners);

        values.practitioners.map(x => { x.studyRoleId = x.studyRole.id; x.studyRole = x.studyRole.name })

        // Add relation Protocol - Practitioner
        addPractitioners(formikBag.props.protocolId, { practitioners: values.practitioners })
            .then(res => {
                var items = res.data

                // Upload Attachmens
                var promises = [];
                values.practitioners.forEach(relation => {
                    // Upload attachments if any. 
                    if (relation.attachments && relation.attachments.length > 0) {
                        const attachmentsUploads = relation.attachments.map(async attachment => {
                            // Initial FormData
                            const formData = new FormData();
                            formData.append("attachment", attachment);
                            formData.append("title", attachment.name);

                            // Make an AJAX upload request using Axios
                            try {
                                await uploadPractitionerAttachment(formikBag.props.protocolId, relation.practitioner.userId, formData);
                                //uploaded = true;
                            }
                            catch (error) {
                                console.log(error.response);
                                var message = formikBag.props.t("protocolNotifications.Attachment_Error") + attachment.title;
                                console.log(message);
                                HandleApiError(error);
                            }
                        });

                        promises = promises.concat(attachmentsUploads);
                    }
                });

                // Once all the files are uploaded 
                Promise.all(promises)
                    .then((response) => {
                        //console.log(response)
                        formikBag.setSubmitting(false);
                        Success("protocolNotifications.practitioners_Added");
                        formikBag.props.onSubmit(items);
                    })
                    .catch(error => console.log(`Error in executing ${error}`));

            }).catch(error => {
                console.log(error);
                formikBag.setSubmitting(false);
                HandleApiError(error);
            });
    },

    displayName: 'AddPractitioners',

})(PractitionersForm));

ProtocolAddPractitionersFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    protocolId: PropTypes.string.isRequired,
};