import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { editPractitioner, getPractitioners, uploadPractitionerAttachment } from "../../../api/protocol";
import { CreateAuditModal } from "../../../components/AuditTrail/AuditModal";
import { WithAuth } from "../../../components/Authorization/WithAuthProtocol";
import Card from "../../../components/Cards/Card";
import { Add } from "../../../components/Icons/Icons";
import { HandleApiError } from "../../../components/Notifications/APIErrorHandler";
import { Success } from "../../../components/Notifications/Notifications";
import { Practitioners } from "../../../components/Protocols/Practitioners";
import ProtocolContext from "../ProtocolContext/ProtocolContext";
import { AddPractitionerModal } from "./AddPractitioners";
import { EditPractitionerModal } from "./EditPractitioner";

export const ProtocolPractitionersContainer = ({ protocolId }) => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const context = useContext(ProtocolContext);
    const [practitioners, setPractitioners] = useState([]);
    const [totalSize, setTotalSize] = useState(0);
    const [reload, setReload] = useState(false);
    const [query, setQuery] = useState({
        pagination: {
            page: 1,
            sizePerPage: 10,
            totalSize: 0
        },
        sort: {
            sortField: "FullName",
            sortOrder: "desc"
        }
    });
    useEffect(() => {
        const fetchPractitionersData = () => {
            setIsLoading(true);
            setReload(false);
            getPractitioners(protocolId, query)
                .then(response => {
                    var paginatedData = response.data
                    //console.log("GET Practitioners: ", paginatedData);

                    setTotalSize(paginatedData.count);
                    setPractitioners(paginatedData.data);
                    setIsLoading(false);
                }).catch(error => {
                    HandleApiError(error);
                    setIsLoading(false);
                });
        }

        fetchPractitionersData();
    }, [protocolId, query, reload]);

    const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {

        if (type === 'pagination') {
            let newQuery = JSON.parse(JSON.stringify(query));

            newQuery.pagination.page = page;
            newQuery.pagination.sizePerPage = sizePerPage;

            setQuery(newQuery);
        }

        if (type === 'sort') {
            let newQuery = JSON.parse(JSON.stringify(query));

            newQuery.sort.sortField = sortField;
            newQuery.sort.sortOrder = sortOrder;

            setQuery(newQuery);
        }
    }

    const [modal, setModal] = useState(null);
    const handleEditPractitioner = (practitionerUserId) => {
        setModal(<EditPractitionerModal
            protocolId={protocolId}
            practitionerId={practitionerUserId}
            hide={() => setModal(null)}
            submit={handleEditedPractitioner}
            isShowing={true} />);
    }

    const handleEditedPractitioner = (practitioner) => {
        setModal(<CreateAuditModal
            onClose={() => setModal(null)}
            onSave={(reason, comments) => confirmEdited(practitioner, reason, comments)}
        />)
    }

    const confirmEdited = (practitioner, reason, comments) => {
        practitioner.audit = {
            reason: reason,
            comments: comments
        }

        if (practitioner.studyRole && practitioner.studyRole.id) {
            practitioner.studyRoleId = practitioner.studyRole.id;
            practitioner.studyRole = practitioner.studyRole.name;
        }

        // Add relation Protocol - Practitioner
        editPractitioner(protocolId, practitioner.userId, practitioner)
            .then(res => {
                //var items = res.data

                // Upload Attachmens
                var promises = [];
                // Upload attachments if any. 
                if (practitioner.newattachments && practitioner.newattachments.length > 0) {
                    const attachmentsUploads = practitioner.newattachments.map(async attachment => {
                        // Initial FormData
                        const formData = new FormData();
                        formData.append("attachment", attachment.file);
                        formData.append("title", attachment.title);

                        // Make an AJAX upload request using Axios
                        try {
                            await uploadPractitionerAttachment(protocolId, practitioner.userId, formData);
                        }
                        catch (error) { HandleApiError(error); }
                    });

                    promises = promises.concat(attachmentsUploads);
                }

                // Once all the files are uploaded 
                Promise.all(promises)
                    .then(() => {
                        Success("protocolNotifications.practitioner_Updated");
                        setModal(null);
                        setReload(true);
                    })
                    .catch(error => console.log(`Error in executing ${error}`));

            }).catch(error => { HandleApiError(error); });

    }

    const handleAddPractitioner = () => {
        setModal(<AddPractitionerModal
            protocolId={protocolId}
            hide={() => setModal(null)}
            submit={() => { setModal(null); setReload(true) }}
            isShowing={true} />);
    }

    return (
        <Card
            header={
                <WithAuth protocolId={protocolId} requiredPermission={["Practitioners.Add"]}>
                    <button onClick={handleAddPractitioner} className="btn btn-primary" disabled={context.isDisabled}>
                        <Add /> {t('protocols.practitioner.addPractitionerTitle')}
                    </button>
                    {modal}
                </WithAuth>
            }
            content={
                <Practitioners
                    isLoading={isLoading}
                    protocolId={protocolId}
                    practitioners={practitioners}
                    query={query}
                    totalSize={totalSize}
                    onTableChange={handleTableChange}
                    onEdit={!context.isDisabled && handleEditPractitioner}
                />
            }
        />
    );
}