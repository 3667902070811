import PropTypes from "prop-types";
import React, { useContext, useState } from 'react';
import { ControlLabel, FormControl, FormGroup, HelpBlock, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { getLaboratoryData } from '../../api/admin';
import '../../assets/sass/lbd/_dynamic-form.scss';
import ProtocolContext from "../../containers/Protocols/ProtocolContext/ProtocolContext";
import Card from '../Cards/Card';
import { AdherenceControl } from '../CustomControls/Adherence';
import { CustomCheckbox } from '../CustomControls/CustomCheckbox';
import { CustomDate, CustomDateTime, CustomTime } from "../CustomControls/CustomDateTime";
import { CustomFileUpload } from "../CustomControls/CustomFileUpload";
import { CustomRadio } from '../CustomControls/CustomRadio';
import { AdverseEventsAsync, CustomSelect, CustomSelectAsync } from "../CustomControls/CustomSelect";
import { SnomedPicker } from '../CustomControls/SnomedPicker';
import { YesNo, YesNoNotApply } from '../CustomControls/YesNoControls';
import { Config, Remove, Sync } from '../Icons/Icons';
import { Tooltip } from "../Utils/Tooltips";

const getDynamicField = (field, indexComp, indexChild, index) => {
    let name = `fields[${index}]`;
    if (indexChild !== null && indexChild !== undefined) {
        name = `fields[${index}].components[${indexComp}].fields[${indexChild}]`;
    }

    switch (field._t) {
        case 'DividerField':
            return (
                <FormGroup key={"formHorizontal" + field.name}>
                    <hr />
                </FormGroup>
            );
        case 'TextField':
            return (
                <FormGroup key={"formHorizontal" + field.name}>
                    <ControlLabel>{field.label}</ControlLabel>
                </FormGroup>
            );
        case 'SingleLineField':
            return (<FormControl type="text" placeholder={field.placeholder} disabled={true} />);
        case 'MultiLineField':
            return (<FormControl componentClass="textarea" placeholder={field.placeholder} disabled={true} />);
        case 'IntegerField':
        case 'DecimalField':
            if (field.text && field.text !== "") {
                return (
                    <InputGroup>
                        {field.textPosition == 0 ? <InputGroup.Addon>{field.text}</InputGroup.Addon> : ""}
                        <FormControl type="number" placeholder={field.placeholder} disabled={true} />
                        {field.textPosition == 1 ? <InputGroup.Addon>{field.text}</InputGroup.Addon> : ""}
                    </InputGroup>);
            }
            else {
                return (<FormControl type="number" placeholder={field.placeholder} disabled={true} />);
            }
        case 'DateField':
            return (<CustomDate
                disabled={true}
                value={field.value}
                name={`fields[${index}].value`}
                onChange={() => { }}
                onBlur={() => { }}
                closeOnSelect={true}
            />);
        case 'TimeField':
            return (<CustomTime
                disabled={true}
                value={field.value ?? new Date()}
                name={`fields[${index}].value`}
                onChange={() => { }}
                onBlur={() => { }}
                closeOnSelect={true}
            />);
        case 'DateTimeField':
            return (<CustomDateTime
                disabled={true}
                value={field.value}
                name={`fields[${index}].value`}
                onChange={() => { }}
                onBlur={() => { }}
                closeOnSelect={true}
            />);
        case 'BooleanField':
            return (<YesNo
                disabled={true}
                value={field.value}
                onChange={() => { }}
                name={`fields[${index}].value`}
                isClearable={!field.required} />);
        case 'YesNoField':
            return (<YesNoNotApply
                disabled={true}
                value={field.value}
                onChange={() => { }}
                name={`fields[${index}].value`}
                isClearable={!field.required} />);
        case 'SnomedField':
            return (<SnomedPicker
                disabled={true}
                id={field.name}
                value={field.value}
                name={`fields[${index}].value`}
                placeholder={field.placeholder}
                onChange={() => { }}
                onBlur={() => { }}
                conceptId={field.snomedExpression} />);
        case 'SelectField':
            let options = field.options.split("\n").map(option => { return { id: option, title: option } });
            return (<CustomSelect
                isDisabled={true}
                value={field.value}
                onChange={() => { }}
                onBlur={() => { }}
                name={`fields[${index}].value`}
                placeholder={field.placeholder}
                options={options} />);
        case 'SelectAsyncField':
            return (<CustomSelectAsync
                isDisabled={true}
                value={field.value}
                onChange={() => { }}
                onBlur={() => { }}
                name={`fields[${index}].value`}
                placeholder={field.placeholder}
                masterData={field.masterData?.code} />);
        case 'AdverseEventsAsyncField':
            return (<AdverseEventsAsync
                isDisabled={true}
                value={field.value}
                onChange={() => { }}
                onBlur={() => { }}
                name={`fields[${index}].value`}
                placeholder={field.placeholder}
                eventId={field.eventId}
                medicalRecordId={field.medicalRecordId} />);
        case 'CheckboxField':
            return (<CustomCheckbox
                disabled={true}
                name={`fields[${index}].value`}
                label={field.placeholder}
                isChecked={field.value ? field.value : ''}
                onChange={() => { }} />);
        case 'MultiCheckBoxField':
            let multicheckOptions = field.options.split("\n").map((option, idx) => { return { id: `${idx}_${field.order}`, title: option } });
            return multicheckOptions.map(option => {
                return (<CustomCheckbox
                    disabled={true}
                    key={`${name}.value.${option.id}`}
                    name={`${name}.value`}
                    label={option.title}
                    checked={false}
                    onChange={() => { }}
                />);
            });
        case 'RadioField':
            let radioOptions = field.options.split("\n").map(option => { return { id: option, title: option } });
            return radioOptions.map(option => {
                return (<CustomRadio
                    disabled={true}
                    key={`fields[${index}].value.${option.id}`}
                    name={`fields[${index}].value`}
                    label={option.title}
                    number={option.id}
                    option={option.id}
                    onChange={() => { }}
                    onBlur={() => { }}
                />);
            });
        case 'FileUploadField':
            return (<CustomFileUpload
                disabled={true}
                value={field.value}
                name={`fields[${index}].value`}
                placeholder={field.placeholder}
                onChange={() => { }}
            />);
        case 'AdherenceField':
            return (<AdherenceControl
                isDisabled={true}
                placeholder={field.placeholder}
                name={`fields[${index}].value`}
                value={field.value}
                onChange={() => { }}
                onBlur={() => { }}
            />);
        default:
            return (<FormControl type="text" disabled placeholder="TypeField not implemented yet." name={`fields[${index}].value`} value={field.value} onChange={() => { }} onBlur={() => { }} />);
    }
}

const getArrayFieldComponent = (field, index, props) => {
    return (<Card
        content={
            field.template.sort((function (a, b) { return a.order - b.order; }))
                .map(function (fieldRec, indexRec) {
                    return (
                        <FormGroup key={`formHorizontal-${index}-${indexRec}-${fieldRec.name}`}>
                            <ControlLabel>{fieldRec.label}  {fieldRec.syncTo && <Sync />}</ControlLabel>
                            {getDynamicField(fieldRec, 0, indexRec, index, props)}
                            <div className="field-container-options">
                                <Tooltip tooltip="Configurar campo" id={`tooltipconfig-${indexRec}`}>
                                    <button className="btn btn-fill btn-default btn-lg" name={field.name} id={`${index}_${indexRec}`} onClick={() => props.onConfigFromTemplateClick(index, indexRec)}><Config /> </button>
                                </Tooltip>
                                <Tooltip tooltip="Eliminar campo" id={`tooltipremove-${indexRec}`}>
                                    <button className="btn btn-fill btn-danger btn-lg" name={field.name} id={`${index}_${indexRec}`} onClick={() => props.onRemoveFromTemplateClick(index, indexRec)}><Remove /> </button>
                                </Tooltip>
                            </div>
                            <HelpBlock>{fieldRec.note}</HelpBlock>
                        </FormGroup >
                    )
                })
        }
    />);
}

export const DynamicFormCreatorViewer = props => {
    const { fields } = props;
    const { t } = useTranslation();
    const context = useContext(ProtocolContext);
    const isDisabled = context.isDisabled || props.isDisabled;

    const [data, setData] = useState({ units: [], significances: [], templates: [] });
    const [templateLaboSelected, setTemplateLaboSelected] = useState(null);
    const fetchData = () => {
        getLaboratoryData()
            .then(response => {
                setData(response.data);
            }).catch(error => {
                setData({});
            });
    }

    const handleChangeLaboTemplate = (_name, selectedTemplates, index) => {
        if (selectedTemplates && selectedTemplates.length > 0) {
            let components = [];

            selectedTemplates.forEach(selectedTemplate => {
                components = components.concat(
                    selectedTemplate.resultItems.map(item => {
                        return ({
                            fields: [
                                {
                                    "Value": {
                                        "ConceptId": item.conceptId,
                                        "Description": item.description
                                    },
                                    "SnomedExpression": "71388002",
                                    "Required": true,
                                    "Label": "Nombre del Estudio",
                                    "Placeholder": "Seleccione",
                                    "Note": null,
                                    "SyncTo": "Name",
                                    "_t": "SnomedField",
                                    "Name": "Name",
                                    "Order": item.order
                                },
                                {
                                    "Value": null,
                                    "Required": true,
                                    "Label": "Resultado",
                                    "Placeholder": "Seleccione",
                                    "Note": null,
                                    "SyncTo": "Result",
                                    "_t": "SingleLineField",
                                    "Name": "Result",
                                    "Order": item.order
                                },
                                {
                                    "Value": data.units.filter(x => x.title == item.unit)[0],
                                    "MasterData": {
                                        "Id": 0,
                                        "Code": "Units",
                                        "Title": null,
                                        "Description": null
                                    },
                                    "Options": null,
                                    "Required": true,
                                    "Label": "Unidad",
                                    "Placeholder": "Seleccione",
                                    "Note": "",
                                    "SyncTo": "Unit",
                                    "_t": "SelectAsyncField",
                                    "Name": "Unit",
                                    "Order": item.order
                                }
                            ]
                        });
                    })
                );
            });
            setTemplateLaboSelected(selectedTemplates);
            props.onChangeLaboTemplate(index, components);
        }
        else {
            setTemplateLaboSelected(null);
            props.onChangeLaboTemplate(index, []);
        }
    }

    const handleConfigClick = (event) => {
        props.onConfigClick(event.currentTarget.id);
    }
    const handleRemoveClick = (event) => {
        props.onRemoveClick(event.currentTarget.id);
    }

    const fieldsComponent = fields.sort((function (a, b) { return a.order - b.order; }))
        .map(function (field, index) {
            let fieldComponent = null;
            switch (field._t) {
                case 'ArrayField':
                    let templateLaboComponent = null;
                    if (field.componentsType === "LaboratoryResults") {
                        if (data.templates.length === 0)
                            fetchData();

                        templateLaboComponent = (
                            <CustomSelect
                                onChange={(name, selected) => handleChangeLaboTemplate(name, selected, index)}
                                value={templateLaboSelected}
                                name={"labotemplates"}
                                placeholder={t("commons.selectTemplate")}
                                options={data.templates}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                isClearable={true}
                                isMulti={true}
                            />
                        );
                    }
                    return (
                        <div className="field-container" key={`field-container-${index}`}>
                            {templateLaboComponent}
                            {getArrayFieldComponent(field, index, props)}
                            <div className="field-container-options">
                                <Tooltip tooltip="Eliminar campo" id={`tooltipremove-${index}`}>
                                    <button className="btn btn-fill btn-danger btn-sm" disabled={isDisabled} name={field.name} id={index} onClick={handleRemoveClick}><Remove /></button>
                                </Tooltip>
                            </div>
                        </div>

                    );
                case 'DividerField':
                    return (
                        <div key={`field-container-${index}`} className="field-container">
                            {getDynamicField(field, null, null, index, props)}
                            <div className="field-container-options">
                                <Tooltip tooltip="Eliminar campo" id={`tooltipremove-${index}`}>
                                    <button className="btn btn-fill btn-danger btn-sm" name={field.name} id={index} onClick={handleRemoveClick}><Remove /></button>
                                </Tooltip>
                            </div>
                        </div>
                    );
                case 'TextField':
                    fieldComponent = getDynamicField(field, null, null, index, props);
                    break;
                default:
                    fieldComponent = (
                        <FormGroup key={"formHorizontal" + field.name}>
                            <ControlLabel>{field.label}  {field.syncTo && <Sync />}</ControlLabel>
                            {getDynamicField(field, null, null, index, props)}
                            <HelpBlock>{field.note}</HelpBlock>
                        </FormGroup>
                    );
                    break;
            }

            return (<div key={`field-container-${index}`} className="field-container">
                {fieldComponent}
                <div className="field-container-options">
                    <Tooltip tooltip="Configurar campo" id={`tooltipconfig-${index}`}>
                        <button className="btn btn-fill btn-default btn-lg" name={field.name} disabled={isDisabled} id={index} onClick={handleConfigClick}><Config /> </button>
                    </Tooltip>
                    <Tooltip tooltip="Eliminar campo" id={`tooltipremove-${index}`}>
                        <button className="btn btn-fill btn-danger btn-lg" name={field.name} disabled={isDisabled} id={index} onClick={handleRemoveClick}><Remove /> </button>
                    </Tooltip>
                </div>
            </div>);
        });

    const DragHandle = sortableHandle(() => <div className="SortableHelper"></div>);
    const SortableItem = sortableElement(({ field }) => (
        <li className="sortable-field-item">
            <DragHandle />
            {field}
        </li>
    ));
    const SortableContainer = sortableContainer(({ children }) => {
        return <ul className="sortable-field-list">{children}</ul>;
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        props.onSortEnd(oldIndex, newIndex);
    };

    return (
        <div className="card">
            <div className="header">
                <h4 className="title">{t("protocols.visit.formTemplate.dynamicTitle")}</h4>
            </div>
            <div className="content">
                {
                    fields &&
                    <SortableContainer onSortEnd={onSortEnd} useDragHandle>
                        {fieldsComponent.map((fieldComponent, index) => (
                            <SortableItem key={`item-${index}`} index={index} field={fieldComponent} disabled={isDisabled} />
                        ))}
                    </SortableContainer>
                }

                <div className="clearfix"></div>
            </div>
        </div>);
};

DynamicFormCreatorViewer.propTypes = {
    onRemoveClick: PropTypes.func.isRequired,
    onConfigClick: PropTypes.func.isRequired,
    onConfigFromTemplateClick: PropTypes.func.isRequired,
    onRemoveFromTemplateClick: PropTypes.func.isRequired,
    onSortEnd: PropTypes.func.isRequired,
    onChangeLaboTemplate: PropTypes.func.isRequired,
    fields: PropTypes.array.isRequired,
    isDisabled: PropTypes.bool.isRequired
};