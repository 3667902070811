import React, { useState } from 'react';
import { Col, Grid, Nav, NavItem, Row, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAuthProtocol } from '../../components/Authorization/useAuthorization.js';
import { ProtocolInfo } from '../../components/Protocols/ProtocolInfo.jsx';
import { ProtocolBriefContainer } from './Brief/Brief.jsx';
import { ProtocolPractitionersContainer } from './Practitioners/Practitioners.jsx';
import { ProtocolProvider } from './ProtocolContext/ProtocolProvider.js';
import { VisitsTrackingContainer } from "./Tracking/VisitsTracking";
import { ProtocolVisitsContainer } from './Visits/Visits.jsx';

const ProtocolContainer = (props) => {
    const { match: { params: { protocolId, section } } } = props;
    const { t } = useTranslation();
    const [isAuthorized] = useAuthProtocol(protocolId);
    const [selectedTab, setSelectedTab] = useState(section ?? "brief");

    const handleChangeTab = (tab) => {
        setSelectedTab(tab)
    }

    return (
        <ProtocolProvider protocolId={protocolId}>
            <Grid fluid>
                <Row className="display-flex">
                    <Col md={12} lg={12}>
                        <ProtocolInfo />
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={12}>
                        <Tab.Container id="protocol-home" defaultActiveKey="brief" activeKey={selectedTab} onSelect={handleChangeTab}>
                            <Row className="clearfix">
                                <Col sm={12}>
                                    <Nav bsStyle="tabs">
                                        {
                                            isAuthorized(["Brief.View"]) && <NavItem eventKey="brief">{t("protocols.header.description")}</NavItem>
                                        }
                                        {
                                            isAuthorized(["Practitioners.View"]) && <NavItem eventKey="practitioners">{t("protocols.header.practitioners")}</NavItem>
                                        }
                                        {
                                            isAuthorized(["Visits.View"]) && <NavItem eventKey="visitsTemplate">{t("protocols.header.visits")}</NavItem>
                                        }
                                        {
                                            isAuthorized(["VisitTracking.View"]) && <NavItem eventKey="visitTracking">{t("protocols.header.visitTracking")}</NavItem>
                                        }
                                    </Nav>
                                </Col>
                                <Col sm={12}>
                                    <Tab.Content animation mountOnEnter={true}>
                                        <Tab.Pane eventKey="brief">
                                            <ProtocolBriefContainer />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="practitioners">
                                            <ProtocolPractitionersContainer protocolId={protocolId} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="visitsTemplate">
                                            <ProtocolVisitsContainer protocolId={protocolId} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="visitTracking">
                                            <VisitsTrackingContainer protocolId={protocolId} {...props} />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Col>
                </Row>
            </Grid>
        </ProtocolProvider>
    );
}

export default ProtocolContainer;