import React from "react";
import { Button, Modal as BootstrapModal } from 'react-bootstrap';
import ReactDOM from 'react-dom';

const SessionTimeoutDialog = ({ open, countdown, onLogout, onContinue }) => {

  return open ? ReactDOM.createPortal(
    <React.Fragment>
      <BootstrapModal show={true} backdrop="static" >
        <BootstrapModal.Header>
          <BootstrapModal.Title>Cierre de Sesión</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          <div>
            La sesión se cerrará en <span>{countdown}</span> segundos por inactividad. ¿Desea continuar con la sesión?
          </div>
        </BootstrapModal.Body>
        <BootstrapModal.Footer>
          <Button onClick={onLogout}>
            Cerrar Sesión
        </Button>
          <Button onClick={onContinue}>
            Continuar
        </Button>
        </BootstrapModal.Footer>
      </BootstrapModal>
    </React.Fragment>, document.body
  ) : null;
};

export default SessionTimeoutDialog;