import PropTypes from "prop-types";
import React from "react";
import { Button, Modal as BootstrapModal, Table } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import { Cancel, View } from "../Icons/Icons";
import { dateTimeFormatter } from "../Utils/Formatter";
import { Tooltip } from "../Utils/Tooltips";

export const ModalDuplicatedItem = (props) => {
    const { items, item, onHide } = props;
    const { t } = useTranslation();

    const getItemName = () => {
        switch (item) {
            case "adverseEvent": return t('medicalRecords.adverseEvents.title');
            case "drugs": return t('medicalRecords.drugs.title');
            case "procedures": return t('medicalRecords.procedures.title');
            case "personalHistory": return t('medicalRecords.personalHistory.title');
            default:
                return "items";
        }
    }

    const getItemPropertyName = () => {
        switch (item) {
            case "adverseEvent":
            case "personalHistory": return "adverseEffect";
            case "drugs": return "drug";
            case "procedures": return "procedure";
            default:
                return "name";
        }
    }

    return ReactDOM.createPortal(
        <React.Fragment>
            <BootstrapModal show={true} onHide={onHide} bsSize="large" backdrop="static" bsStyle="warning">
                <BootstrapModal.Header closeButton>
                    <BootstrapModal.Title>¡Atención!</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    Los siguientes {getItemName()} no han sido agregados por estar duplicados
                    {
                        <Table striped bordered responsive style={{ marginTop: '40px' }}>
                            <thead>
                                <tr>
                                    <th>Creado</th>
                                    <th>{getItemName()}</th>
                                    <th>Desde</th>
                                    <th>Hasta</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    items.map((item, idx) => {
                                        return (
                                            <tr key={`patient-${idx}`}>
                                                <td>{dateTimeFormatter(item.created)}</td>
                                                <td>{item[getItemPropertyName()]}</td>
                                                <td>{item.from}</td>
                                                <td>{item.to}</td>
                                                <td>
                                                    <Tooltip tooltip={"Ir a Visita"} id={"tooltipview-" + idx}>
                                                        <NavLink to={`/admin/medicalRecords/${props.medicalRecordNumber}/entry/${item.entryId}`} className="nav-link" activeClassName="active">
                                                            <View style={{ height: '25px', width: '25px', marginRight: '5px', verticalAlign: 'middle' }} />
                                                        </NavLink>
                                                    </Tooltip>
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </Table>
                    }
                </BootstrapModal.Body>
                <BootstrapModal.Footer>
                    <Button bsStyle="default" onClick={onHide}><Cancel /> {t('buttons.close')}</Button>
                </BootstrapModal.Footer>
            </BootstrapModal>
        </React.Fragment>, document.body
    )
};

ModalDuplicatedItem.propTypes = {
    onHide: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    item: PropTypes.string.isRequired
};