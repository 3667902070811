export const getLabelColor = (name) => {
    let _value = "";
    switch (name) {
        case "EXTERNAL": _value = "label-external"; break;
        case "PROTOCOL": _value = "label-protocol"; break;
        case "PROJECT": _value = "label-project"; break;
        default: break;
    }

    return _value;
}

export const getButtonColor = (name) => {

    switch (name) {
        case "EXTERNAL": return "btn-external";
        case "PROTOCOL": return "btn-protocol";
        case "PROJECT": return "btn-project";
        default: return "btn-default";
    }
}

export const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const generateLink = (mentionParams) => {
    if (!mentionParams)
        return "/"
    var urlParams = new URLSearchParams(mentionParams);
    if (urlParams.has("from")) {
        switch (urlParams.get('from')) {
            case "VisitTracking":
                return `/admin/protocols/${urlParams.get('protocolId')}/visitTracking/${urlParams.get('visitId')}/patient/${urlParams.get('patientId')}?&formId=${urlParams.get('formId')}`;
            case "VisitTrackingExtra":
                return `/admin/protocols/${urlParams.get('protocolId')}/visitTrackingExtra/${urlParams.get('visitId')}/patient/${urlParams.get('patientId')}`;
            default:
                return "";
        }
    }
}

export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const getQueryParam = (url, name) => {
    return new URLSearchParams(url).get(name);
}

export const getFullNameFromUserMention = (mention) => {
    const fullNameRegex = /@\[(.*?)]\(((\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1})\)/;
    var matches = fullNameRegex.exec(mention);

    if (matches != null && matches[1]) {
        return mention.replace(matches[0], `'${matches[1]}'`);
    }

    return mention;
}

export const capitalize = (s, onlyFirst = true, split = ".") => {
    if (typeof s !== 'string') return ''

    if (onlyFirst)
        return s.charAt(0).toUpperCase() + s.slice(1)

    const words = s.split(split);

    return words.map((word) => {
        return word[0].toUpperCase() + word.slice(1);
    }).join(split);

}

/*!
 * Get an object value from a specific path
 * @param  {Object}       obj  The object
 * @param  {String|Array} path The path
 * @param  {*}            def  A default value to return [optional]
 * @return {*}                 The value
 */
export const getWithPath = function (obj, path, def) {

	/**
	 * If the path is a string, convert it to an array
	 * @param  {String|Array} path The path
	 * @return {Array}             The path array
	 */
	var stringToPath = function (path) {

		// If the path isn't a string, return it
		if (typeof path !== 'string') return path;

		// Create new array
		var output = [];

		// Split to an array with dot notation
		path.split('.').forEach(function (item) {

			// Split to an array with bracket notation
			item.split(/\[([^}]+)\]/g).forEach(function (key) {

				// Push to the new array
				if (key.length > 0) {
					output.push(key);
				}

			});

		});

		return output;

	};

	// Get the path as an array
	path = stringToPath(path);

	// Cache the current object
	var current = obj;

	// For each item in the path, dig into the object
	for (var i = 0; i < path.length; i++) {

		// If the item isn't found, return the default (or null)
		if (!current[path[i]]) return def;

		// Otherwise, update the current  value
		current = current[path[i]];

	}

	return current;

};