import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types'
import { withFormik } from 'formik';
import { withTranslation } from 'react-i18next';

import { usePersonMasterData, useUser } from "./Hooks";
import { updateUser, uploadSign } from "../../api/identity";

import { HandleApiError } from '../../components/Notifications/APIErrorHandler';
import { Success } from '../../components/Notifications/Notifications';
import { UserProfileForm } from "../../components/Users/UserProfile";
import { getUserProfileSchema } from "../../components/Validations/FormValidationSchema";
import { uploadCerts } from "../../api/digitalSign";
import { useAuthorization } from "../../components/Authorization/useAuthorization";

export const EditUserContainer = (props) => {
    const { match: { params } } = props;
    const userId = params.userId;
    let history = useHistory();

    const [isLoading, masterData] = usePersonMasterData();
    const [isUserLoading, user] = useUser(userId);
    const [, currentUser] = useAuthorization();
   
    const _isValidImageSize = (imageToCheck, width, height) => {
        var _URL = window.URL || window.webkitURL;
        var file, img, isValid = true;
        if ((file = imageToCheck)) {
            img = new Image();
            var objectUrl = _URL.createObjectURL(file);
            img.onload = function () {
                if (this.width != width || this.height != height) {
                    alert(`El tamaño de la imagen debe ser de ${width}x${height}`);
                    isValid = false;
                }
                _URL.revokeObjectURL(objectUrl);
            };
            img.src = objectUrl;
        }

        return isValid;
    }

    const handleUploadSign = async (sign) => {

        if (!sign || !sign.file || !_isValidImageSize(sign.file, 400, 200))
            return;

        // Initial FormData
        const formData = new FormData();
        formData.append("file", sign.file);
        formData.append("title", sign.fileName);

        // Make an AJAX upload request using Axios
        try {
            const res = await uploadSign(formData);
            Success("identityNotifications.sign_Uploaded");
        }
        catch (error) {
            console.log(error.response);
        }
    }

    if (isLoading || isUserLoading || !user.userId) return null;

    user.password = "";

    return (
        <EditUserFormik
            onSubmit={() => history.push(`/admin/users/${user.userId}`)}
            onCancel={() => history.goBack()}
            onUploadSign={handleUploadSign}
            hideCancel={props.hideCancel}
            masterData={masterData}
            user={user}
            currentUser={currentUser?.sub}
        />
    );
}

const EditUserFormik = withTranslation()(withFormik({

    mapPropsToValues: (props) => (props.user),
    enableReinitialize: true,
    validationSchema: getUserProfileSchema(),

    handleSubmit: async (userProfile, formikBag) => {

        userProfile.bloodGroup = userProfile.bloodGroup != null ? userProfile.bloodGroup.title : "";
        userProfile.bloodFactor = userProfile.bloodFactor != null ? userProfile.bloodFactor.title : "";
        if (userProfile.speciality) {
            //if (typeof userProfile.speciality === 'string')
            //    userProfile.speciality = userProfile.speciality;
            if (typeof userProfile.speciality === 'object' && userProfile.speciality.constructor === Object)
                userProfile.speciality = userProfile.speciality.description;
        }

        try {
            await updateUser(formikBag.props.user.userId, userProfile);

            if (userProfile.rsaPublicCert && userProfile.password) {
                await uploadCerts({
                    rsaPublicCert: userProfile.rsaPublicCert,
                    rsaPrivateCert: userProfile.rsaPrivateCert,
                    password: userProfile.password
                });
            }

            formikBag.setSubmitting(false);
            Success("userNotifications.user_Updated");
            formikBag.props.onSubmit();

        }
        catch (ex) {
            console.log(ex);
            formikBag.setSubmitting(false);
            HandleApiError(ex);
            //formikBag.props.onCancel();
        }
    },

    displayName: 'UserForm',

})(UserProfileForm));

EditUserFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onUploadSign: PropTypes.func.isRequired,
    masterData: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};