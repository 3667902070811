import React from 'react';
import { useProtocol } from '../Hooks';
import ProtocolContext from './ProtocolContext';

export const ProtocolProvider = (props) => {

    const [isProtocolLoading, brief, closeProtocol] = useProtocol(props.protocolId);    

    return (
        <ProtocolContext.Provider
            value={{
                isLoading: isProtocolLoading,
                protocol: brief,
                protocolId: props.protocolId,
                closeProtocol: closeProtocol,
                isDisabled: brief && brief.status && brief.status.code === "Closed"
            }}
        >
            {props.children}
        </ProtocolContext.Provider>
    );
}