import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Audit } from '../../components/AuditTrail/Audit';
import { HandleApiError } from '../../components/Notifications/APIErrorHandler';
import { getAuditTrail } from '../../api/admin';

export const AuditTrail = ({ medicalRecordNumber, entity, entityId, fields }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [audit, setAudit] = useState([]);
    useEffect(() => {
        const fetchData = () => {
            setIsLoading(true);
            getAuditTrail(entity, entityId)
                .then(response => {
                    var auditTrails = response.data;
                    //console.log(auditTrails)
                    setAudit(auditTrails);
                    setIsLoading(false);
                })
                .catch(error => {
                    HandleApiError(error);
                    setIsLoading(false);
                })
        }

        fetchData();
    }, [entity, entityId]);

    if (fields && fields.length > 0) {
        return (
            <Audit audit={audit} fields={fields} isLoading={isLoading} />
        );
    }
    else {
        return (
            <Audit audit={audit} isLoading={isLoading} medicalRecordNumber={medicalRecordNumber}/>
        );
    }
}

AuditTrail.propTypes = {
    entity: PropTypes.string.isRequired,
    entityId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    fields: PropTypes.array,
    medicalRecordNumber: PropTypes.string,
};