import React, { useEffect, useState } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { createForm, editForm, getForm, removeForm } from '../../../api/protocol';
import { createFormTemplate, getFormTemplates, removeFormTemplate } from '../../../api/protocolTemplates';
import { useAuthProtocol } from '../../../components/Authorization/useAuthorization.js';
import { DynamicFormComponents } from '../../../components/DynamicForm/DynamicFormComponents';
import { DynamicFormControls } from '../../../components/DynamicForm/DynamicFormControls';
import { DynamicFormCreatorViewer } from '../../../components/DynamicForm/DynamicFormCreatorViewer';
import { DynamicFormHeader } from '../../../components/DynamicForm/DynamicFormHeader';
import { DynamicOptionsFormik } from '../../../components/DynamicForm/DynamicFormOptions';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';
import { Success } from '../../../components/Notifications/Notifications';
import { formTypeFields, snomedExpressions } from '../../../variables/Enums';
import { ProtocolProvider } from '../ProtocolContext/ProtocolProvider';
import { RemoveModal } from './RemoveVisit';

export const VisitForm = (props) => {
    const { match: { params: { protocolId, formId, visitId } } } = props;
    let history = useHistory();
    const { t } = useTranslation();
    const [isAuthorized] = useAuthProtocol(protocolId);
    const [isLoading, setIsLoading] = useState(false);
    const [form, setForm] = useState(null);
    const [formType, setFormType] = useState(null);
    const [fields, setFields] = useState([]);
    const [fieldConfig, setFieldConfig] = useState(null);
    const [fieldSettings, setFieldSettings] = useState(null);
    const [modal, setModal] = useState(null);
    let isSaving = false;

    useEffect(() => {
        const fetchData = () => {
            setIsLoading(true);
            getForm(protocolId, visitId, formId)
                .then(response => {
                    var formResponse = response.data
                    //console.log("GET Form: ", formResponse);
                    setFormType(formResponse.type);
                    setForm(formResponse);
                    setFields(formResponse.fields)
                    setIsLoading(false);
                }).catch(error => {
                    HandleApiError(error);
                    setIsLoading(false);
                });
        }
        if (formId)
            fetchData();
        else
            setForm({
                type: '',
                title: ''
            });
    }, [protocolId, visitId, formId]);

    const [formTemplates, setFormTemplates] = useState([]);
    useEffect(() => {
        const fetchData = () => {
            setIsLoading(true);
            getFormTemplates(protocolId)
                .then(response => {
                    setFormTemplates(response.data);
                    setIsLoading(false);
                }).catch(error => {
                    setIsLoading(false);
                });
        }
        fetchData();
    }, [protocolId]);

    function _getSnomed(type) {
        //console.log(type)
        switch (type) {
            case "AdverseEvent": return snomedExpressions.Hallazgos;
            case "Drug": return snomedExpressions.Producto_O_Sustancia;
            case "Procedure": return snomedExpressions.Procedimiento;
            default: return snomedExpressions.Hallazgos;
        }
    }

    function _configField(field) {
        //console.log(field)
        switch (field._t) {
            case 'TextField':
                field.label = "Configurar campo para insertar texto aqui...";
                break;
            case 'SingleLineField':
            case 'MultiLineField':
            case 'YesNoField':
            case 'BooleanField':
            case 'DateField':
            case 'TimeField':
            case 'DateTimeField':
            case 'CheckboxField':
            case 'AdverseEventsAsyncField':
            case 'FileUploadField':
                field.required = false;
                field.placeholder = "placeholder";
                field.value = null;
                break;
            case 'IntegerField':
            case 'DecimalFIeld':
                field.required = false;
                field.placeholder = "placeholder";
                field.value = null;
                field.text = "";
                field.textposition = 1;
                break;
            case 'RadioField':
            case 'MultiCheckBoxField':
            case 'SelectField':
                field.required = false;
                field.placeholder = "placeholder";
                field.value = null;
                field.options = 'Ejemplo de Opción 1\nEjemplo de Opción 2';
                break;
            case 'SelectAsyncField':
                field.required = false;
                field.placeholder = "placeholder";
                field.value = null;
                field.note = "Configurar campo para seleccionar datos."
                field.masterData = "";
                field.options = [
                    { code: "Actions", title: "Acciones" },
                    { code: "EntryGroups", title: "Grupos" },
                    { code: "EntryTypes", title: "Tipo de Entrada" },
                    { code: "Intensity", title: "Intensidad" },
                    { code: "RelationWithDrugs", title: "Relación con Droga" },
                    { code: "Relatives", title: "Parentezco" },
                    { code: "RoutesOfAdministration", title: "Forma de administración" },
                    { code: "Units", title: "Unidad" },
                    { code: "Frequencies", title: "Frecuencia" },
                ];
                break;
            case 'SnomedField':
                field.required = false;
                field.placeholder = "placeholder";
                field.value = null;
                field.snomedExpression = _getSnomed(field.syncTo); //"404684003";
                field.note = "Configurar el campo para seleccionar la expresión SNOMED CT."
                break;
            default:
                break;
        }
    }

    const handleAddFormFieldControlClick = (selectedField) => {

        let field = {
            _t: selectedField.fieldType,
            order: fields.length + 1,
            name: selectedField.fieldType,
            syncTo: selectedField.value,
            label: t(`protocols.visit.formTemplate.formTypeFields.${selectedField.value}`),
            note: '',
        }

        _configField(field);

        let fieldsCopy = JSON.parse(JSON.stringify(fields));
        fieldsCopy.push(field);
        setFields(fieldsCopy);
        setFieldSettings({ type: "field", fieldIndex: fieldsCopy.length - 1 });
        field._t !== "DividerField" && setFieldConfig(field);
    }
    const handleAddControlClick = (event) => {
        let code = event.currentTarget.name;
        let fieldsCopy = JSON.parse(JSON.stringify(fields));
        let field = {
            _t: code,
            order: fieldsCopy.length + 1,
            name: code,
            label: code,
            note: '',
            syncTo: ''
        }

        _configField(field);

        fieldsCopy.push(field);
        setFields(fieldsCopy);
        setFieldSettings({ type: "field", fieldIndex: fieldsCopy.length - 1 });
        field._t !== "DividerField" && setFieldConfig(field);
    }
    const handleAddComponentsClick = (template) => {

        if (!template || !template.fields)
            return;

        let fieldsCopy = JSON.parse(JSON.stringify(fields));
        const currentFieldsCount = fieldsCopy.length;

        template.fields.forEach((field, index) => {
            field.order = currentFieldsCount + index;
            fieldsCopy.push(field);
        });

        setFormType(template.type)
        setFields(fieldsCopy);
    }
    const handleRemoveClick = (fieldIndex) => {
        let result = fields.filter((_field, index) => index != fieldIndex);
        setFields(result);
        setFieldConfig(null);
    }

    const handleRemoveFromTemplateClick = (fieldIndex, templateFieldIndex) => {
        let fieldsCopy = JSON.parse(JSON.stringify(fields));
        fieldsCopy[fieldIndex].template = fields[fieldIndex].template.filter((_field, index) => index != templateFieldIndex);
        setFields(fieldsCopy);
        setFieldConfig(null);
    }

    const handleConfigClick = (fieldIndex) => {
        let field = JSON.parse(JSON.stringify(fields[fieldIndex]));
        setFieldConfig(field);
        setFieldSettings({ type: "field", fieldIndex })
    }
    const handleOptionsSubmit = (options) => {
        let fieldsCopy = JSON.parse(JSON.stringify(fields));

        if (fieldSettings.type === "field") {
            fieldsCopy.splice(fieldSettings.fieldIndex, 1, options); //Reemplazo field. 
        }
        else if (fieldSettings.type === "arrayField") {
            fieldsCopy[fieldSettings.fieldIndex].template.splice(fieldSettings.templateFieldIndex, 1, options); //Reemplazo field. 
        }

        setFields(fieldsCopy);
        setFieldConfig(null);
        setFieldSettings(null);
    }

    const handleConfigFromTemplateClick = (fieldIndex, templateFieldIndex) => {
        let field = JSON.parse(JSON.stringify(fields[fieldIndex].template[templateFieldIndex]));
        setFieldConfig(field);
        setFieldSettings({ type: "arrayField", fieldIndex, templateFieldIndex });
    }

    const handleSaveClick = (formHeader) => {
        //setIsLoading(true);
        let formCopy = JSON.parse(JSON.stringify(form));
        formCopy.title = formHeader.title;
        formCopy.type = formHeader.type ?? formType;
        setForm(formCopy); // Header del form
        if (fields.length == 0 || isSaving) {
            return;
        }

        formCopy.fields = fields;
        formCopy._Fields = "";

        if (!formId) {
            isSaving = true;
            createForm(protocolId, visitId, formCopy)
                .then(() => {
                    isSaving = false;
                    Success("protocolNotifications.form_Created");
                    history.push(`/admin/protocols/${protocolId}/visitsTemplate/${visitId}`);
                }).catch(error => {
                    HandleApiError(error);
                });
        }
        else {
            isSaving = true;
            editForm(protocolId, visitId, formId, formCopy)
                .then(() => {
                    isSaving = false;
                    Success("protocolNotifications.form_Updated");
                    history.push(`/admin/protocols/${protocolId}/visitsTemplate/${visitId}`);
                }).catch(error => {
                    HandleApiError(error);
                });
        }
        isSaving = false;
    }

    const handleSortEnd = (oldIndex, newIndex) => {

        if (oldIndex == newIndex)
            return;

        // Hago sort en cliente. 
        let copiedFields = fields.slice();
        copiedFields.splice(newIndex < 0 ? copiedFields.length + newIndex : newIndex, 0, copiedFields.splice(oldIndex, 1)[0]);

        copiedFields.forEach((field, index) => {
            field.order = index + 1;
        });

        setFields(copiedFields);
    };

    const handleRemoveForm = (formId) => {
        setModal(<RemoveModal
            id={formId}
            title="¿Está seguro?"
            onClose={() => { setModal(null); }}
            onConfirm={handleConfirmRemove} />)
    }
    const handleConfirmRemove = (id) => {
        //console.log("Removing form: ", id);
        removeForm(protocolId, visitId, id)
            .then(res => {
                history.push(`/admin/protocols/${protocolId}/visitsTemplate/${visitId}`);
            }).catch(error => {
                console.error(error);
                HandleApiError(error);
                setModal(null);
            });

    }

    const handleTypeChange = (type) => {
        setFormType(type)
    }

    const handleClose = () => {
        setFieldConfig(null);
    }

    const handleSaveAsTemplate = async () => {
        let formCopy = JSON.parse(JSON.stringify(form));
        if (fields.length == 0)
            return;

        formCopy.fields = fields;
        formCopy._Fields = "";
        try {
            const formTemplate = await createFormTemplate(protocolId, formCopy);
            Success("protocolNotifications.formTemplate_Created");
            setIsLoading(false);
            setFormTemplates([...formTemplates, formTemplate.data]);
        } catch (error) {
            console.log(error)
            HandleApiError(error);
            setIsLoading(false);
        }
    }

    const handleRemoveTemplate = async (templateId) => {
        try {
            await removeFormTemplate(protocolId, templateId);
            Success("protocolNotifications.formTemplate_Removed");
            setFormTemplates(formTemplates.filter((item) => item.id !== templateId));
            setIsLoading(false);
        } catch (error) {
            HandleApiError(error);
            setIsLoading(false);
        }
    }

    const handleChangeLaboTemplate = (fieldIndex, labos) => {
        let fieldsCopy = JSON.parse(JSON.stringify(fields));

        let fieldArrayLabo = fieldsCopy[fieldIndex];
        if (!fieldArrayLabo) return;

        fieldArrayLabo.components = labos;
        fieldsCopy.splice(fieldIndex, 1, fieldArrayLabo); //Reemplazo field. 
        setFields(fieldsCopy);
    }

    const isDisabled = !isAuthorized(["Visits.Add"]);

    return (
        <ProtocolProvider protocolId={protocolId}>
            <Grid fluid>
                <Row>
                    <Col md={12}>
                        {modal}
                        {
                            form &&
                            <DynamicFormHeader
                                onSubmit={handleSaveClick}
                                onRemove={handleRemoveForm}
                                onSaveAsTemplate={handleSaveAsTemplate}
                                onTypeChange={handleTypeChange}
                                protocolId={protocolId}
                                visitId={visitId}
                                form={form}
                                formType={formType}
                                isDisabled={isDisabled || isLoading}
                            />
                        }
                    </Col>
                </Row>
                <Row className="display-flex">
                    <Col md={2}>
                        <Row>
                            <Col md={12}>
                                <DynamicFormControls
                                    formFieldTypes={formTypeFields.find(x => x.type === formType)?.fields}
                                    onAddControlClick={handleAddControlClick}
                                    onAddFormFieldControlClick={handleAddFormFieldControlClick}
                                    isDisabled={isDisabled}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <DynamicFormComponents
                                    isLoading={isLoading}
                                    onAddComponentClick={handleAddComponentsClick}
                                    onRemoveTemplate={handleRemoveTemplate}
                                    templates={formTemplates}
                                    isDisabled={isDisabled}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        {
                            fields && <DynamicFormCreatorViewer
                                fields={fields}
                                onConfigClick={handleConfigClick}
                                onRemoveClick={handleRemoveClick}
                                onRemoveFromTemplateClick={handleRemoveFromTemplateClick}
                                onConfigFromTemplateClick={handleConfigFromTemplateClick}
                                onSortEnd={handleSortEnd}
                                onChangeLaboTemplate={handleChangeLaboTemplate}
                                isDisabled={isDisabled}
                            />
                        }
                    </Col>
                    <Col md={4}>
                        {
                            fieldConfig && <DynamicOptionsFormik
                                fieldOptions={fieldConfig}
                                formType={formType}
                                onSubmit={handleOptionsSubmit}
                                onClose={handleClose}
                            />
                        }
                    </Col>
                </Row>
            </Grid>
        </ProtocolProvider>
    );
}