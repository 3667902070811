import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Add, RemoveCircle, IconSVG } from '../Icons/Icons';

export const ExpandableContent = (props) => {
    const { title, expandedAll, children, content } = props;
    const [expanded, setExpanded] = useState(true);

    useEffect(() => {
        if (expandedAll !== null && expandedAll !== undefined )
            setExpanded(expandedAll)
    }, [expandedAll])

    return (
        <article className='expandable-content'>
            <header onClick={() => setExpanded(!expanded)}>
                <h4 className='expandable-title'>
                    <IconSVG name={content} style={{ width: '19px', marginRight: '10px' }} />{title}
                </h4>
                <button className="btn-table-actions" type="button" onClick={() => setExpanded(!expanded)}>
                    {expanded ? <RemoveCircle /> : <Add />}
                </button>
            </header>
            <div style={{ display: expanded ? 'inline' : 'none' }} className="expandable-data">
                {children}
            </div>
        </article>
    )
}

ExpandableContent.defaultProps = {
    expandedAll: false,
}

ExpandableContent.propTypes = {
    expandedAll: PropTypes.bool,    
}
