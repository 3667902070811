import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Card } from "../../../components/Cards/Card";
import { AdverseEventsListContainer } from "./AdverseEventsListContainer";
import { AnthropometricsListContainer } from "./AnthropometricsListContainer";
import { AttachmentsListContainer } from "./AttachmentsListContainer";
import { DrugsListContainer } from "./DrugsListContainer";
import EntriesContainer from "./EntriesContainer";
import { FamilyHistoryListContainer } from "./FamilyHistoryListContainer";
import { LaboratoryListContainer } from "./LaboratoryListContainer";
import { PersonalHabitsListContainer } from "./PersonalHabitsListContainer";
import { PersonalHistoryListContainer } from "./PersonalHistoryListContainer";
import { ProceduresListContainer } from "./ProceduresListContainer";
import { PrescriptionsListContainer } from "./PrescriptionsListContainer";
import { VitalsListContainer } from "./VitalsListContainer";
import { OrdersListContainer } from "./OrdersListContainer";

export const EntryItemsList = (props) => {
    const [expandedAll, setExpandedAll] = useState(true);

    return (
        <Card
            header={
                <>
                    <button className="btn btn-right btn-primary" onClick={() => setExpandedAll(!expandedAll)}>
                        {
                            expandedAll ? <>Ocultar todos</> : <>Ver Todos</>
                        }
                    </button>
                    <div className="clearfix"></div>
                </>
            }

            content={
                <>
                    <AdverseEventsListContainer {...props} expandedAll={expandedAll} readOnly={true} />
                    <PersonalHistoryListContainer {...props} expandedAll={expandedAll} readOnly={true} />
                    <DrugsListContainer {...props} expandedAll={expandedAll} readOnly={true} />
                    <ProceduresListContainer {...props} expandedAll={expandedAll} readOnly={true} />
                    <PrescriptionsListContainer {...props} expandedAll={expandedAll} readOnly={true} />
                    <OrdersListContainer {...props} expandedAll={expandedAll} readOnly={true} />
                    <LaboratoryListContainer {...props} expandedAll={expandedAll} readOnly={true} />
                    <VitalsListContainer {...props} expandedAll={expandedAll} readOnly={true} />
                    <AnthropometricsListContainer {...props} expandedAll={expandedAll} readOnly={true} />
                    <PersonalHabitsListContainer {...props} expandedAll={expandedAll} readOnly={true} />
                    <FamilyHistoryListContainer {...props} expandedAll={expandedAll} readOnly={true} />
                    <AttachmentsListContainer {...props} expandedAll={expandedAll} readOnly={true} />
                </>
            }
        />
    );
}

export const EntryItemsListTabs = ({ medicalRecordNumber }) => {
    return (
        <Tabs defaultActiveKey="entries" id="medical-record-home" mountOnEnter={true}>
            <Tab eventKey={"entries"} title="Evoluciones">
                <Card
                    tabs
                    noHeader
                    content={<EntriesContainer medicalRecordNumber={medicalRecordNumber} view="scroll-small" />}
                />
            </Tab>
            <Tab eventKey={"personalHistory"} title="Antecedentes">
                <Card
                    tabs
                    noHeader
                    content={<PersonalHistoryListContainer medicalRecordNumber={medicalRecordNumber} expandedAll={true} />}
                />
            </Tab>
            <Tab eventKey={"events"} title="Eventos">
                <Card
                    tabs
                    noHeader
                    content={<AdverseEventsListContainer medicalRecordNumber={medicalRecordNumber} expandedAll={true} />}
                />
            </Tab>
            <Tab eventKey={"drugs"} title="Medicamentos">
                <Card
                    tabs
                    noHeader
                    content={<DrugsListContainer medicalRecordNumber={medicalRecordNumber} expandedAll={true} />}
                />
            </Tab>
            <Tab eventKey={"procedures"} title="Procedimientos">
                <Card
                    tabs
                    noHeader
                    content={<ProceduresListContainer medicalRecordNumber={medicalRecordNumber} expandedAll={true} />}
                />
            </Tab>
            <Tab eventKey={"prescriptions"} title="Recetas">
                <Card
                    tab
                    content={<PrescriptionsListContainer medicalRecordNumber={medicalRecordNumber} expandedAll={true} />}
                />
            </Tab>
            <Tab eventKey={"orders"} title="Ordenes">
                <Card
                    tab
                    content={<OrdersListContainer medicalRecordNumber={medicalRecordNumber} expandedAll={true} />}
                />
            </Tab>
            <Tab eventKey={"laboratories"} title="Laboratorios">
                <Card
                    tabs
                    noHeader
                    content={<LaboratoryListContainer medicalRecordNumber={medicalRecordNumber} expandedAll={true} />}
                />
            </Tab>
            <Tab eventKey={"vitals"} title="Signos Vitales">
                <Card
                    tabs
                    noHeader
                    content={<VitalsListContainer medicalRecordNumber={medicalRecordNumber} expandedAll={true} />}
                />
            </Tab>
            <Tab eventKey={"anthropometrics"} title="Antropometría">
                <Card
                    tabs
                    noHeader
                    content={<AnthropometricsListContainer medicalRecordNumber={medicalRecordNumber} expandedAll={true} />}
                />
            </Tab>
        </Tabs>
    );
}