import axios from './index';

export const getConfiguration = () => {
    return axios.get(`api/settings`);
};

export const uploadHeader = async (formData, type) => {
    return axios.post(`api/settings/prescriptionorders/${type}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const getSettings = () => {
    return axios.get(`api/settings/clientsettings`);
};

export const setSettings = (key, value) => {
    return axios.put(`api/settings/clientsettings/key/${key}/value/${value}`, value);
};