import { ErrorMessage, FieldArray } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, ControlLabel, Grid, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { useTranslation } from 'react-i18next';
import { getPractitionerAttachmentsEndpoint } from '../../api/protocol';
import { AuditTrail } from '../../containers/Admin/AuditTrail';
import { AuditModal } from '../AuditTrail/AuditModal';
import { WithAuth } from '../Authorization/WithAuthProtocol';
import { CustomDate } from "../CustomControls/CustomDateTime";
import { CustomSelect } from "../CustomControls/CustomSelect";
import { Files, FilesForm } from '../CustomControls/Files/Files';
import { PractitionerPicker } from '../CustomControls/PractitionerPicker';
import { Attach, Audit, Edit, Remove, Save } from '../Icons/Icons';
import { dateFormatter } from '../Utils/Formatter';
import { Tooltip } from "../Utils/Tooltips";
import { FormErrorMessage } from '../Validations/FormValidationErrors';

export const Practitioners = props => {

    const { protocolId, practitioners, query, totalSize, onTableChange, onEdit } = props;
    const { t } = useTranslation();

    const columns = [
        { dataField: 'userId', text: 'ID', hidden: true },
        { dataField: 'fullName', text: t("protocols.practitioner.fullName"), sort: true },
        { dataField: 'studyRole', text: t("protocols.practitioner.role") },
        {
            dataField: 'studyTasks', text: t("protocols.practitioner.studyTasks.title"),
            formatter: function (cell, _row, rowIndex) {
                const content = cell.map((item, index) => {
                    return <div key={index}>{item.title}</div>;
                })
                return <div key={rowIndex}>{content}</div>
            }
        },
        {
            dataField: 'startOfTask', text: t("protocols.practitioner.startOfTask"), sort: true,
            formatter: function (cell) {
                return dateFormatter(cell, "DD/MMM/YYYY")
            }
        },
        {
            dataField: 'endOfTask', text: t("protocols.practitioner.endOfTask"),
            formatter: function (cell) {
                return dateFormatter(cell, "DD/MMM/YYYY")
            }
        },
        {
            dataField: 'view',
            text: t("protocols.practitioner.actions"),
            isDummyField: true,
            headerClasses: 'header-protocol-actions',
            classes: 'row-protocol-actions',
            formatter: function (_cell, row, rowIndex) {
                return (
                    <div key={rowIndex}>
                        {
                            onEdit &&
                            <WithAuth protocolId={protocolId} requiredPermission={["Practitioners.Edit"]}>
                                <button className="btn-table-actions" onClick={() => onEdit(row.userId)}>
                                    <Tooltip tooltip={t("protocols.practitioner.edit")} id={"tooltipedit-" + row.id}>
                                        <span aria-hidden="true"><Edit /></span>
                                    </Tooltip>
                                </button>
                            </WithAuth>
                        }
                        {
                            row.hasAudit &&
                            <Tooltip tooltip={t("protocols.practitioner.audit")} id={"tooltipaudit-" + row.id}>
                                <button className="btn-table-actions" onClick={() => handleAuditTrail(row.userId)}>
                                    <Audit />
                                </button>
                            </Tooltip>
                        }
                    </div>
                )
            }
        }
    ];

    const expandRow = {
        renderer: row => (<Files attachments={row.attachments} endpoint={getPractitionerAttachmentsEndpoint(protocolId, row.userId)} />),
        showExpandColumn: true,
        onlyOneExpanding: true,
        expandByColumnOnly: true,
        expandHeaderColumnRenderer: () => {
            return <Attach style={{ fontSize: '20px' }} />;
        },
        expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
            if (!expandable)
                return;
            if (expanded) {
                return (
                    <b style={{ cursor: 'pointer' }}>-</b>
                );
            }
            return (<Attach style={{ fontSize: '16px', cursor: 'pointer' }} />);
        },
        nonExpandable: practitioners.filter((item) => item.attachments.length == 0).map(item => item.userId)
    };

    const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        onTableChange(type, { page, sizePerPage, sortField, sortOrder });
    }

    const [modal, setModal] = useState(null);
    const handleAuditTrail = (userId) => {
        setModal(
            <AuditModal
                onClose={() => { setModal(null); }}
                title="Auditoría"
            >
                <AuditTrail entity="ProtocolPractitioner" entityId={userId} />
            </AuditModal>
        )
    }

    return (
        <>
            {modal}
            <BootstrapTable
                classes="table-spaced table-spaced-small"
                wrapperClasses="table-responsive"
                bordered={false}
                hover
                remote
                keyField='userId'
                data={practitioners}
                columns={columns}
                noDataIndication={() => <>Sin datos</>}
                overlay={overlayFactory({ spinner: true })}
                expandRow={expandRow}
                pagination={paginationFactory({
                    page: query.pagination.page,
                    sizePerPage: query.pagination.sizePerPage,
                    totalSize: totalSize,
                    hideSizePerPage: true,
                })}
                onTableChange={handleTableChange}
            />
        </>
    );
}

Practitioners.propTypes = {
    protocolId: PropTypes.string.isRequired,
    practitioners: PropTypes.array.isRequired,
    query: PropTypes.object.isRequired,
    totalSize: PropTypes.number.isRequired,
    onTableChange: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
};

export const PractitionersForm = props => {

    const {
        values,
        setFieldValue,
        setFieldTouched,
        handleSubmit,
        studyTasks,
        roles,
        t
    } = props;

    const emptyPractitioner = { practitioner: '', studyTasks: null, startOfTask: null, endOfTask: null };
    if (!values.practitioners) {
        values.practitioners = [];
        values.practitioners.push(emptyPractitioner);
    }

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <FieldArray
                name="practitioners"
                render={arrayHelper => (
                    <Grid fluid>
                        {values.practitioners && values.practitioners.length > 0 ? (
                            values.practitioners.map((practitioner, index) => (
                                <Row key={"practitioners" + index}>
                                    <Col md={6}>
                                        <ControlLabel>{t("protocols.practitioner.practitioner")}</ControlLabel>
                                        <PractitionerPicker
                                            id={`practitioners.${index}.practitioner`}
                                            name={`practitioners.${index}.practitioner`}
                                            value={practitioner.practitioner}
                                            multiple={false}
                                            onlyDoctor={false}
                                            placeholder={t("commons.select")}
                                            onChange={setFieldValue}
                                            onBlur={setFieldTouched}
                                        />
                                        <ErrorMessage name={`practitioners.${index}.practitioner`} component={FormErrorMessage} />
                                    </Col>
                                    <Col md={4}>
                                        <ControlLabel>{t("protocols.practitioner.role")}</ControlLabel>
                                        <CustomSelect
                                            value={practitioner.studyRole}
                                            onChange={setFieldValue}
                                            onBlur={setFieldTouched}
                                            name={`practitioners.${index}.studyRole`}
                                            placeholder={t("commons.select")}
                                            options={roles ? roles : []}
                                            getOptionLabel={(option) => option.name}
                                        />
                                        <ErrorMessage name={`practitioners.${index}.studyRole`} component={FormErrorMessage} />
                                    </Col>
                                    <Col md={2}>
                                        <button
                                            type="button"
                                            className="btn-action btn-remove btn right"
                                            onClick={() => arrayHelper.remove(index)} >
                                            <Remove />
                                        </button>
                                    </Col>
                                    <Col md={6}>
                                        <ControlLabel>{t("protocols.practitioner.studyTasks.title")}</ControlLabel>
                                        <CustomSelect
                                            value={practitioner.studyTasks}
                                            onChange={setFieldValue}
                                            onBlur={setFieldTouched}
                                            name={`practitioners.${index}.studyTasks`}
                                            placeholder={t("commons.select")}
                                            options={studyTasks.length > 0 ? studyTasks : []}
                                            isMulti={true}
                                            closeMenuOnSelect={false}
                                        />
                                        <ErrorMessage name={`practitioners.${index}.studyTasks`} component={FormErrorMessage} />
                                    </Col>
                                    <Col md={3}>
                                        <ControlLabel>{t("protocols.practitioner.startOfTask")}</ControlLabel>
                                        <CustomDate
                                            value={practitioner.startOfTask}
                                            name={`practitioners.${index}.startOfTask`}
                                            onChange={setFieldValue}
                                            onBlur={setFieldTouched}
                                            closeOnSelect={true}
                                        />
                                        <ErrorMessage name={`practitioners.${index}.startOfTask`} component={FormErrorMessage} />
                                    </Col>
                                    <Col md={3}>
                                        <ControlLabel>{t("protocols.practitioner.endOfTask")}</ControlLabel>
                                        <CustomDate
                                            value={practitioner.endOfTask}
                                            name={`practitioners.${index}.endOfTask`}
                                            onChange={setFieldValue}
                                            onBlur={setFieldTouched}
                                            closeOnSelect={true}
                                        />
                                        <ErrorMessage name={`practitioners.${index}.endOfTask`} component={FormErrorMessage} />
                                    </Col>
                                    <Col md={12}>
                                        <FilesForm
                                            placeholder={t("protocols.practitioner.attachments")}
                                            values={practitioner.attachments}
                                            name={`practitioners.${index}.attachments`}
                                            onChange={setFieldValue}
                                        />
                                    </Col>
                                </Row>
                            ))
                        ) : ""}

                        <button type="button" className="btn btn-default" onClick={() => arrayHelper.push(emptyPractitioner)}>{t("buttons.add")}</button>
                    </Grid>
                )}
            />
            <Grid fluid>
                <Row>
                    <Col sm={12}>
                        <button type="submit" className="btn btn-success btn-right"><Save /> {t("buttons.save")}</button>
                        <button type="button" className="btn btn-default btn-right" onClick={props.onCancel} style={{ marginRight: '10px' }}>{t("buttons.cancel")}</button>
                    </Col>
                </Row>
            </Grid>
        </form>
    );
}

export const PractitionerForm = props => {

    const {
        values,
        protocolId,
        practitionerUserId,
        setFieldValue,
        setFieldTouched,
        handleSubmit,
        studyTasks,
        roles,
        t
    } = props;

    if (!values.practitioner) {
        return '';
    }

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <Row>
                <Col md={6}>
                    <ControlLabel>{t("protocols.practitioner.practitioner")}</ControlLabel>
                    <div>{values.practitioner.fullName}</div>
                    <small>{values.practitioner.speciality}</small>
                </Col>
                <Col md={6}>
                    <ControlLabel>{t("protocols.practitioner.role")}</ControlLabel>
                    <CustomSelect
                        defaultValue={values.practitioner.studyRole && roles && roles.find(x => x.id === values.practitioner.studyRoleId)}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        name={`practitioner.studyRole`}
                        placeholder={t("commons.select")}
                        options={roles ? roles : []}
                        getOptionLabel={(option) => option.name}
                    />
                    <ErrorMessage name={`practitioner.studyRole`} component={FormErrorMessage} />
                </Col>
                <Col md={6}>
                    <ControlLabel>{t("protocols.practitioner.studyTasks.title")}</ControlLabel>
                    <CustomSelect
                        value={values.practitioner.studyTasks}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        name={`practitioner.studyTasks`}
                        placeholder={t("commons.select")}
                        options={studyTasks.length > 0 ? studyTasks : []}
                        isMulti={true}
                    />
                    <ErrorMessage name={`practitioner.studyTasks`} component={FormErrorMessage} />
                </Col>
                <Col md={3}>
                    <ControlLabel>{t("protocols.practitioner.startOfTask")}</ControlLabel>
                    <CustomDate
                        value={values.practitioner.startOfTask}
                        name={`practitioner.startOfTask`}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        closeOnSelect={true}
                    />
                    <ErrorMessage name={`practitioner.startOfTask`} component={FormErrorMessage} />
                </Col>
                <Col md={3}>
                    <ControlLabel>{t("protocols.practitioner.endOfTask")}</ControlLabel>
                    <CustomDate
                        value={values.practitioner.endOfTask}
                        name={`practitioner.endOfTask`}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        closeOnSelect={true}
                    />
                    <ErrorMessage name={`practitioner.endOfTask`} component={FormErrorMessage} />
                </Col>
                <Col md={12}>
                    <FilesForm
                        placeholder={t("protocols.practitioner.attachments")}
                        values={values.practitioner.newattachments}
                        name={`practitioner.newattachments`}
                        onChange={setFieldValue}
                    />
                </Col>
                <Col md={12}>
                    <Files
                        attachments={values.practitioner.attachments}
                        endpoint={getPractitionerAttachmentsEndpoint(protocolId, practitionerUserId)}
                    />
                </Col>
            </Row>
            <Grid fluid>
                <Row>
                    <Col sm={12}>
                        <button type="submit" className="btn btn-success btn-right" disabled={props.isSubmitting} >{t("buttons.save")}</button>
                        <button type="button" className="btn btn-default btn-right" onClick={props.onCancel} style={{ marginRight: '10px' }}>{t("buttons.cancel")}</button>
                    </Col>
                </Row>
            </Grid>
        </form>
    );
}
