import { ErrorMessage } from 'formik';
import React, { useState } from 'react';
import { Col, ControlLabel, FormControl, Grid, Row } from 'react-bootstrap';
import { getMedicalInsurancePlanByMedicalInsurance } from '../../api/admin';
import { bloodFactor, bloodGroup } from "../../variables/Enums";
import Card from '../Cards/Card';
import { CustomSelect } from "../CustomControls/CustomSelect";
import { PractitionerSelect } from '../CustomControls/PractitionerSelect';
import { FormErrorMessage } from '../Validations/FormValidationErrors';

export const MedicalDataForm = props => {

    const {
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldTouched,
        t,
        masterData
    } = props;

    const [medicalPlans, setMedicalPlans] = useState([]);

    const onChangeMedicalInsurance = (field, value, shouldValidate) => {

        if (value) {
            getMedicalInsurancePlanByMedicalInsurance(value.id).then(res => {
                setMedicalPlans(res.data);
                
            }).catch(error => {
                console.log('Error getting Medical Insurance Plans', error);
            });
        }
        setFieldValue(field, value, shouldValidate);
        setFieldValue("medicalInsuranceInfo.medicalInsurancePlan", null);
    }

    if (!values.medicalInsuranceInfo) values.medicalInsuranceInfo = { medicalInsurance: '', medicalInsuranceNumber: '' };

    return (
        <fieldset>
            <legend>Datos Médicos</legend>
            <Card
                className="card-form"
                content={
                    <Grid fluid>
                        <Row>
                            <Col sm={2}>
                                <ControlLabel>{t("person.medicalInsuranceInfo.medicalInsurance")}</ControlLabel>
                                <CustomSelect
                                    defaultValue={values.medicalInsuranceInfo?.medicalInsurance}
                                    onChange={onChangeMedicalInsurance}
                                    onBlur={setFieldTouched}
                                    name="medicalInsuranceInfo.medicalInsurance"
                                    placeholder={t("commons.select")}
                                    options={masterData.medicalInsurances}
                                    isClearable={true}
                                />
                                <ErrorMessage name="medicalInsuranceInfo.medicalInsurance" component={FormErrorMessage} />
                            </Col>
                            <Col sm={2}>
                                <ControlLabel>{t("person.medicalInsuranceInfo.medicalInsurancePlan")}</ControlLabel>
                                <CustomSelect
                                    value={values.medicalInsuranceInfo?.medicalInsurancePlan}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    isClearable={true}
                                    name="medicalInsuranceInfo.medicalInsurancePlan"
                                    placeholder={t("commons.select")}
                                    options={medicalPlans} />
                                <ErrorMessage name="medicalInsuranceInfo.medicalInsurancePlan" component={FormErrorMessage} />
                            </Col>
                            <Col sm={2}>
                                <ControlLabel>{t("person.medicalInsuranceInfo.medicalInsuranceNumber")}</ControlLabel>
                                <FormControl type="text" name="medicalInsuranceInfo.medicalInsuranceNumber" value={values.medicalInsuranceInfo?.medicalInsuranceNumber} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                <ErrorMessage name="medicalInsuranceInfo.medicalInsuranceNumber" component={FormErrorMessage} />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={2}>
                                <ControlLabel>{t("person.bloodGroup")}</ControlLabel>
                                <CustomSelect
                                    defaultValue={values.bloodGroup && bloodGroup.filter(x => x.title === values.bloodGroup)}
                                    //value={values.bloodGroup}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    name="bloodGroup"
                                    placeholder={t("commons.select")}
                                    options={bloodGroup} />
                            </Col>
                            <Col sm={2}>
                                <ControlLabel>{t("person.bloodFactor")}</ControlLabel>
                                <CustomSelect
                                    defaultValue={values.bloodFactor && bloodFactor.filter(x => x.title === values.bloodFactor)}
                                    //value={values.bloodFactor}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    name="bloodFactor"
                                    placeholder={t("commons.select")}
                                    options={bloodFactor} />
                            </Col>
                            <Col sm={3}>
                                <ControlLabel>{t("person.familyDoctor")}</ControlLabel>
                                <FormControl type="text" name="familyDoctor" value={values.familyDoctor ?? ""} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                            </Col>
                            <Col sm={3}>
                                <ControlLabel>{t("person.internalPractitioner")}</ControlLabel>
                                <PractitionerSelect
                                    value={values.internalPractitioner}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    name={`internalPractitioner`}
                                    placeholder={t("commons.select")}
                                />
                            </Col>
                        </Row>
                    </Grid>
                }
            />
        </fieldset>
    );
}