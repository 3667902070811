import { ErrorMessage, FieldArray } from 'formik';
import React, { useState } from 'react';
import { Col, ControlLabel, FormControl, FormGroup, Grid, Row, Panel, PanelGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { snomedExpressions } from '../../../variables/Enums';
import { FromToNullableDatePicker, NullableDatePickerCombo } from "../../CustomControls/DatePicker";
import { SnomedPicker } from '../../CustomControls/SnomedPicker';
import { Remove } from '../../Icons/Icons';
import { FormErrorMessage } from '../../Validations/FormValidationErrors';

export const AdverseEventForm = props => {

    const {
        values,
        setFieldValue,
        setFieldTouched,
        handleSubmit,
        handleChange,
        isSubmitting,
        t
    } = props;

    const emptyResult = {
        name: null,
        intensity: null,
        serious: null,
        reported: null,
        relationwithdrug: null,
        action: null,
        fromYear: null
    };

    if (!values.adverseEvents) {
        values.adverseEvents = [emptyResult];
    }

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <PanelGroup accordion id="multi-items" defaultActiveKey="multi-item-0">
                <FieldArray
                    name="adverseEvents"
                    render={arrayHelper => (
                        <div>
                            {values.adverseEvents && values.adverseEvents.length > 0 && (
                                values.adverseEvents.map((adverseEvent, index) => (
                                    <Panel key={`multi-item-${index}`} eventKey={`multi-item-${index}`}>
                                        <Panel.Heading>
                                            <Panel.Title toggle>{adverseEvent.name ? adverseEvent.name.description : 'Evento'}</Panel.Title>
                                        </Panel.Heading>
                                        <Panel.Body collapsible>
                                            <FormGroup key={index}>
                                                <Grid fluid>
                                                    <Row>
                                                        <Col sm={11}>
                                                            <ControlLabel>{t("medicalRecords.adverseEvents.adverseEvent")}</ControlLabel>
                                                            <SnomedPicker
                                                                autoFocus
                                                                defaultInputValue={adverseEvent.name ? adverseEvent.name.description : ''}
                                                                id={`adverseEvents.${index}.name`}
                                                                value={adverseEvent.name}
                                                                name={`adverseEvents.${index}.name`}
                                                                placeholder={t("commons.search")}
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                conceptId={snomedExpressions.Hallazgos} />
                                                            <ErrorMessage name={`adverseEvents.${index}.name`} component={FormErrorMessage} />
                                                        </Col>
                                                        <Col md={1}>
                                                            <button
                                                                className="btn-table-actions btn-remove"
                                                                style={{ verticalAlign: 'middle' }}
                                                                type="button"
                                                                onClick={() => arrayHelper.remove(index)}>
                                                                <Remove />
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={9}>
                                                            <FromToNullableDatePicker
                                                                valuesFrom={{ fromDay: adverseEvent.fromDay, fromMonth: adverseEvent.fromMonth, fromYear: adverseEvent.fromYear }}
                                                                nameFromDay={`adverseEvents.${index}.fromDay`}
                                                                nameFromMonth={`adverseEvents.${index}.fromMonth`}
                                                                nameFromYear={`adverseEvents.${index}.fromYear`}
                                                                valuesTo={{ toDay: adverseEvent.toDay, toMonth: adverseEvent.toMonth, toYear: adverseEvent.toYear }}
                                                                nameToDay={`adverseEvents.${index}.toDay`}
                                                                nameToMonth={`adverseEvents.${index}.toMonth`}
                                                                nameToYear={`adverseEvents.${index}.toYear`}
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                            />
                                                            <ErrorMessage name={`adverseEvents.${index}.fromYear`} component={FormErrorMessage} />
                                                            <ErrorMessage name={`adverseEvents.${index}.toYear`} component={FormErrorMessage} />
                                                            <ErrorMessage name={`adverseEvents.${index}.toMonth`} component={FormErrorMessage} />
                                                            <ErrorMessage name={`adverseEvents.${index}.toDay`} component={FormErrorMessage} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12}>
                                                            <ControlLabel>{t("medicalRecords.adverseEvents.notes")}</ControlLabel>
                                                            <FormControl componentClass="textarea" value={adverseEvent.notes} name={`adverseEvents.${index}.notes`} onChange={handleChange} />
                                                        </Col>
                                                    </Row>
                                                </Grid>
                                                <hr />
                                            </FormGroup>
                                        </Panel.Body>
                                    </Panel>
                                ))
                            )}
                            {
                                (values.adverseEvents.length === 0 || (values.adverseEvents.length > 0 && !values.adverseEvents[0].id)) &&
                                <Row style={{ marginTop: "20px" }}>
                                    <Col md={4}><button type="button" className="btn btn-default" onClick={() => arrayHelper.push(emptyResult)}>{t("buttons.add")}</button></Col>
                                    <Col md={8}></Col>
                                </Row>
                            }
                        </div>
                    )}
                />
            </PanelGroup>
            <button type="button" className="btn btn-default" onClick={props.onCancel} disabled={isSubmitting}>{t("buttons.cancel")}</button>
            {
                values.adverseEvents && values.adverseEvents.length > 0 &&
                <button type="submit" className="btn btn-success btn-right" disabled={isSubmitting}>{t("buttons.save")}</button>
            }

            <div className="clearfix"></div>
        </form>
    );
}

export const AdverseEventCloseForm = props => {
    const { adverseEvent, onCancel, onCloseItem } = props;
    const { t } = useTranslation();
    const [closeDate, setCloseDate] = useState({});

    const handleChange = (name, value) => {
        setCloseDate({
            ...closeDate,
            [name]: value
        });
    }

    const handleCloseItem = () => {
        if (closeDate && onCloseItem)
            onCloseItem(adverseEvent, closeDate);
    }

    return (
        <>
            <FormGroup>
                <Grid fluid>
                    <Row>
                        <Col sm={6}>
                            <ControlLabel>{t("medicalRecords.from")}</ControlLabel>
                            <NullableDatePickerCombo
                                disabled={true}
                                valueDay={adverseEvent.fromDay}
                                valueMonth={adverseEvent.fromMonth}
                                valueYear={adverseEvent.fromYear}
                            />
                        </Col>
                        <Col sm={6}>
                            <ControlLabel>{t("medicalRecords.to")}</ControlLabel>
                            <NullableDatePickerCombo
                                nameDay={`toDay`}
                                nameMonth={`toMonth`}
                                nameYear={`toYear`}
                                onChange={handleChange}
                                valueDay={adverseEvent.toDay}
                                valueMonth={adverseEvent.toMonth}
                                valueYear={adverseEvent.toYear}
                            />
                        </Col>
                    </Row>
                </Grid>
                <hr />
            </FormGroup>
            <button type="button" className="btn btn-default" onClick={onCancel}>{t("buttons.cancel")}</button>
            <button type="button" className="btn btn-success btn-right" onClick={handleCloseItem}>{t("buttons.save")}</button>
        </>
    );
}