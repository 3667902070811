import { useCallback, useState } from 'react';
import { getBlob, postFile } from "../../../api/commons";
import { HandleApiError, HandleApiErrorWithInfo } from '../../Notifications/APIErrorHandler';
import { SuccessWithInfo } from '../../Notifications/Notifications';
import { uploadStatusEnum } from './Utils';

export const useFiles = (apiEndpoint) => {
    const [res, setRes] = useState({ error: null, isLoading: false, status: null, blob: null, fileName: null });

    const _handleResponse = (res) => {
        if (res) {
            if (res.error)
                HandleApiErrorWithInfo(res.error, "commons.fileName", res.fileName);
            else
                SuccessWithInfo("commons.fileUploaded", res.data?.fileName)
        }
    }

    const getAPI = useCallback(async (attachmentId, endpoint = apiEndpoint) => {
        setRes(prevState => ({ ...prevState, isLoading: true }));

        try {
            const encodedFileName = encodeURIComponent(attachmentId);
            const res = await getBlob(`${endpoint}/${encodedFileName}`);
            const blob = new Blob([res.data], { type: res.data.type });

            const contentDisposition = res.headers['content-disposition'];
            let fileName = 'unknown';
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                if (fileNameMatch.length > 1)
                    fileName = fileNameMatch[1];
            };

            setRes({ isLoading: false, error: null, blob, fileName });
        }
        catch (error) {
            console.log(error);
            HandleApiError(error);
            setRes({ isLoading: false, error, blob: null, fileName: null });
        }
    }, [])

    const postAPI = useCallback(async (attachments, endpoint = apiEndpoint) => {
        setRes(prevState => ({ ...prevState, status: uploadStatusEnum.Uploading, isLoading: true }));

        if (attachments) {
            // Sync Upload: Para que no haya problema de guardado en los formularios de protocolo. 
            // Ver si es necesario pasar parametro para elegir Sync o Async. 
            var queue = Promise.resolve();
            attachments.forEach(function (attachment) {
                queue = queue.then(async function (res) {
                    _handleResponse(res);

                    // Initial FormData
                    const formData = new FormData();
                    formData.append("attachment", attachment.file);
                    formData.append("title", attachment.fileName);

                    // Make an AJAX upload request using Axios
                    return postFile(endpoint, formData);

                }).catch((error) => {
                    console.log("Error calling endpoint ", error.response);
                    setRes(prevState => ({ ...prevState, error }));
                    return Promise.resolve({ error, fileName: attachment.fileName });
                });
            });

            queue.then(function (lastResponse) {
                _handleResponse(lastResponse);
                setRes(prevState => ({ ...prevState, status: uploadStatusEnum.Uploaded, isLoading: false }));
            });

            return queue

            // Async Upload: Trae problemas en los forms de Protocolo. 
            /*const attachmentsUploads = attachments.map(async attachment => {
                // Initial FormData
                const formData = new FormData();
                formData.append("attachment", attachment.file);
                formData.append("title", attachment.fileName);

                // Make an AJAX upload request using Axios
                try {
                    //return Promise.resolve({ data: { fileName: attachment.fileName } });
                    return await postFile(endpoint, formData);
                }
                catch (error) {
                    console.log("Error calling endpoint ", error.response);
                    setRes(prevState => ({ ...prevState, error }));
                    return Promise.reject({ error, fileName: attachment.fileName });
                }
            });

            // Once all the files are uploaded 
            Promise.allSettled(attachmentsUploads).then((results) => {
                results.forEach((result) => {
                    if (result.status === 'rejected')
                        HandleApiErrorWithInfo(result.reason.error, "commons.fileName", result.reason.fileName);
                    else
                        SuccessWithInfo("commons.fileUploaded", result.value?.data?.fileName)
                })
                setRes(prevState => ({ ...prevState, status: uploadStatusEnum.Uploaded, isLoading: false })
                );
            });*/
        }
    }, [])

    return [res, getAPI, postAPI];
}
