import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getVisitTrackingExtraForms, publishVisitTrackingExtraForm, saveVisitTrackingExtraForm, screenFailureVisitTracking } from '../../../api/protocol';
import { AuditModal, CreateAuditModal } from '../../../components/AuditTrail/AuditModal';
import { useAuthProtocol } from '../../../components/Authorization/useAuthorization';
import { SignWithOutInfo } from '../../../components/DigitalSign/Sign';
import { DynamicForm } from '../../../components/DynamicForm/DynamicForm';
import { MedicalRecord } from '../../../components/Icons/Icons';
import { ModalSpinner } from '../../../components/Modals/ModalSpinner';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';
import { Success } from '../../../components/Notifications/Notifications';
import { FormActions } from '../../../components/Protocols/ExtraVisits/Forms';
import { ProtocolInfo } from '../../../components/Protocols/ProtocolInfo';
import { Entry } from '../../../components/Protocols/Tracking/Entry';
import { FormMenu, VisitStatus } from '../../../components/Protocols/Tracking/Forms';
import { ScreenFailure } from '../../../components/Protocols/Tracking/Modals';
import { Slider } from '../../../components/Slider/Slider';
import { Spinner } from '../../../components/Utils/Loaders';
import { getValidationSchema } from '../../../components/Validations/FormValidationSchema';
import { formStatus, visitStatus as visitStatusEnum } from "../../../variables/Enums";
import { AuditTrail } from "../../Admin/AuditTrail";
import { ModalDigitalSign } from '../../DigitalSign/ModalDigitalSign';
import { EntryItemsListTabs } from '../../MedicalRecords/MedicalRecordItems/EntryItemsList';
import { useAudits, useProtocol } from '../Hooks';

export const ExtraVisitTracking = (props) => {
    const { match: { params: { protocolId, patientId, visitId } } } = props;
    let history = useHistory();
    const [showHistory, setShowHistory] = useState(false);
    const [isAuthorized] = useAuthProtocol(protocolId);
    const [isProtocolLoading, brief] = useProtocol(protocolId);
    const settingsStore = useSelector(state => state.settings);
    const [isAuditLoading, , getLastVisitTrackingChange] = useAudits("VisitTrackingExtra", visitId);
    const [isLoading, setIsLoading] = useState(false);

    const [forms, setForms] = useState([]);
    const [visitTracking, setVisitTracking] = useState(null);
    const [selectedForm, setSelectedForm] = useState(null);
    const [modal, setModal] = useState(null);
    const [patientNumber, setPatientNumber] = useState(null);
    useEffect(() => {
        const fetchData = () => {
            setIsLoading(true);

            getVisitTrackingExtraForms(protocolId, visitId, patientId)
                .then(response => {
                    var visitTracking = response.data;

                    if (visitTracking.forms && visitTracking.forms.length > 0) {
                        let formAux = visitTracking.forms[0];
                        formAux.text = visitTracking.visit.text;
                        setSelectedForm(formAux);
                    }
                    setForms(visitTracking.forms);
                    setVisitTracking(visitTracking.visit);
                    setPatientNumber(visitTracking.patientNumber);
                    setIsLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    HandleApiError(error);
                    setIsLoading(false);
                })
        }

        fetchData();
    }, [protocolId, visitId, patientId]);

    const _save = async (formVisitTracking) => {
        let res = await saveVisitTrackingExtraForm(protocolId, visitId, patientId, formVisitTracking);
        let form = res.data.form;
        const visit = res.data.visit;

        // Upload attachments if any. 
        /*let fieldsWithFiles = formVisitTracking.patientForm.fields.filter(x => x._t === "FileUploadField" && x.value.file);
        if (fieldsWithFiles && fieldsWithFiles.length > 0) {

            setModal(<ModalSpinner isShowing={true} hide={null} />);
            var lastResponse = null;
            for (const fieldWithFile of fieldsWithFiles) {
                let attachment = fieldWithFile.value;

                // Initial FormData
                const formData = new FormData();
                formData.append("attachment", attachment.file);
                formData.append("title", attachment.fileName);

                // Make an AJAX upload request using Axios
                try {
                    lastResponse = await uploadVisitTrackingAttachmentForm(protocolId, visitId, form.id, patientId, formData);
                }
                catch (error) {
                    console.log(error.response);
                }
            }
            setModal(null);
            if (lastResponse?.data)
                form = lastResponse.data;
        }*/

        let formsCopy = JSON.parse(JSON.stringify(forms));
        var index = formsCopy.findIndex(x => x.id === form.id);
        formsCopy.splice(index, 1, form); //Reemplazo form.
        setVisitTracking(visit);
        setForms(formsCopy);

        return form;
    }

    const saveVisit = async (formVisitTracking, formikProps) => {

        try {
            setModal(<ModalSpinner isShowing={true} hide={null} />);
            let form = await _save(formVisitTracking);
            setSelectedForm(form); // Si estoy guardando manualmente, luego de hacerlo reemplazo el selectedForm.

            setModal(null);
            Success("protocolNotifications.visitTracking_FormSaved");
        }
        catch (error) {
            setModal(null);
            formikProps.setSubmitting(false);
            console.log(error);
            HandleApiError(error);
        }
    }

    const autoSaveVisit = async (valuesToSave, formikProps) => {
        try {
            if (formikProps.isSubmitting)
                return;

            formikProps.setSubmitting(true);
            let formVisitTracking = { patientForm: valuesToSave };
            return await _save(formVisitTracking);
        }
        catch (error) {
            setModal(null);
            console.log(error);
            HandleApiError(error);
        }
        finally {
            formikProps.setSubmitting(false);
        }
    }

    const _publishVisit = async (digitalSign, actions) => {

        try {
            setModal(<ModalSpinner isShowing={true} hide={null} />);
            const res = await publishVisitTrackingExtraForm(protocolId, visitId, patientId, digitalSign);
            setModal(null);
            setSelectedForm({ text: res.data.text });
            setVisitTracking(res.data);
            actions.setSubmitting(false);
            Success("protocolNotifications.visitTracking_VisitPublished");
        }
        catch (error) {
            setModal(null);
            actions.setSubmitting(false);
            console.log(error);
            HandleApiError(error);
        }
    }

    const handleSaveVisit = (formVisitTracking, formikProps) => {

        if (visitTracking.status !== visitStatusEnum.Started || formVisitTracking.status !== formStatus.Initial) {
            setModal(<CreateAuditModal
                onClose={() => setModal(null)}
                onSave={(reason, comments) => saveVisit({ patientForm: formVisitTracking, audit: { reason: reason, comments: comments } }, formikProps)}
            />)
        }
        else {
            saveVisit({ patientForm: formVisitTracking }, formikProps);
        }
    }

    const handleSubmitFormik = (_formVisitTracking, actions) => {
        if (settingsStore.settings.digitalSignEnabled)
            setModal(<ModalDigitalSign
                isShowing={true}
                hide={() => { setModal(null); actions.setSubmitting(false); }}
                onSubmit={(pin) => _publishVisit(pin, actions)}
            />);
        else
            _publishVisit({ password: "" }, actions)
    }

    const handleSubmitForm = (evt, formikBag) => {
        evt.preventDefault();

        if (!formikBag.dirty || (formikBag.dirty && window.confirm("Hay cambios sin guardar, está seguro que desea continuar?"))) {
            formikBag.submitForm();
        }
    }

    const handleAuditTrail = () => {
        setModal(
            <AuditModal
                onClose={() => { setModal(null); }}
                title="Auditoría"
            >
                Campos:
                <AuditTrail entity="VisitTrackingExtraForm" entityId={selectedForm.id} fields={selectedForm && selectedForm.fields} />

                Evolución:
                <AuditTrail entity="VisitTrackingExtra" entityId={visitTracking.id} />
            </AuditModal>
        )
    }

    const handleSelectForm = (formId, isDirty) => {
        if (!isDirty || (isDirty && window.confirm("Hay cambios sin guardar, está seguro que desea continuar?"))) {
            let selectedForms = forms.filter(x => { return x.id === formId });
            let formAux = selectedForms.length > 0 ? selectedForms[0] : {};
            formAux.text = selectedForm.text;

            setSelectedForm(formAux);
        }
    }

    const handleScreenFailure = (formikBag) => {

        if (!formikBag.dirty || (formikBag.dirty && window.confirm("Hay cambios sin guardar, está seguro que desea continuar?"))) {
            setModal(<ScreenFailure
                onClose={() => { setModal(null); formikBag.setSubmitting(false); }}
                onConfirm={() => confirmScreenFailure(formikBag)}
            />)
        }
    }

    const confirmScreenFailure = (formikBag) => {
        if (settingsStore.settings.digitalSignEnabled)
            setModal(<ModalDigitalSign
                isShowing={true}
                isLoading={isLoading}
                hide={() => { setModal(null); formikBag.setSubmitting(false); }}
                onSubmit={(pin) => _publishScreenFailure(pin, formikBag)}
            />);
        else
            _publishScreenFailure({ password: "" }, formikBag)
    }

    const _publishScreenFailure = async (digitalSign, formikBag) => {
        try {
            setIsLoading(true);
            const res = await screenFailureVisitTracking(protocolId, visitId, patientId, digitalSign);
            setModal(null);
            setSelectedForm({ text: res.data.text });
            setVisitTracking(res.data);
            formikBag.setSubmitting(false);
            setIsLoading(false);
            Success("protocolNotifications.visitTracking_VisitPublished");
        }
        catch (error) {
            setModal(null);
            setIsLoading(false);
            formikBag.setSubmitting(false);
            console.log(error);
            HandleApiError(error);
        }
    }

    if (!visitTracking || isLoading || isAuditLoading)
        return <Spinner />;

    const isDisabled = !isAuthorized(["VisitTracking.Add"]) || (brief && brief.status && brief.status.code === "Closed"); // || visitTracking?.status === 2 || visitTracking?.status === 3;

    return (
        <>
            {modal}
            <Formik
                initialValues={selectedForm}
                validationSchema={getValidationSchema}
                onSubmit={handleSubmitFormik}
                enableReinitialize={true}
                validateOnBlur={false}
            >
                {props => (
                    <form onSubmit={(evt) => handleSubmitForm(evt, props)}>
                        <Row>
                            <Col md={12} lg={12}>
                                <ProtocolInfo protocol={brief} isLoading={isProtocolLoading} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} lg={3}>
                                <Row>
                                    <Col md={12}>
                                        <FormMenu
                                            forms={forms}
                                            onClick={(formId) => handleSelectForm(formId, props.dirty)}
                                            selectedFormId={selectedForm?.id}
                                            formsWithErrors={[]}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormActions
                                            protocolId={protocolId}
                                            visit={visitTracking}
                                            onBack={(evt) => { evt.preventDefault(); history.goBack(); }}
                                            onSave={() => handleSaveVisit(props.values, props)}
                                            onAuditTrail={handleAuditTrail}
                                            onScreenFailure={() => handleScreenFailure(props)}
                                            onBackToMR={() => history.push(`/admin/medicalRecords/${visitTracking.patientId}`)}
                                            isSubmitting={props.isSubmitting}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4} lg={5}>
                                {visitTracking && <VisitStatus status={visitTracking?.status} />}
                                <DynamicForm
                                    {...props}
                                    patientId={visitTracking.patientId}
                                    patientNumber={patientNumber}
                                    visitId={visitId}
                                    protocolId={protocolId}
                                    //onDownload={handleDownloadFile}
                                    isDisabled={isDisabled}
                                    getLastChange={getLastVisitTrackingChange}
                                />
                                {/*<AutoSavingForm
                                    initialValues={selectedForm}
                                    valuesToSave={props.values}
                                    setInitialValues={setSelectedForm}
                                    onSave={autoSaveVisit}
                                    formikProps={props}
                />*/}
                            </Col>
                            <Col md={4} lg={4} className="sticky">
                                <button type="button"
                                    className="btn btn-primary btn-fill btn-full"
                                    onClick={() => { setShowHistory(!showHistory) }}
                                    style={{ marginBottom: '22px', textAlign: 'center' }}>
                                    <MedicalRecord /> Ver Historia Clínica
                                </button>
                                <Entry
                                    {...props}
                                    audit={getLastVisitTrackingChange("Text")}
                                    isDisabled={isDisabled}
                                    title={visitTracking.type === 1 ? "Visita Extra Protocolar" : "Nota Aclaratoria"}
                                    subtitle={patientNumber}
                                />
                                {
                                    (visitTracking?.status === visitStatusEnum.Completed || visitTracking?.status === visitStatusEnum.Skipped || visitTracking?.status === visitStatusEnum.Failed) &&
                                    <SignWithOutInfo signedBy={visitTracking.createdById} created={visitTracking.created} />
                                }
                            </Col>
                            <Slider isOpen={showHistory}>
                                <Grid fluid>
                                    <button type="button"
                                        className="btn btn-primary btn-fill btn-full"
                                        onClick={() => { setShowHistory(!showHistory) }}
                                        style={{ margin: '22px 0', textAlign: 'center' }}>
                                        <MedicalRecord /> Ocultar Historia Clínica
                                    </button>
                                    <EntryItemsListTabs medicalRecordNumber={visitTracking.patientId} />
                                </Grid>
                            </Slider>
                        </Row>
                    </form>
                )}
            </Formik>
        </>
    );
}