import React, { useState } from "react";
import { useFamilyHistories } from "../Hooks/useFamilyHistory";
import { FamilyHistory } from "../../../components/MedicalRecords/FamilyHistory";
import { AuditModal } from "../../../components/AuditTrail/AuditModal";
import { AuditTrail } from "../../Admin/AuditTrail";
import { ExpandableContent } from "../../../components/CustomControls/ExpandableContent";
import { useTranslation } from "react-i18next";

export const FamilyHistoryListContainer = ({ entryId, medicalRecordNumber, expandedAll }) => {
    const { t } = useTranslation();
    const [data, query, totalSize, isLoading, handleTableChange,] = useFamilyHistories(medicalRecordNumber, entryId);

    const [modal, setModal] = useState(null);
    const handleAuditTrail = (entityId) => {
        setModal(
            <AuditModal
                onClose={() => { setModal(null); }}
                title="Auditoría"
            >
                <AuditTrail entity="FamilyHistory" entityId={entityId} medicalRecordNumber={medicalRecordNumber} />
            </AuditModal>
        )
    }

    if (totalSize === 0)
        return null;

    return (
        <ExpandableContent title={t("medicalRecords.familyHistory.title")} content="familyHistory" expandedAll={expandedAll}>
            {modal}
            <FamilyHistory
                withCard={false}
                data={data}
                query={query}
                totalSize={totalSize}
                isLoading={isLoading}
                onTableChange={handleTableChange}
                onAuditTrail={handleAuditTrail}
            />
        </ExpandableContent>
    );
}