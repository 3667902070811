import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { useTranslation } from 'react-i18next';
import { useProcedures } from '../../containers/MedicalRecords/Hooks/useProcedures';
import { NullableDatePickerCombo } from '../CustomControls/DatePicker';
import { ExpandableContent } from '../CustomControls/ExpandableContent';
import { dateFormatter } from '../Utils/Formatter';
import { EntryItemsTable } from './EntryItemsTable';

export const Procedures = (props) => {
    const { t } = useTranslation();

    const columns = [
        {
            dataField: 'procedure', text: t('medicalRecords.procedures.procedure'), formatter: function (_cell, row, rowindex) {
                return (

                    <Row key={`procedure-item-${rowindex}`} style={{ textAlign: 'left' }} className="item-data">
                        <Col md={12}><span className="item-label">{dateFormatter(row.created, "DD/MM/YY")}</span> - <span className="item-text">{row.procedure}</span></Col>
                        <Col md={6}>
                            {row.from && <div><span className="item-label">{t('medicalRecords.from')}:</span> <span className="item-text">{row.from}</span></div>}
                        </Col>
                        <Col md={6}>
                            {row.to && <div><span className="item-label">{t('medicalRecords.to')}:</span> <span className="item-text">{row.to}</span></div>}
                        </Col>
                    </Row>
                )
            }
        },
    ];

    const expandedContent = (row) => (
        <div className="info">
            {row.reason && <div><span className="patient-label">{t('medicalRecords.procedures.reason')}:</span> <span className="patient-text">{row.reason}</span></div>}
            {row.notes && <div><span className="patient-label">{t('medicalRecords.procedures.notes')}:</span> <span className="patient-text">{row.notes}</span></div>}
        </div>
    )
    const rowClasses = (row) => { return row.originalId && row.originalId !== row.id ? 'item-modified' : ''; };

    return (<EntryItemsTable columns={columns} {...props} rowClasses={rowClasses} expandContent={expandedContent} />);
}

export const ProceduresOpenItems = (props) => {
    const { value, medicalRecordNumber, expandedAll, isDisabled, title } = props;
    const [procedures, query, totalSize, isLoading, handleTableChange] = useProcedures(medicalRecordNumber, null, true);
    const [closedItems, setClosedItems] = useState(value !== "" && value !== null ? value : []);
    const { t } = useTranslation();

    const handleChange = (item, name, value) => {
        let dateName = name.split(".")[1];
        var closedItem = closedItems.find(x => x.id === item.id);

        if (closedItem) { // Update
            closedItem.closed[dateName] = value;

            if (!closedItem.closed.toDay && !closedItem.closed.toMonth && !closedItem.closed.toYear) {
                const closedItemsCopy = closedItems.filter(x => x.id !== closedItem.id); // Remove
                setClosedItems(closedItemsCopy);
                props.onChange(props.name, closedItemsCopy);
            } else {
                let closedItemsCopy = JSON.parse(JSON.stringify(closedItems));
                var index = closedItemsCopy.findIndex(x => x.id === item.id);
                closedItemsCopy.splice(index, 1, closedItem); // Update. 
                setClosedItems(closedItemsCopy);
                props.onChange(props.name, closedItemsCopy);
            }
        }
        else {
            // New
            var joined = closedItems.concat({
                id: item.id,
                closed: { [dateName]: value },
                name: item.procedure
            });
            setClosedItems(joined);
            props.onChange(props.name, joined);
        }
    }

    const handleBlur = () => {
        if (props.onBlur)
            props.onBlur(props.name, true);
    };

    const columns = [
        { dataField: 'procedure', text: t('medicalRecords.procedures.procedure') },
        { dataField: 'from', text: t('medicalRecords.from') },
        {
            isDummyField: true,
            dataField: 'to',
            text: t('medicalRecords.to'),
            formatter: function (_cell, row, rowindex) {
                var closedItem = closedItems?.find(x => x.id === row.id);

                return (<span className="item-text">
                    <NullableDatePickerCombo
                        nameDay={`${rowindex}.toDay`}
                        nameMonth={`${rowindex}.toMonth`}
                        nameYear={`${rowindex}.toYear`}
                        onChange={(name, value) => handleChange(row, name, value)}
                        onBlur={handleBlur}
                        valueDay={closedItem ? closedItem.closed.toDay : row.toDay}
                        valueMonth={closedItem ? closedItem.closed.toMonth : row.toMonth}
                        valueYear={closedItem ? closedItem.closed.toYear : row.toYear}
                        disabled={isDisabled}
                    />
                </span>)
            }
        }
    ];

    const columnsClosed = [
        { dataField: 'name', text: t('medicalRecords.procedures.procedure') },
        {
            isDummyField: true,
            dataField: 'to',
            text: t('medicalRecords.to'),
            formatter: function (_cell, row, rowindex) {
                if (!row.closed) return "";

                return (
                    <span className="item-text">
                        <NullableDatePickerCombo
                            nameDay={`${rowindex}.toDay`}
                            nameMonth={`${rowindex}.toMonth`}
                            nameYear={`${rowindex}.toYear`}
                            onChange={(name, value) => handleChange(row, name, value)}
                            onBlur={handleBlur}
                            valueDay={row.closed.toDay}
                            valueMonth={row.closed.toMonth}
                            valueYear={row.closed.toYear}
                            disabled={isDisabled}
                        />
                    </span>
                )
            }
        }
    ];

    let data = [];
    if (!isLoading && value && value.length > 0 && procedures.length > 0) {
        value.forEach(closed => {
            if (!procedures.find(x => x.id === closed.id)) {
                data.push(closed);
            }
        });
    }

    return (
        <>
            {
                data && data.length > 0 &&
                <ExpandableContent title="Cerrados en la Visita actual." expandedAll={expandedAll} content="adverseEvents">
                    <BootstrapTable
                        bordered={false}
                        classes="table-spaced table-spaced-small"
                        wrapperClasses="table-responsive"
                        striped
                        hover
                        keyField='id'
                        data={data ? data : []}
                        columns={columnsClosed}
                    />
                </ExpandableContent>
            }
            <ExpandableContent title={title} expandedAll={expandedAll} content="procedures">
                <BootstrapTable
                    bordered={false}
                    classes="table-spaced table-spaced-small"
                    wrapperClasses="table-responsive"
                    striped
                    hover
                    remote
                    keyField='id'
                    data={procedures ? procedures : []}
                    columns={columns}
                    pagination={query && query.pagination && paginationFactory({
                        page: query.pagination.page,
                        sizePerPage: query.pagination.sizePerPage,
                        totalSize: totalSize,
                        hideSizePerPage: true,
                        hidePageListOnlyOnePage: true
                    })}
                    onTableChange={handleTableChange}
                    noDataIndication={() => <>Sin información</>}
                    loading={isLoading}
                    overlay={overlayFactory({ spinner: true })}
                />
            </ExpandableContent>
        </>
    );
}