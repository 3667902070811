import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { DropdownButton, MenuItem } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Card from '../../Cards/Card';
import { dateTimeFormatter } from '../../Utils/Formatter';
import { Tooltip } from '../../Utils/Tooltips';
import { Check, Comments, Remove } from '../../Icons/Icons';
import { useTranslation } from 'react-i18next';
import { WithAuth } from '../../Authorization/WithAuthProtocol';
import ProtocolContext from '../../../containers/Protocols/ProtocolContext/ProtocolContext';

const isDraft = (version) => { return version % 1 !== 0 }
const hasDraft = (visits) => { return visits.find(x => isDraft(x.version)) }

export const VisitHistory = props => {
    const { t } = useTranslation();
    const context = useContext(ProtocolContext);

    // Establezco campo Status en base a su versión. 
    let hasActiveVersion = false;
    props.visitHistory.map(history => {
        history.active = false;
        if (isDraft(history.version)) {
            history.versionStatus = "Borrador";
        }
        else {
            if (!isDraft(history.version) && !hasActiveVersion) {
                hasActiveVersion = true;
                history.versionStatus = "Activa";
                history.active = true;
            }
            else {
                history.versionStatus = "Historial"
            }
        }
    })

    if (context.isLoading) return null;

    const columns = [
        { dataField: 'version', text: t("commons.version") },
        { dataField: 'versionStatus', text: t("commons.status") },
        { dataField: 'modified', text: t("commons.modified"), formatter: dateTimeFormatter },
        { dataField: 'modifiedBy.fullName', text: t("commons.modifiedBy") },
        {
            dataField: 'versionComments', text: '', formatter: function (_cell, row) {

                if (isDraft(row.version) || !row.versionComments) {
                    return null;
                }

                return (
                    <Tooltip tooltip={row.versionComments} id={"tooltipComments-" + row.id}>
                        <Comments />
                    </Tooltip>
                );
            }
        },
        {
            dataField: 'actions',
            text: t("commons.actions"),
            isDummyField: true,
            headerClasses: 'header-visit-actions',
            classes: 'row-visit-actions',
            formatter: function (_cell, row, rowIndex) {

                return (
                    <div key={rowIndex}>
                        {
                            !context.isDisabled &&
                            <WithAuth protocolId={row.protocolId} requiredPermission={["Visits.Add"]}>
                                <DropdownButton
                                    bsSize="xsmall"
                                    title={<span aria-hidden="true"><i className="pe-7s-more"></i></span>}
                                    key={rowIndex}
                                    id={`dropdown-basic-${rowIndex}`}
                                >
                                    {
                                        isDraft(row.version) || (!hasDraft(props.visitHistory) && row.active) ? (<MenuItem eventKey="1" onClick={() => props.onEditVisit(row.visitId)}><span aria-hidden="true"><i className="pe-7s-note"></i></span> Editar</MenuItem>) : null
                                    }
                                    {
                                        isDraft(row.version) ? // Draft
                                            (<>
                                                <MenuItem eventKey="2" onClick={() => props.onRemoveVisit(row.visitId)}><Remove /> Eliminar</MenuItem>
                                                <MenuItem eventKey="3" onClick={() => props.onPublishVisit(row.visitId)}><span aria-hidden="true"><Check /> </span> Activar</MenuItem>
                                            </>)
                                            : // History o Active
                                            (<>
                                                <MenuItem eventKey="4" disabled><span aria-hidden="true"><i className="pe-7s-file"></i></span> Crear PDF</MenuItem>
                                                <MenuItem eventKey="5" disabled><span aria-hidden="true"><i className="pe-7s-diskette"></i></span> Guardar como Template</MenuItem>
                                            </>)
                                    }
                                </DropdownButton>
                            </WithAuth>
                        }
                    </div>
                )
            }
        },
    ];
    
    return (
        <Card
            title="Historial de versiones"
            content={
                <div className="container-fluid card">
                    <BootstrapTable
                        bordered={false}
                        hover
                        remote
                        keyField='id'
                        data={props.visitHistory}
                        loading={context.isLoading}
                        columns={columns}
                    />
                </div>
            }
        />
    );
}

VisitHistory.propTypes = {
    visitHistory: PropTypes.array.isRequired,
    onPublishVisit: PropTypes.func.isRequired,
    onRemoveVisit: PropTypes.func.isRequired,
    onEditVisit: PropTypes.func.isRequired,
};