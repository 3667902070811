import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import { addPatients } from '../../../api/protocol';
import { HandleApiError } from "../../../components/Notifications/APIErrorHandler";
import { Success } from "../../../components/Notifications/Notifications";
import { PatientsForm } from '../../../components/Protocols/Patients';
import { getProtocolPatientsSchema } from '../../../components/Validations/FormValidationSchema';

export const AddPatientModal = (props) => {
    const { isShowing, hide, submit, protocolId } = props;
    const { t } = useTranslation();

    return isShowing ? ReactDOM.createPortal(
        <React.Fragment>
            <BootstrapModal show={true} onHide={hide} bsSize="large" backdrop="static" >
                <BootstrapModal.Header closeButton>
                    <BootstrapModal.Title>{t('protocols.patient.addPatientTitle')}</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    <ProtocolAddPatientsContainer protocolId={protocolId} onCancel={hide} onSubmit={submit} />
                </BootstrapModal.Body>
            </BootstrapModal>
        </React.Fragment>, document.body
    ) : null;
};

const ProtocolAddPatientsContainer = withTranslation()(withFormik({
    mapPropsToValues: (props) => ({}),

    validationSchema: getProtocolPatientsSchema(),

    handleSubmit: (values, formikBag) => {
        //console.log("Posting Patients:", values.patients);
        const list = values.patients.map(x => {
            x.patient.numberInProtocol = x.numberInProtocol;
            return x.patient;
        });
        //console.log(list)
        addPatients(formikBag.props.protocolId, { patients: list })
            .then(res => {
                var item = res.data
                formikBag.setSubmitting(false);
                Success("protocolNotifications.patients_Added");
                formikBag.props.onSubmit(item);
            }).catch(error => {
                console.error(error);
                formikBag.setSubmitting(false);
                HandleApiError(error);
            });
    },

    displayName: 'AddPatients',

})(PatientsForm));

ProtocolAddPatientsContainer.propTypes = {
    //onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    protocolId: PropTypes.string.isRequired,
};