import { ErrorMessage } from 'formik';
import React from 'react';
import { Col, ControlLabel, FormControl, FormGroup, Grid, Row } from 'react-bootstrap';
import { FormErrorMessage } from '../Validations/FormValidationErrors';

export const DigitalSignForm = props => {

    const {
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        currentUser,
        t
    } = props;

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <FormGroup>
                <Grid fluid>
                    <Row>
                        <Col sm={12}>
                            <ControlLabel>Usuario</ControlLabel>
                            <FormControl.Static>{currentUser}</FormControl.Static>
                        </Col>
                        <Col sm={12}>
                            <ControlLabel>PIN</ControlLabel>
                            <FormControl type="password" name="password" value={values.password ?? ""} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                            <ErrorMessage name="password" component={FormErrorMessage} />
                        </Col>
                    </Row>
                </Grid>
            </FormGroup>
            
            <button type="submit" className="btn btn-success btn-right" >{t("buttons.send")}</button>
            <div className="clearfix"></div>
        </form>
    );
}