import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from "react-router-dom"
import Card from '../../Cards/Card'
import { Down, Up, Add, Config } from '../../Icons/Icons'
import { WithAuth } from '../../../components/Authorization/WithAuthProtocol';
import { Spinner } from '../../../components/Utils/Loaders';
import { useTranslation } from 'react-i18next'
import ProtocolContext from '../../../containers/Protocols/ProtocolContext/ProtocolContext'

export const VisitForms = props => {
    const { t } = useTranslation();
    const context = useContext(ProtocolContext);

    const handleSwapForm = (oldIndex, newIndex) => {
        props.onSwap(oldIndex, newIndex);
    };

    const subTitleComponent = !context.isDisabled && (<NavLink to={`/admin/protocols/${context.protocolId}/visitsTemplate/${props.visitId}/forms/create`} className="nav-link" activeClassName="active">
        <Add /> {t("protocols.visit.addVisitForm")}
    </NavLink>);

    const content = (props.forms && <ul className="list-container">
        {props.forms.map((form, index) => (
            <li className="list-items" key={`swap${index}`}>
                <WithAuth protocolId={context.protocolId} requiredPermission={["Visits.Add"]}>
                    <div style={{ minWidth: '45px' }}>
                        {
                            props.forms.length !== index + 1 && !context.isDisabled &&
                            <button
                                type="button"
                                className="btn-table-actions btn-swap"
                                style={{ marginRight: '5px' }}
                                onClick={() => handleSwapForm(index, index + 1)} >
                                <Down />
                            </button>
                        }
                        {
                            0 !== index && !context.isDisabled &&
                            <button
                                type="button"
                                className="btn-table-actions btn-swap"
                                style={{ marginRight: '5px' }}
                                onClick={() => handleSwapForm(index, index - 1)} >
                                <Up />
                            </button>
                        }
                    </div>
                </WithAuth>
                <NavLink
                    to={`/admin/protocols/${context.protocolId}/visitsTemplate/${props.visitId}/forms/${form.id}`}
                    style={{ marginLeft: '10px' }}>
                    <span aria-hidden="true"><Config /></span> {form.title}
                </NavLink>
            </li>
        ))}
    </ul>);

    if (props.isLoading || !props.visitId)
        return <Card
            title={t("protocols.visit.formTitle")}
            content={<Spinner />}
        />

    return (
        <Card
            title={t("protocols.visit.formTitle")}
            subtitle={
                !props.isPublished &&
                <WithAuth protocolId={context.protocolId} requiredPermission={["Visits.Add"]}>
                    {subTitleComponent}
                </WithAuth>
            }
            content={content}
        />
    );
}

VisitForms.propTypes = {
    onSwap: PropTypes.func.isRequired,
    forms: PropTypes.array,
    visitId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    isPublished: PropTypes.bool
};