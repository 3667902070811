import React, { useContext, useEffect } from 'react';
import { ReactReduxContext, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { AuthorizeRoute } from "./components/api-authorization/AuthorizeRoute";
import { WithAuth } from './components/Authorization/WithAuth';
import SessionTimeout from './components/SessionControls/SessionTimeout';
import { Spinner } from './components/Utils/Loaders';
import AdminLayout from './layouts/Admin';
import { setupSignalRConnection } from './signalR';

export default (props) => {
  const { store } = useContext(ReactReduxContext);
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);
  const settingsState = useSelector(state => state.settings);
  let navigation = useHistory();

  //useEffect(() => {
  //  if (isAuthenticated) {
  //    var connection = setupSignalRConnection(store);
//
  //    return (() => { connection.stop() })
  //  }
  //}, [isAuthenticated]);

  const handleLogOut = () => {
    navigation.push({ pathname: `${ApplicationPaths.LogOut}`, state: { local: true } });
  }

  if (settingsState.isLoading)
    return <Spinner />;

  return (
    <>
      <WithAuth requiredPermission={["MedicalRecords.Create"]}>
        <SessionTimeout isAuthenticated={isAuthenticated} logOut={handleLogOut} />
      </WithAuth>
      <Switch>
        <AuthorizeRoute path="/admin" component={props => <AdminLayout {...props} />} />
        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
        <Redirect from="/" to="/admin/dashboard" />
      </Switch>
    </>
  );
}