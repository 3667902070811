import React from 'react';
import { Col, FormControl, FormGroup, Grid, Row } from 'react-bootstrap';
import Card from '../Cards/Card';
import { CustomFileUpload } from '../CustomControls/CustomFileUpload';

export const SettingsForm = props => {
    const {
        isSubmitting,
        isDisabled,
        onAutoClose,
        onFirstMRN,
        onHeader,
        onFooter,
        values,
        setFieldValue,
        handleChange,
        settings,
        isLoading,
        t,
    } = props;

    var data = {
        mrsettings: settings && JSON.parse(settings.mrdata),
        dbsettings: settings && JSON.parse(settings.dbdata),
        prsettings: settings && JSON.parse(settings.prdata)
    };

    return (
        <Grid fluid>
            <Row>
                <Col md={6}>
                    {
                        !isLoading &&
                        <Card
                            title=""
                            content={
                                <Grid fluid>
                                    <Row>
                                        <Col sm={8}>
                                            <div className="card-label">Historia Clínica: Primer número de H.C.</div>
                                        </Col>
                                        <Col sm={2}>
                                            <FormControl autoFocus type="number" name={`firstMRN`} value={values.firstMRN || data.dbsettings?.find(x => x.key === "FirstMRN")?.value} onChange={handleChange} autoComplete="off" />
                                        </Col>
                                        <Col sm={2}>
                                            <button className="btn btn-success" type="button" disabled={isSubmitting} onClick={() => onFirstMRN(values.firstMRN)}>
                                                {t("buttons.save")}
                                            </button>
                                        </Col>
                                    </Row>
                                </Grid>
                            }
                        />
                    }
                </Col>

                <Col md={6}>
                    {
                        !isLoading &&
                        <Card
                            title=""
                            content={
                                <Grid fluid>
                                    <Row>
                                        <Col sm={8}>
                                            <div className="card-label">Visitas: Tiempo de Cerrado Automático (Horas)</div>
                                        </Col>
                                        <Col sm={2}>
                                            <FormControl autoFocus type="number" name={`autoclose`} value={values.autoclose || data.mrsettings?.find(x => x.key === "AutoClose")?.value} onChange={handleChange} autoComplete="off" />
                                        </Col>
                                        <Col sm={2}>
                                            <button className="btn btn-success" type="button" disabled={isSubmitting} onClick={() => onAutoClose(values.autoclose)}>
                                                {t("buttons.save")}
                                            </button>
                                        </Col>
                                    </Row>
                                </Grid>
                            }
                        />
                    }
                </Col>

                <Col md={12}>
                    <Card
                        title=""
                        content={
                            <FormGroup>
                                <Grid fluid>
                                    <Row>
                                        <Col sm={6}>
                                            <div className="card-label">Ordenes y Prescripciones: Cargar Header</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6}>
                                            <CustomFileUpload
                                                accept="image/*"
                                                disabled={isDisabled}
                                                value={values.header}
                                                name={`header`}
                                                placeholder={"Subir Header: Medidas 1654x245"}
                                                onChange={setFieldValue}
                                                onDownload={() => { }}
                                            />
                                        </Col>
                                        <Col sm={6}>
                                            <button className="btn btn-success" type="button" disabled={isSubmitting} onClick={() => onHeader(values.header)}>
                                                {t("buttons.save")}
                                            </button>
                                        </Col>
                                    </Row>
                                </Grid>
                                <Grid fluid>
                                    <Row>
                                        <Col sm={6}>
                                            <div className="card-label">Ordenes y Prescripciones: Cargar Footer</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6}>
                                            <CustomFileUpload
                                                accept="image/*"
                                                disabled={isDisabled}
                                                value={values.footer}
                                                name={`footer`}
                                                placeholder={"Subir Footer: Medidas 1654x90"}
                                                onChange={setFieldValue}
                                                onDownload={() => { }}
                                            />
                                        </Col>
                                        <Col sm={6}>
                                            <button className="btn btn-success" type="button" disabled={isSubmitting} onClick={() => onFooter(values.footer)}>
                                                {t("buttons.save")}
                                            </button>
                                        </Col>
                                    </Row>
                                </Grid>
                            </FormGroup>
                        }
                    />
                </Col>
            </Row>
        </Grid>
    );
};