import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, ControlLabel, Row } from 'react-bootstrap';
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';

export const NullableDatePicker = (props) => {

    const [dateValue, setDateValue] = useState(null);

    const handleChange = value => {
        let dateNumber;

        if (value) {
            switch (props.viewMode) {
                case "days":
                    dateNumber = value.date();
                    break;
                case "months":
                    dateNumber = value.month() + 1;
                    break;
                case "years":
                    dateNumber = value.year();
                    break;
                default:
                    break;
            }
        }
        setDateValue(value);

        // this is going to call setFieldValue and manually update values.[name]
        props.onChange(props.name, dateNumber, value);
    };
    const handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.[name]
        props.onBlur(props.name, true);
    };

    useEffect(() => {
        if (props.value && props.value !== "") {
            switch (props.viewMode) {
                case "days":
                    setDateValue(new Date(2000, 1, props.value));
                    break;
                case "months":
                    setDateValue(new Date(2000, props.value - 1, 1));
                    break;
                case "years":
                    setDateValue(new Date(props.value, 1, 1));
                    break;
                default:
                    break;
            }
        }
    }, [props.value, props.viewMode]);

    return <>
        <DateTime
            {...props}
            value={dateValue}
            onChange={handleChange}
            onBlur={handleBlur}
            isValidDate={props.valid}
        />
    </>;
}

NullableDatePicker.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired
};

export const NullableDatePickerCombo = (props) => {
    const {
        dayLabel,
        monthLabel,
        yearLabel,
        valueDay, valueMonth, valueYear,
        onChange,
        onBlur,
        disabled
    } = props;

    const [day, setDay] = useState(null);
    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(null);

    const [selectDay, setSelectDay] = useState(valueDay ?? (dayLabel));
    const [selectMonth, setSelectMonth] = useState(valueMonth ?? (monthLabel));
    const [selectYear, setSelectYear] = useState(valueYear ?? (yearLabel));
    useEffect(() => {
        let day = [], month = [], year = [];
        let maxDays = 31;
        let maxMonths = 11;

        if (selectYear && selectYear !== yearLabel && selectMonth && selectMonth !== monthLabel) {
            maxDays = moment(`${selectYear}-${parseInt(selectMonth) + 1}`, "YYYY-MM").daysInMonth();
        }

        if (selectYear && selectYear === moment().year().toString()) {
            maxMonths = moment().month();

            if (selectMonth && selectMonth === moment().month().toString())
                maxDays = moment().date();
        }
        for (let i = 1; i <= maxDays; i++) {
            day.push(i);
        }

        for (let i = 0; i <= maxMonths; i++) {
            month.push({
                text: props.useMonthNames ? moment.monthsShort('-MMM-', i) : (i + 1),
                value: i
            });
        }

        for (let i = moment().year(); i >= props.minYear; i--) {
            year.push(i);
        }

        setDay(day); setMonth(month); setYear(year);
    }, [selectDay, selectMonth, selectYear,]);

    const handleBlur = () => {
        if (onBlur)
            onBlur(props.name, true);
    };

    const handleChange = (e, type) => {
        let value = e.target.value == '' ? null : e.target.value;
        switch (type) {
            case "selectDay":
                setSelectDay(value);
                onChange(props.nameDay, value);
                break;
            case "selectMonth":
                setSelectMonth(value);
                onChange(props.nameMonth, value);
                break;
            case "selectYear":
                setSelectYear(e.target.value);
                onChange(props.nameYear, value);
                break;
            default:
                break;
        }
    };

    if (!day || !month || !year)
        return null;

    const dayElement = day.map((day, id) => {
        return <option value={day} key={id}>{day}</option>;
    });
    const monthElement = month.map((month, id) => {
        return <option value={month.value} key={id}>{month.text}</option>;
    });
    const yearElement = year.map((year, id) => {
        return <option value={year} key={id}>{year}</option>;
    });

    return (
        <Row>
            <Col md={4} style={{ paddingRight: '0' }}>
                <select className="form-control" value={selectDay} onChange={(e) => handleChange(e, 'selectDay')} onBlur={handleBlur} style={{ padding: '0' }} disabled={disabled}>
                    <option value="">{dayLabel}</option>
                    {dayElement}
                </select>
            </Col>
            <Col md={3} style={{ padding: '0' }}>
                <select className="form-control" value={selectMonth} onChange={(e) => handleChange(e, 'selectMonth')} onBlur={handleBlur} style={{ padding: '0' }} disabled={disabled}>
                    <option value="">{monthLabel}</option>
                    {monthElement}
                </select>
            </Col>
            <Col md={5} style={{ paddingLeft: '0' }}>
                <select className="form-control" value={selectYear} onChange={(e) => handleChange(e, 'selectYear')} onBlur={handleBlur} style={{ padding: '0' }} disabled={disabled}>
                    <option value="">{yearLabel}</option>
                    {yearElement}
                </select>
            </Col>
        </Row>
    );
}

NullableDatePickerCombo.propTypes = {
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    dayLabel: PropTypes.string,
    monthLabel: PropTypes.string,
    yearLabel: PropTypes.string,
    minYear: PropTypes.number,
    selectDay: PropTypes.string,
    selectMonth: PropTypes.string,
    selectYear: PropTypes.string,
    useMonthNames: PropTypes.bool,
    disabled: PropTypes.bool,
};

NullableDatePickerCombo.defaultProps = {
    dayLabel: 'dia',
    monthLabel: 'mes',
    yearLabel: 'año',
    minYear: 1916,
    selectDay: '',
    selectMonth: '',
    selectYear: '',
    useMonthNames: true,
    disabled: false
};

export const FromToNullableDatePicker = (props) => {
    const {
        valuesFrom,
        valuesTo,
        onChange,
        onBlur,
    } = props;

    const { t } = useTranslation();
    return (
        <Row>
            <Col sm={6}>
                <ControlLabel>{t("medicalRecords.from")}</ControlLabel>
                <NullableDatePickerCombo
                    nameDay={props.nameFromDay}
                    nameMonth={props.nameFromMonth}
                    nameYear={props.nameFromYear}
                    onChange={onChange}
                    onBlur={onBlur}
                    valueDay={valuesFrom.fromDay}
                    valueMonth={valuesFrom.fromMonth}
                    valueYear={valuesFrom.fromYear}
                />
            </Col>
            <Col sm={6}>
                <ControlLabel>{t("medicalRecords.to")}</ControlLabel>
                <NullableDatePickerCombo
                    nameDay={props.nameToDay}
                    nameMonth={props.nameToMonth}
                    nameYear={props.nameToYear}
                    onChange={onChange}
                    onBlur={onBlur}
                    values={valuesTo}
                    valueDay={valuesTo.toDay}
                    valueMonth={valuesTo.toMonth}
                    valueYear={valuesTo.toYear}
                />
            </Col>
        </Row>
    );
}

FromToNullableDatePicker.defaultProps = {
    nameFromDay: 'fromDay',
    nameFromMonth: 'fromMonth',
    nameFromYear: 'fromYear',
    nameToDay: 'toDay',
    nameToMonth: 'toMonth',
    nameToYear: 'toYear',
};