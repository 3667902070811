import React from "react";
import { Link } from "react-router-dom";

const Breadcrumbs = (props) => {

  let crumbs = props.crumbs;

  // If single Crumb. Add Home to init
  if (!crumbs) {
    return <div style={{ paddingTop: '30px' }}></div>;
  }

  if (crumbs.length === 1 && !crumbs.find(x => x.path === "/admin/dashboard")) {
    crumbs.unshift({ name: "Inicio", path: "/admin/dashboard" });
  }

  return (
    <div className="breadcrumb-container container-fluid">
      <ol className="breadcrumb">
        {/* Link back to any previous steps of the breadcrumb. */}
        {crumbs.map(({ name, path }, key) =>
          key + 1 === crumbs.length ? (
            <li key={key} className="active">{name}</li>
          ) : (
              <li key={key}>
                <Link to={path}>
                  {name}
                </Link>
              </li>
            )
        )}
      </ol>
    </div>
  );
};

export default Breadcrumbs;