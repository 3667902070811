import axios from './index';

export const uploadCerts = (data) => {
    return axios.post('api/v1/digitalSign/upload', data);
};

export const signData = (data) => {
    return axios.post('api/v1/digitalSign/sign', data);
};

export const verifySign = (data) => {
    return axios.post('api/v1/digitalSign/verify', data);
};
