import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Col, ControlLabel, Grid, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TextBoxUsersMentions } from '../CustomControls/TextBoxUsersMentions';
import { getFullNameFromUserMention } from '../Utils/Commons';
import { dateFormatter } from '../Utils/Formatter';
import { Spinner } from '../Utils/Loaders'

export const Summaries = (props) => {

    if (!props.summaries)
        return null;

    return (
        <Grid fluid>
            {
                props.summaries.sort(function (a, b) {
                    return new Date(b.created) - new Date(a.created);
                }).map((summary, index) => (
                    <Row key={`note-idx-${index}`} style={{ padding: '10px 0', borderBottom: '1px solid #eee' }}>
                        <Col sm={12}>
                            <div>[{dateFormatter(summary.created)} - {summary.createdBy}] {getFullNameFromUserMention(summary.note)}</div>
                        </Col>
                    </Row>
                ))
            }
        </Grid>
    );
}
Summaries.propTypes = {
    summaries: PropTypes.array.isRequired
};

export const SummariesFlat = (props) => {

    if (!props.summaries)
        return null;

    return (
        <>
            {
                props.summaries.sort(function (a, b) {
                    return new Date(b.created) - new Date(a.created);
                }).map((summary, index) => (
                    <div key={`summary-${index}`}>[{dateFormatter(summary.created)} - {summary.createdBy}] {getFullNameFromUserMention(summary.note)}</div>
                ))
            }
        </>
    );
}
SummariesFlat.propTypes = {
    summaries: PropTypes.array.isRequired
};

export const SummaryFlat = (props) => {
    const { summary } = props;
    if (!summary)
        return null;

    return (
        <div>[{dateFormatter(summary.created)} - {summary.createdBy}] {getFullNameFromUserMention(summary.note)}</div>
    );
}


export const SummaryForm = ({ onCreate, onEdit, summary, isLoading }) => {
    const { t } = useTranslation();
    const [text, setText] = useState("");

    useEffect(() => {
        if (summary)
            setText(summary.note);
    }, [summary]);

    const handleChange = (_name, value) => {
        setText(value);
    }

    const handleCreate = () => {
        if (onCreate)
            onCreate(text);
    }

    const handleEdit = () => {
        if (onEdit) {
            onEdit(summary.id, text);
        }
    }

    if (isLoading)
        return <Spinner />;

    return (
        <Grid fluid>
            <Row>
                <Col sm={12}>
                    <ControlLabel>{t("medicalRecords.summary.summary")}</ControlLabel>
                </Col>
                <Col sm={9}>
                    <TextBoxUsersMentions name={`summaries`} value={text} onChange={handleChange} maxLength={280} />
                </Col>
                <Col sm={3}>
                    {
                        summary
                            ?
                            <Button bsStyle="success" onClick={handleEdit}>{t("buttons.save")}</Button>
                            :
                            <Button bsStyle="success" onClick={handleCreate}>{t("buttons.add")}</Button>
                    }
                </Col>
            </Row>
        </Grid>
    );
}
SummaryForm.propTypes = {
    summary: PropTypes.string,
    onCreate: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired
};