import { ErrorMessage } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { Button, Col, ControlLabel, FormGroup, Grid, Modal, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useTranslation } from "react-i18next";
import { useScheduleLocks } from '../../containers/Agenda/Hooks';
import { visitTypeColorGetter } from '../Agenda/Utils';
import Card from '../Cards/Card';
import { CustomDate, CustomTime } from '../CustomControls/CustomDateTime';
import { CustomFormControl } from '../CustomControls/CustomFormControl';
import { CustomRadio } from "../CustomControls/CustomRadio";
import { CustomSelect } from "../CustomControls/CustomSelect";
import { PractitionerPicker } from '../CustomControls/PractitionerPicker';
import { Remove, Save } from "../Icons/Icons";
import { ModalConfirm } from '../Modals/ModalConfirm';
import { ModalData } from '../Modals/ModalData';
import { dateFormatter } from '../Utils/Formatter';
import { Tooltip } from '../Utils/Tooltips';
import { FormErrorMessage } from '../Validations/FormValidationErrors';
import { validateIsSameOfAfter } from './Utils';

export const ScheduleLocks = () => {
    const [data, query, totalSize, isLoading, handleTableChange, , handleRemove] = useScheduleLocks();
    const [modal, setModal] = useState(null);
    const { t } = useTranslation();

    const handleRemoveLock = (id) => {
        if (id)
            setModal(
                <ModalConfirm
                    onCancel={() => setModal(null)}
                    onConfirm={() => confirmRemove(id)}
                    title={t('calendar.removeScheduleLock')}
                />
            );
    }

    const confirmRemove = async (id) => {
        try {
            setModal(null);
            await handleRemove(id);
            await handleTableChange("reload", { page: null, sizePerPage: null, sortField: null, sortOrder: null });
        }
        catch (error) {
            console.log(error)
        }
    }

    const columns = [
        { dataField: 'practitioner', text: "Profesional", formatter: function (cell) { return cell?.fullName } },
        { dataField: 'start', text: "Inicio", formatter: function (cell, row) { return (<div>{dateFormatter(cell, "DD/MMM/YYYY")} {row.lockFrom}</div>); } },
        { dataField: 'end', text: "Fin", formatter: function (cell, row) { return (<div>{dateFormatter(cell, "DD/MMM/YYYY")} {row.lockTo}</div>); } },
        { dataField: 'comments', text: "Motivo" },
        {
            dataField: 'view',
            text: t("protocols.practitioner.actions"),
            isDummyField: true,
            headerClasses: 'header-protocol-actions',
            classes: 'row-protocol-actions',
            formatter: function (_cell, row, rowIndex) {
                return (
                    <div key={rowIndex}>
                        <button className="btn-table-actions" onClick={() => handleRemoveLock(row.id)}>
                            <Tooltip tooltip={t("commons.remove")} id={"tooltipaudit-" + row.id}>
                                <span aria-hidden="true"><Remove /></span>
                            </Tooltip>
                        </button>
                    </div>
                )
            }
        }
    ];

    return (
        <>
            {modal}
            <BootstrapTable
                classes="table-spaced table-spaced-small"
                wrapperClasses="table-responsive"
                bordered={false}
                hover
                remote
                keyField='id'
                data={data}
                columns={columns}
                isLoading={isLoading}
                pagination={paginationFactory({
                    page: query.pagination.page,
                    sizePerPage: query.pagination.sizePerPage,
                    totalSize: totalSize,
                    hideSizePerPage: true
                })}
                onTableChange={handleTableChange}
                noDataIndication={() => <>Sin datos</>}
            />
        </>
    );
}

export const ScheduleLockForm = (props) => {
    const {
        values,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        handleSubmit
    } = props;

    const { t } = useTranslation();
    const [availableTimes, setAvailableTimes] = useState([]);

    useEffect(() => {
        const arr = [];
        for (let i = 0; i < 24; i++) {
            arr.push({ id: i, value: `${i}:00` });
            //for (let j = 0; j < 4; j++) {
            //    arr.push(`${i}:${j === 0 ? `00` : 20 * j}`);
            //}
        }
        setAvailableTimes(arr);
    }, [])

    const handleTypeChange = (controlName, selectedvalue) => {
        if (values.centerOrParticular == 0)
            setFieldValue("practitionerId", null); // Si es centro, blanqueo practitioner. 

        setFieldValue(controlName, selectedvalue);
    }

    const handlePractitionerChange = (controlName, selectedValue) => {
        setFieldValue(controlName, selectedValue);

        if (selectedValue)
            setFieldValue("practitionerId", selectedValue?.userId);
        else
            setFieldValue("practitionerId", null);
    }

    const handleTimeChange = (controlName, selectedValue) => {
        if (!moment.isMoment(selectedValue))
            return;

        if (controlName === "from")
            setFieldValue("lockFrom", selectedValue.format("HH:mm"));

        if (controlName === "to")
            setFieldValue("lockTo", selectedValue.format("HH:mm"));
    }

    return (
        <Modal show={true} onHide={props.onCancel} backdrop='static' bsSize="large">
            <Modal.Header closeButton>
                <Modal.Title>{t('calendar.newScheduleLock')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit} autoComplete="off">
                    <Grid fluid>
                        <Row>
                            <Col md={3}>
                                <ControlLabel>¿Centro o Profesional?</ControlLabel>
                                <CustomRadio
                                    number="center"
                                    option={0}
                                    name="centerOrParticular"
                                    onChange={handleTypeChange}
                                    checked={values.centerOrParticular == 0}
                                    label="Centro Médico"
                                />
                                <CustomRadio
                                    number="particular"
                                    option={1}
                                    name="centerOrParticular"
                                    onChange={handleTypeChange}
                                    checked={values.centerOrParticular == 1}
                                    label="Profesional"
                                />
                            </Col>
                            {
                                values.centerOrParticular == 1 &&
                                <Col sm={6}>
                                    <FormGroup>
                                        <ControlLabel>Profesional</ControlLabel>
                                        <PractitionerPicker
                                            id="practitioner"
                                            name="practitioner"
                                            autoFocus
                                            value={values.practitioner}
                                            multiple={false}
                                            onlyDoctor={true}
                                            placeholder={t("commons.select")}
                                            onChange={handlePractitionerChange}
                                            onBlur={setFieldTouched}
                                        />
                                    </FormGroup>
                                </Col>
                            }
                        </Row>
                        <Row>
                            <Col md={3}>
                                <ControlLabel>¿Día o Rango?</ControlLabel>
                                <CustomRadio
                                    number="day"
                                    option={0}
                                    name="dayOrRange"
                                    onChange={setFieldValue}
                                    checked={values.dayOrRange == 0}
                                    label="Día"
                                />
                                <CustomRadio
                                    number="range"
                                    option={1}
                                    name="dayOrRange"
                                    onChange={setFieldValue}
                                    checked={values.dayOrRange == 1}
                                    label="Rango"
                                />
                            </Col>

                            {
                                values.dayOrRange && (values.centerOrParticular == 0 || (values.centerOrParticular == 1 && values.practitioner)) &&
                                <>
                                    <Col sm={9}>
                                        <FormGroup>
                                            <Row>
                                                <div className="col-xs-6">
                                                    <ControlLabel>Inicio</ControlLabel>
                                                    <CustomDate
                                                        name="start"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        closeOnSelect={true}
                                                        inputProps={{ placeholder: "Día Inicio" }}
                                                        isValidDate={(current) => validateIsSameOfAfter(current)}
                                                    />
                                                    <ErrorMessage name="start" component={FormErrorMessage} />
                                                </div>
                                                <div className="col-xs-6">
                                                    <ControlLabel>Horario</ControlLabel>
                                                    <CustomTime
                                                        name="from"
                                                        onChange={handleTimeChange}
                                                        onBlur={setFieldTouched}
                                                        closeOnSelect={true}
                                                    />
                                                    <ErrorMessage name="lockFrom" component={FormErrorMessage} />
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="col-xs-6">
                                                    <ControlLabel>Fin</ControlLabel>
                                                    <CustomDate
                                                        name="end"
                                                        disabled={!values.start || values.dayOrRange != 1}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        closeOnSelect={true}
                                                        inputProps={{ placeholder: "Día Fin" }}
                                                        isValidDate={(current) => validateIsSameOfAfter(current, values.start)}
                                                    />
                                                    <ErrorMessage name="end" component={FormErrorMessage} />
                                                </div>
                                                <div className="col-xs-6">
                                                    <ControlLabel>Horario</ControlLabel>
                                                    <CustomTime
                                                        name="to"
                                                        onChange={handleTimeChange}
                                                        onBlur={setFieldTouched}
                                                        closeOnSelect={true}
                                                    />
                                                    <ErrorMessage name="lockTo" component={FormErrorMessage} />
                                                </div>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup>
                                                <ControlLabel>Motivo</ControlLabel>
                                                <CustomFormControl
                                                    type="textarea"
                                                    name="comments"
                                                    value={values.comments}
                                                    placeholder={t('calendar.notes')}
                                                    rows={3}
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </>
                            }
                        </Row>
                    </Grid>
                    <div className="modal-footer">
                        <Button
                            bsStyle="success"
                            type="submit"
                            disabled={!values.start || isSubmitting}
                        >
                            <Save /> {t("buttons.save")}
                        </Button>
                    </div>
                </form>
                <ScheduleLocks />
            </Modal.Body>
        </Modal >
    );
}

ScheduleLockForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export const ScheduleLockAppointmentsTaken = (props) => {
    const { appointmentsTaken } = props;
    const { t } = useTranslation();

    let components = null;
    if (appointmentsTaken) {
        components = appointmentsTaken.map((event, index) => {
            let start = moment.utc(moment.utc(event.start)).local().toDate();
            let end = moment.utc(moment.utc(event.end)).local().toDate();

            return (
                <tr key={index} className="daily-agenda-row" style={{ borderLeft: `6px solid ${visitTypeColorGetter(event.visitType?.code)}` }}>
                    <td>
                        <table>
                            <tbody>
                                <tr>
                                    <td className="card-desc">
                                        {moment(start).format('DD/MM/YYYY')} {moment(start).format('H:mm')} - {moment(end).format('H:mm')} [{event.visitType?.title}]
                                    </td>
                                </tr>
                                <tr>
                                    <td className="card-text">{event.patient.fullName} - {event.practitioner.fullName}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    {/*<td style={{ width: '120px' }}>
                        <div className="daily-agenda-options">
                            <NavLink to={`/admin/medicalRecords/${event.patient.medicalRecordNumber}`} activeClassName="active">
                                <Tooltip tooltip={event.present ? t('calendar.patientPresent') : t('database.viewMR')} id={"tooltipadd-" + event.patient.medicalRecordNumber}>
                                    {
                                        event.present ?
                                            <WaitingRoom /> :
                                            <ViewMR />
                                    }
                                </Tooltip>
                            </NavLink>
                            <NavLink to={`/admin/agenda?medicalRecordNumber=${event.patient.medicalRecordNumber}`} activeClassName="active">
                                <Tooltip tooltip={t('medicalRecords.actionNewAppointment')} id={"tooltipaddAppointment-" + event.patient.medicalRecordNumber}>
                                    <AddAppointment />
                                </Tooltip>
                            </NavLink>
                        </div>
                                </td>*/}
                </tr >
            )
        });
    }

    return (
        <ModalData
            isShowing={true}
            hide={props.onClose}
            title={"¡Reagendar o Cancelar Turnos!"}
        >
            <Card
                title="Los siguientes turnos necesitan ser reagendados o cancelados:"
                className="card-daily-widget"
                content={
                    <>
                        <table className="table table-responsive">
                            <tbody>
                                {components}
                            </tbody>
                        </table>
                    </>
                }
            />
        </ModalData>

    )
}

