import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import React from "react";
import Card from "../../Cards/Card";
import { AuditTooltip } from '../../CustomControls/AuditTooltip';
import { RichTextControl } from '../../CustomControls/RichTextControl';
import { FormErrorMessage } from '../../Validations/FormValidationErrors';

export const Entry = (props) => {
    const {
        values,
        setFieldValue,
        setFieldTouched,
        isDisabled,
        title,
        subtitle,
        placeholder,
        audit
    } = props;

    return (
        <Card
            title={title}
            subtitle={subtitle}
            content={
                <div>
                    {audit && <AuditTooltip id="visitText" text={audit} />}
                    <RichTextControl
                        autoFocus={true}
                        name="text"
                        isDisabled={isDisabled}
                        placeholder={placeholder}
                        style={props.style}
                        value={values.text ?? ""}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        toolbar={
                            [
                                {
                                    name: 'history', items: ['undo', 'redo']
                                },
                                {
                                    name: 'clear', items: ['removeformat']
                                }
                            ]
                        }
                    />
                    <ErrorMessage name={`text`} component={FormErrorMessage} />
                </div>
            }
        />
    );
}

Entry.defaultProps = {
    title: "Evolución"
}

Entry.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    placeholder: PropTypes.string,
    isDisabled: PropTypes.bool,
    values: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
};