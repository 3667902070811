import React from 'react';
import { Label } from 'react-bootstrap'
import moment from 'moment';
import { IconSVG } from '../Icons/Icons';

export const dateFormatter = (dateTime, format, withIcon) => {
  if (!format)
    format = 'DD/MM/YYYY';

  const icon = withIcon ? <IconSVG name="date" /> : null;

  if (dateTime)
    return (<span className="date-formatter">{icon} {moment(dateTime).format(format)} </span>);

  return (<span className="date-formatter"> </span>);
}

export const labelFormatter = (value, style) => {

  if (!style)
    style = "default";
  if (value)
    return (<Label bsStyle={style}>{value}</Label>);

  return null;
}

export const dateTimeFormatter = (dateTime) => {
  if (dateTime)
    return (<span className="datetime-formatter"> {moment(dateTime).format('DD/MM/YYYY HH:mm')} </span>);

  return (<span className="datetime-formatter"> </span>);
}

export const timeFormatter = (time) => {
  if (time)
    return (<span className="datetime-formatter"> {moment(time).format('HH:mm')} </span>);

  return (<span className="datetime-formatter"> </span>);
}

export const boolFormatter = (value, translationFn) => {

  if (value !== null || value !== undefined && translationFn) {
    if (value)
      return (translationFn('commons.yes'));
    return (translationFn('commons.no'));
  }

  return "";
}

export const yesNoFormatter = (value, translationFn) => {

  if (value === null || value === undefined && !translationFn)
    return "";

  if (value === 'yes' || value)
    return translationFn('commons.yes')

  return translationFn('commons.no');
}

export const yesNoNotApplyFormatter = (value, translationFn) => {

  if (value === null || value === undefined && !translationFn)
    return "";

  switch (value) {
    case "yes": return translationFn('commons.yes');
    case "no": return translationFn('commons.no');
    case "n/a": return translationFn('commons.na');
    default:
      return "";
  }
}

export const YesNoFormatter = (cell, _row, _rowIndex, formatExtraData) => {

  return (
    <div>
      {
        cell === true ?
          formatExtraData.t('commons.yes') :
          formatExtraData.t('commons.no')
      }
    </div>
  );
}

export const objectFormatter = (value) => {

  if (value !== null || value !== undefined) {
    if (value.hasOwnProperty("ConceptId")) {
      return value["Description"];
    }
    if (value.hasOwnProperty("Code")) {
      return value["Title"];
    }
  }

  return "";
}

export const extractContentFromHTML = (html) => {
  return new DOMParser().parseFromString(html, "text/html").documentElement.textContent;
}