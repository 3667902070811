import React from 'react';
import PropTypes from 'prop-types';
import { useAuthorization } from './useAuthorization';

export const WithAuth = props => {
    const { requiredPermission } = props;
    const [isAuthenticated, , isAuthorized] = useAuthorization();

    if (!isAuthenticated) {
        return '';
    }

    if (isAuthorized(requiredPermission))
        return <>{props.children}</>;

    return "";
}

WithAuth.propTypes = {
    requiredPermission: PropTypes.array,
};