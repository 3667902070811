import React, { useState } from "react";

import { useAnthropometrics } from "../Hooks/useAnthropometrics";
import { Anthropometrics } from "../../../components/MedicalRecords/Anthropometrics";
import { AuditModal } from "../../../components/AuditTrail/AuditModal";
import { AuditTrail } from "../../Admin/AuditTrail";
import { ExpandableContent } from "../../../components/CustomControls/ExpandableContent";
import { useTranslation } from "react-i18next";
import { ModalAnthropometricsHistory } from "../Modals/ModalAnthropometricsHistory";

export const AnthropometricsListContainer = ({ entryId, medicalRecordNumber, mrn, expandedAll }) => {
    const { t } = useTranslation();
    const [data, query, totalSize, isLoading, handleTableChange] = useAnthropometrics(medicalRecordNumber, entryId);

    const [modal, setModal] = useState(null);
    const handleAuditTrail = (entityId) => {
        setModal(
            <AuditModal
                onClose={() => { setModal(null); }}
                title="Auditoría"
            >
                <AuditTrail entity="Anthropometrics" entityId={entityId} medicalRecordNumber={medicalRecordNumber} />
            </AuditModal>
        )
    }

    const handleHistory = () => {
        setModal(<ModalAnthropometricsHistory
            onClose={() => { setModal(null); }}
            title="Histórico"
            medicalRecordNumber={medicalRecordNumber || mrn}
        />)
    }

    if (totalSize === 0)
        return null;

    return (
        <ExpandableContent title={t("medicalRecords.anthropometrics.title")} content="anthropometrics" expandedAll={expandedAll}>
            {modal}
            <Anthropometrics
                withCard={false}
                data={data}
                query={query}
                totalSize={totalSize}
                isLoading={isLoading}
                onTableChange={handleTableChange}
                onAuditTrail={handleAuditTrail}
                onHistory={handleHistory}
            />
        </ExpandableContent>
    );
}