/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { Collapse } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.jsx";
import logo from "../../assets/img/logo-blanco.png";

import routes from "../../routes.js";
import { useAuthorization } from "../Authorization/useAuthorization.js";
import { IconSVG } from "../Icons/Icons.jsx";

var ps;

export const Sidebar = (props) => {
  const refSidebarWrapper = useRef(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [collapseStates, setCollapseStates] = useState({});
  const [, , isAuthorized] = useAuthorization();

  useEffect(() => {
    // add event listener for windows resize
    window.addEventListener("resize", updateDimensions());
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1 && refSidebarWrapper) {
      ps = new PerfectScrollbar(refSidebarWrapper.current);
    }

    updateDimensions();

    return function cleanup() {
      // we need to destroy the false scrollbar when we navigate
      // to a page that doesn't have this component rendered
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  }, [])

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.routes),
          ...getCollapseStates(prop.routes),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/routes/forms/RegularForms.jsx - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].routes)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((prop, key) => {

      if (prop.hidden) {
        return null;
      }
      if (prop.redirect) {
        return null;
      }

      if (!isAuthorized(prop.permission))
        return null;

      if (prop.displayChilds) {
        var st = {};
        st[prop["state"]] = !collapseStates[prop.state];
        return (
          <li key={key}>
            <a href="#" onClick={e => { e.preventDefault(); setCollapseStates(st); }} style={{ textAlign: 'center' }}>
              <IconSVG name={prop.iconSVG} />
              <div>
                <div className="item-name">{prop.name}</div>
                <b className={collapseStates[prop.state] ? "caret rotate-180" : "caret"} />
              </div>
            </a>
            <Collapse in={collapseStates[prop.state]}>
              <ul className="nav">{createLinks(prop.routes)}</ul>
            </Collapse>
          </li>
        );
      }

      return (
        <li className={activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink
            to={prop.layout + prop.path}
            className="nav-link"
            activeClassName="active"
            style={{ textAlign: 'center' }}
          >
            {prop.iconSVG ? (
              <>
                <IconSVG name={prop.iconSVG} />
                <div className="item-name">{prop.name}</div>
              </>
            ) : (
                <>
                  <span className="sidebar-normal">{prop.name}</span>
                </>
              )}
          </NavLink>
        </li>
      );
    });
  };

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  // if the windows width changes CSS has to make some changes
  // this functions tell react what width is the window
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  }

  return (
    <div className="sidebar">
      <div className="logo">
        <a href="/">
          <div className="logo-img">
            <img src={logo} alt="logo_image" />
          </div>
        </a>
      </div>

      <div className="sidebar-wrapper" ref={refSidebarWrapper}>
        <ul className="nav">
          {/*
            If we are on responsive, we want both links from navbar and sidebar
            to appear in sidebar, so we render here HeaderLinks
          */}
          {width <= 992 ? <AdminNavbarLinks /> : null}
          {/*
            here we render the links in the sidebar if the link is simple,
            we make a simple link, if not, we have to create a collapsible group,
            with the speciffic parent button and with it's children which are the links
          */}
          {createLinks(routes)}
        </ul>
      </div>
    </div>
  );
}