import React from "react"
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import SweetAlert from "react-bootstrap-sweetalert"
import { Grid, Row, Col } from 'react-bootstrap'

export const RemoveVisit = (props) => {
    const { t } = useTranslation();

    return (
        <Grid fluid>
            <Row>
                <Col md={12}>
                    <SweetAlert
                        warning
                        title={t("protocolNotifications.removeVisit")}
                        onConfirm={() => props.onConfirm(props.visitId)}
                        onCancel={() => { props.onClose() }}
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="info"
                        confirmBtnText={t("buttons.delete")}
                        cancelBtnText={t("buttons.cancel")}
                        showCancel
                    >
                        <span>Are you sure?</span>
                    </SweetAlert>
                </Col>
            </Row>
        </Grid>
    );
}

RemoveVisit.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    visitId: PropTypes.string.isRequired,
};


export const RemoveForm = (props) => {
    const { t } = useTranslation();

    return (
        <Grid fluid>
            <Row>
                <Col md={12}>
                    <SweetAlert
                        warning
                        title={t("protocolNotifications.removeForm")}
                        onConfirm={() => props.onConfirm(props.id)}
                        onCancel={() => { props.onClose() }}
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="info"
                        confirmBtnText={t("buttons.delete")}
                        cancelBtnText={t("buttons.cancel")}
                        showCancel
                    >
                        <span>Are you sure?</span>
                    </SweetAlert>
                </Col>
            </Row>
        </Grid>
    );
}

RemoveForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    visitId: PropTypes.string.isRequired,
};


export const RemoveModal = (props) => {
    const { t } = useTranslation();
    return (
        <Grid fluid>
            <Row>
                <Col md={12}>
                    <SweetAlert
                        warning
                        title={props.title}
                        onConfirm={() => props.onConfirm(props.id)}
                        onCancel={() => { props.onClose() }}
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="info"
                        confirmBtnText={t("buttons.delete")}
                        cancelBtnText={t("buttons.cancel")}
                        showCancel
                    >
                        <span>{props.subtitle}</span>
                    </SweetAlert>
                </Col>
            </Row>
        </Grid>
    );
}

RemoveModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
};

export const PublishModal = (props) => {
    const { t } = useTranslation();
    return (
        <Grid fluid>
            <Row>
                <Col md={12}>
                    <SweetAlert
                        input
                        inputType="textarea"
                        placeholder="Ingrese comentarios..."
                        required
                        validationMsg="Ingrese comentarios."
                        title={props.title}
                        onConfirm={(value) => props.onConfirm(value, props.id)}
                        onCancel={() => { props.onClose() }}
                        confirmBtnBsStyle="primary"
                        cancelBtnBsStyle="info"
                        confirmBtnText={t("buttons.publish")}
                        cancelBtnText={t("buttons.cancel")}
                        showCancel
                    >
                        <span>{props.subtitle}</span>
                    </SweetAlert>
                </Col>
            </Row>
        </Grid>
    );
}

PublishModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
};