import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { useTranslation, withTranslation } from 'react-i18next';
import { AuditModal, CreateAudit, CreateAuditModal } from "../../../components/AuditTrail/AuditModal";
import { ExpandableContent } from "../../../components/CustomControls/ExpandableContent";
import { PrescriptionsForm } from "../../../components/MedicalRecords/Forms/PrescriptionsForm";
import { Prescriptions } from '../../../components/MedicalRecords/Prescriptions';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';
import { getMedicalRecordsSchema } from '../../../components/Validations/FormValidationSchema';
import { AuditTrail } from "../../Admin/AuditTrail";
import { useDrug, useDrugs } from "../Hooks/useDrugs";
import { usePrescription, usePrescriptions } from '../Hooks/usePrescriptions';
import { ModalData } from '../Modals/ModalData';

export const PrescriptionsContainer = (props) => {
    const [content, setContent] = useState(null);
    const { entryStatus, entryId, medicalRecordNumber, expandedAll } = props;
    const [prescriptions, query, totalSize, isLoading, handleTableChange, setReload] = usePrescriptions(medicalRecordNumber, null);
    const [, , , , , print] = usePrescription();
    const { t } = useTranslation();

    useEffect(() => {
        if (props.contentToReload?.contentName === "prescriptions")
            setReload(true);
    }, [props.contentToReload]);

    const handleEdit = (id) => {
        setContent(
            <ModalData
                isShowing={true}
                hide={handleClose}
                title={t(`medicalRecords.prescriptions.title`)}
            >
                <PrescriptionEditContainer
                    medicalRecordNumber={medicalRecordNumber}
                    entryId={entryId}
                    entryStatus={entryStatus}
                    id={id}
                    patient={props.patient}
                    onSubmit={handleSubmit}
                    onCancel={handleClose}
                />
            </ModalData>);
    }

    const handleCopy = async (_entryId2, id) => {
        setContent(
            <ModalData
                isShowing={true}
                hide={handleClose}
                title={t(`medicalRecords.prescriptions.title`)}
            >
                <PrescriptionCopyContainer
                    medicalRecordNumber={medicalRecordNumber}
                    entryId={entryId}
                    entryStatus={entryStatus}
                    id={id}
                    patient={props.patient}
                    onSubmit={handleSubmit}
                    onCancel={handleClose}
                />
            </ModalData>);
    }

    const handleSubmit = () => {
        setContent(null);
        setReload(true);
    }

    const handleClose = () => {
        setContent(null);
    }

    const handleAuditTrail = (entityId) => {
        setContent(
            <AuditModal
                onClose={() => { setContent(null); }}
                title="Auditoría"
            >
                <AuditTrail entity="PrescriptionsItems" entityId={entityId} medicalRecordNumber={medicalRecordNumber} />
            </AuditModal>
        )
    }

    const handlePrint = async (entryId2, id) => {
        try {
            var response = await print(medicalRecordNumber, entryId2, id, null);

            const blob = response.data;
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        }
        catch (error) {
            HandleApiError(error);
            console.log(error);
        };
    }

    if (totalSize === 0)
        return null;

    return (
        <ExpandableContent title={t("medicalRecords.prescriptions.title")} expandedAll={expandedAll} content="prescriptions">
            {content}
            <Prescriptions
                withCard={false}
                entryId={entryId}
                data={prescriptions}
                query={query}
                totalSize={totalSize}
                isLoading={isLoading}
                onTableChange={handleTableChange}
                //onEdit={prescriptions.length > 0 && prescriptions[0].entryId == entryId ? handleEdit : null}
                onEdit={handleEdit}
                onAuditTrail={handleAuditTrail}
                onPrint={handlePrint}
                onCopy={handleCopy}
            />
        </ExpandableContent>
    );
}

export const PrescriptionsCreateContainer = (props) => {
    const { entryStatus, entryId, medicalRecordNumber, onSubmit } = props;
    const [, masterData, isLoadingMasterData] = useDrug(medicalRecordNumber, entryId);
    const [drugs, , , isLoadingDrugs] = useDrugs(medicalRecordNumber, entryId, true);
    const [, , , create] = usePrescription();
    const [content, setContent] = useState(null);

    const handleSubmit = async (data) => {

        if (entryStatus === "Published" || entryStatus === "AutoPublished") {
            setContent(<CreateAudit
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmit(data, reason, comments)}
            />)
        }
        else {
            onConfirmSubmit(data);
        }
    }

    const onConfirmSubmit = async (data, reason, comments) => {
        try {
            if (reason) {
                data.auditReason = reason;
                data.auditComments = comments;
            }
            data.prescriptions.forEach(x => { (!x.reasonId && x.reason && (x.reasonId = x.reason.id)); x.reason = null; }); // Mapeo Reason
            //console.log(data)
            await create(entryId, data);
            setContent(null);
            onSubmit("prescriptions")
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleCancel = () => {
        props.hide()
    }

    if (!masterData || isLoadingMasterData || isLoadingDrugs)
        return null;

    return (
        <>
            {
                content ??
                <PrescriptionContainerFormik
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    data={masterData.data}
                    events={masterData.events}
                    drugs={drugs}
                    patient={props.patient}
                />
            }
        </>

    );
}

const PrescriptionCopyContainer = (props) => {
    const { medicalRecordNumber, entryId, entryStatus, id, onSubmit, onCancel } = props;
    const [data, masterData, isLoading, create] = usePrescription(medicalRecordNumber, entryId, id);
    const [content, setContent] = useState(null);

    const handleSubmit = async (data) => {

        if (!data || !data.prescriptions || data.prescriptions.length === 0)
            return;

        if (entryStatus === "Published" || entryStatus === "AutoPublished") {
            setContent(<CreateAudit
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmit(data, reason, comments)}
            />)
        }
        else {
            onConfirmSubmit(data);
        }
    }

    const onConfirmSubmit = async (data, reason, comments) => {
        try {
            if (data && data.prescriptions && data.prescriptions.length > 0) {
                if (reason) {
                    data.auditReason = reason;
                    data.auditComments = comments;
                }
                data.prescriptions.forEach(x => { (!x.reasonId && x.reason && (x.reasonId = x.reason.id)); x.reason = null; }); // Mapeo Reason
                //console.log(data)
                await create(entryId, data);
                setContent(null);
                onSubmit("prescriptions");
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    if (!data || !masterData || isLoading)
        return null;

    data.prescriptionDate = new Date();
    return (
        <>
            {content}
            <PrescriptionContainerFormik
                onSubmit={handleSubmit}
                onCancel={onCancel}
                data={masterData.data}
                events={masterData.events}
                prescriptions={data}
                patient={props.patient}
            />
        </>
    );
}

const PrescriptionEditContainer = (props) => {
    const { medicalRecordNumber, entryId, entryStatus, id, onSubmit } = props;
    const [data, masterData, isLoading, , update] = usePrescription(medicalRecordNumber, entryId, id);
    const [content, setContent] = useState(null);

    const handleSubmit = async (data) => {

        if (!data || !data.prescriptions || data.prescriptions.length === 0)
            return;

        if (entryStatus === "Published" || entryStatus === "AutoPublished" || entryId != data.prescriptions[0].entryId) {
            setContent(<CreateAudit
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmit(data, reason, comments)}
            />)
        }
        else {
            onConfirmSubmit(data);
        }
    }

    const onConfirmSubmit = async (data, reason, comments) => {
        try {
            if (data && data.prescriptions && data.prescriptions.length > 0) {
                if (reason) {
                    data.auditReason = reason;
                    data.auditComments = comments;
                }
                data.prescriptions.forEach(x => { (!x.reasonId && x.reason && (x.reasonId = x.reason.id)); x.reason = null; }); // Mapeo Reason
                await update(entryId, id, data)
                onSubmit();
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    if (!data || !masterData || isLoading)
        return null;

    return (
        <>
            {
                content ||
                <PrescriptionContainerFormik
                    onSubmit={handleSubmit}
                    onCancel={props.onCancel}
                    data={masterData.data}
                    events={masterData.events}
                    prescriptions={data}
                    patient={props.patient}
                />
            }
        </>
    );
}

const getFormattedData = (props, data) => {
    return data?.map((drug) => {
        if (typeof drug.frequency === 'string') {
            drug.unit = props.data.units.filter(x => x.title == drug.unit)[0];
            drug.frequency = props.data.frequencies.filter(x => x.title == drug.frequency)[0];
        }
        drug.drug = drug.name;
        drug.posology = drug.dose;
        drug.dose = null;
        drug.notes = `${drug.posology ?? ''} ${drug.unit?.description ?? ''} ${drug.frequency?.description ?? ''}`;
        return drug;
    });
}

const PrescriptionContainerFormik = withTranslation()(withFormik({
    mapPropsToValues: (props) => ({
        prescriptionDate: props.prescriptions?.prescriptionDate ?? new Date(),
        medicalInsurance: props.prescriptions?.medicalInsurance ?? props.patient?.medicalInsurance,
        medicalInsurancePlan: props.prescriptions?.medicalInsurancePlan ?? props.patient?.medicalInsurancePlan,
        medicalInsuranceNumber: props.prescriptions?.medicalInsuranceNumber ?? props.patient?.medicalInsuranceNumber,
        justification: props.prescriptions?.justification,
        type: props.prescriptions?.type ?? 0,
        prescriptions: props.prescriptions?.prescriptions ?? getFormattedData(props, JSON.parse(JSON.stringify(props.drugs)))
    }),

    validationSchema: getMedicalRecordsSchema().prescriptionsEdit,

    handleSubmit: (values, formikBag) => {
        formikBag.props.onSubmit(values);
    },

    displayName: 'PrescriptionsForm',

})(PrescriptionsForm));

PrescriptionContainerFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    drugs: PropTypes.array,
    data: PropTypes.object
};