import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { useTranslation, withTranslation } from 'react-i18next';
import { putPersonalHistory } from '../../../api/medicalRecord';
import { AuditModal, CreateAudit, CreateAuditModal } from "../../../components/AuditTrail/AuditModal";
import { ExpandableContent } from "../../../components/CustomControls/ExpandableContent";
import { AdverseEvents } from "../../../components/MedicalRecords/AdverseEvents";
import { AdverseEventForm } from "../../../components/MedicalRecords/Forms/AdverseEventsForm";
import { ModalDuplicatedItem } from '../../../components/MedicalRecords/ModalDuplicatedItem';
import { getMedicalRecordsSchema } from "../../../components/Validations/FormValidationSchema";
import { AuditTrail } from "../../Admin/AuditTrail";
import { usePersonalHistories, usePersonalHistory } from '../Hooks/usePersonalHistory';

export const PersonalHistoryContainer = (props) => {
    const [content, setContent] = useState(null);
    const { medicalRecordNumber, entryStatus, entryId, expandedAll } = props;
    const [personalHistory, query, totalSize, isLoading, handleTableChange, setReload] = usePersonalHistories(medicalRecordNumber, entryId, true);
    const { t } = useTranslation();

    useEffect(() => {
        if (props.contentToReload?.contentName === "personalHistory")
            setReload(true);

    }, [props.contentToReload]);

    const handleEdit = (id) => {
        setContent(<PersonalHistoryEditContainer
            entryId={entryId}
            id={id}
            onSubmit={handleSubmit}
            onCancel={handleClose}
        />);
    }

    const handleSubmit = async (data) => {

        if (!data || !data.adverseEvents || data.adverseEvents.length === 0)
            return;

        if ((entryStatus !== "Published" && entryStatus !== "AutoPublished") && entryId == data.adverseEvents[0].entryId && (!data.adverseEvents[0].originalId || data.adverseEvents[0].id == data.adverseEvents[0].originalId)) {
            onConfirmSubmit(data);
        } else {
            setContent(<CreateAuditModal
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmit(data, reason, comments)}
            />)
        }
    }

    const onConfirmSubmit = async (data, reason, comments) => {
        try {
            if (data && data.adverseEvents && data.adverseEvents.length > 0) {
                let adverseEvent = data.adverseEvents[0];
                if (reason) {
                    adverseEvent.auditReason = reason;
                    adverseEvent.auditComments = comments;
                }
                await putPersonalHistory(entryId, adverseEvent.id, adverseEvent);
                setContent(null);
                setReload(true);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleClose = () => { setContent(null); }

    const handleAuditTrail = (entityId) => {
        setContent(
            <AuditModal
                onClose={() => { setContent(null); }}
                title="Auditoría"
            >
                <AuditTrail entity="PersonalHistory" entityId={entityId} medicalRecordNumber={medicalRecordNumber} />
            </AuditModal>
        )
    }

    if (totalSize === 0)
        return null;

    return (
        <ExpandableContent title={t("medicalRecords.personalHistory.title")} expandedAll={expandedAll} content="personalHistory">
            {content}
            <AdverseEvents
                data={personalHistory}
                entryId={entryId}
                withCard={false}
                query={query}
                totalSize={totalSize}
                isLoading={isLoading}
                onTableChange={handleTableChange}
                onEdit={handleEdit}
                onAuditTrail={handleAuditTrail}
            />
        </ExpandableContent>
    );
}

export const PersonalHistoryCreateContainer = (props) => {
    const { entryStatus, entryId, onSubmit } = props;
    const [, , create,] = usePersonalHistory(entryId);
    const [content, setContent] = useState(null);

    const handleSubmit = async (data) => {

        if (entryStatus === "Published" || entryStatus === "AutoPublished") {
            setContent(<CreateAudit
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmit(data, reason, comments)}
            />)
        }
        else {
            onConfirmSubmit(data);
        }
    }

    const onConfirmSubmit = async (data, reason, comments) => {
        try {
            if (reason) {
                data.auditReason = reason;
                data.auditComments = comments;
            }
            const resp = await create(entryId, data);
            if (resp.data.length > 0)
                setContent(<ModalDuplicatedItem
                    onHide={() => { setContent(null); onSubmit("personalHistory"); }}
                    items={resp.data}
                    item="personalHistory"
                    medicalRecordNumber={props.medicalRecordNumber}
                />);
            else {
                setContent(null);
                onSubmit("personalHistory"); // Indico al padre sobre q contenido hacer Reload. 
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleCancel = () => {
        props.hide()
    }

    return (
        <>
            {
                content ??
                <PersonalHistoryContainerFormik
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                />
            }
        </>
    );
}

const PersonalHistoryEditContainer = (props) => {
    const { entryId, id, onSubmit, onCancel } = props;
    const [data, isLoading] = usePersonalHistory(entryId, id);

    if (!data || isLoading)
        return null;

    return (
        <PersonalHistoryContainerFormik
            onSubmit={onSubmit}
            onCancel={onCancel}
            personalHistory={data}
        />
    );
}

const PersonalHistoryContainerFormik = withTranslation()(withFormik({
    mapPropsToValues: (props) => (props.personalHistory && { adverseEvents: [props.personalHistory] }),

    validationSchema: getMedicalRecordsSchema().adverseEvent,

    handleSubmit: (values, formikBag) => {
        formikBag.props.onSubmit(values);
    },

    displayName: 'AdverseEventForm',

})(AdverseEventForm));

PersonalHistoryContainerFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    personalHistory: PropTypes.object.isRequired,
};