import { ErrorMessage, FieldArray } from 'formik'
import React from 'react'
import { Col, ControlLabel, FormControl, FormGroup, Grid, Panel, PanelGroup, Row } from 'react-bootstrap'
import { snomedExpressions } from '../../../variables/Enums'
import { CustomSelect } from "../../CustomControls/CustomSelect"
import { FromToNullableDatePicker } from "../../CustomControls/DatePicker"
import { SnomedPicker } from '../../CustomControls/SnomedPicker'
import { Remove } from '../../Icons/Icons'
import { FormErrorMessage } from '../../Validations/FormValidationErrors'

export const ProcedureForm = props => {

    const {
        values,
        setFieldValue,
        setFieldTouched,
        handleSubmit,
        handleChange,
        isSubmitting,
        t,
        events
    } = props;

    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };
    const groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    };
    const formatGroupLabel = data => (
        <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );

    const findReasonInEventList = (reasonId, events) => {
        var reason = events[0].options.find(x => x.id === reasonId); // Busco en eventos adversos. 

        if (reason)
            return reason;

        reason = events[1].options.find(x => x.id === reasonId); // Busco en antecedentes personales.
        return reason;
    }

    const emptyResult = {
        name: null,
        reason: null,
        fromYear: null
    };

    if (!values.procedures) {
        values.procedures = [emptyResult];
    }

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <PanelGroup accordion id="multi-items" defaultActiveKey="multi-item-0">
                <FieldArray
                    name="procedures"
                    render={arrayHelper => (
                        <div>
                            {values.procedures && values.procedures.length > 0 && (
                                values.procedures.map((procedure, index) => (
                                    <Panel key={`multi-item-${index}`} eventKey={`multi-item-${index}`}>
                                        <Panel.Heading>
                                            <Panel.Title toggle>{procedure.name ? procedure.name.description : 'Procedimiento'}</Panel.Title>
                                        </Panel.Heading>
                                        <Panel.Body collapsible>
                                            <FormGroup key={index}>
                                                <Grid fluid>
                                                    <Row>
                                                        <Col sm={11}>
                                                            <ControlLabel>{t("medicalRecords.procedures.procedure")}</ControlLabel>
                                                            <SnomedPicker
                                                                id={`procedures.${index}.name`}
                                                                autoFocus
                                                                defaultInputValue={procedure.name ? procedure.name.description : ''}
                                                                value={procedure.name}
                                                                name={`procedures.${index}.name`}
                                                                placeholder={t("commons.search")}
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                conceptId={snomedExpressions.Procedimiento} />
                                                            <ErrorMessage name={`procedures.${index}.name`} component={FormErrorMessage} />
                                                        </Col>
                                                        <Col md={1}>
                                                            <button
                                                                className="btn-table-actions btn-remove"
                                                                style={{ verticalAlign: 'middle' }}
                                                                type="button"
                                                                onClick={() => arrayHelper.remove(index)}>
                                                                <Remove />
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12}>
                                                            <ControlLabel>{t("medicalRecords.procedures.reason")}</ControlLabel>
                                                            <CustomSelect
                                                                defaultValue={findReasonInEventList(procedure.reasonId, events) ?? events[2].options[0]}
                                                                name={`procedures.${index}.reason`}
                                                                placeholder={t("commons.select")}
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                options={events}
                                                                getOptionLabel={(option) => option.adverseEffect}
                                                                getOptionValue={(option) => option.id}
                                                                formatGroupLabel={formatGroupLabel} />
                                                            <ErrorMessage name={`procedures.${index}.reason`} component={FormErrorMessage} />
                                                        </Col>
                                                        <Col sm={12}>
                                                            <FromToNullableDatePicker
                                                                valuesFrom={{ fromDay: procedure.fromDay, fromMonth: procedure.fromMonth, fromYear: procedure.fromYear }}
                                                                nameFromDay={`procedures.${index}.fromDay`}
                                                                nameFromMonth={`procedures.${index}.fromMonth`}
                                                                nameFromYear={`procedures.${index}.fromYear`}
                                                                valuesTo={{ toDay: procedure.toDay, toMonth: procedure.toMonth, toYear: procedure.toYear }}
                                                                nameToDay={`procedures.${index}.toDay`}
                                                                nameToMonth={`procedures.${index}.toMonth`}
                                                                nameToYear={`procedures.${index}.toYear`}
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                            />
                                                            <ErrorMessage name={`procedures.${index}.fromYear`} component={FormErrorMessage} />
                                                            <ErrorMessage name={`procedures.${index}.toYear`} component={FormErrorMessage} />
                                                            <ErrorMessage name={`procedures.${index}.toMonth`} component={FormErrorMessage} />
                                                            <ErrorMessage name={`procedures.${index}.toDay`} component={FormErrorMessage} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12}>
                                                            <ControlLabel>{t("medicalRecords.procedures.notes")}</ControlLabel>
                                                            <FormControl componentClass="textarea" value={procedure.notes || ''} name={`procedures.${index}.notes`} onChange={handleChange} />
                                                        </Col>
                                                    </Row>
                                                </Grid>
                                            </FormGroup>
                                        </Panel.Body>
                                    </Panel>
                                ))
                            )}
                            {
                                (values.procedures.length === 0 || (values.procedures.length > 0 && !values.procedures[0].id)) &&
                                <Row style={{ marginTop: "20px" }}>
                                    <Col md={4}><button type="button" className="btn btn-default" onClick={() => arrayHelper.push(emptyResult)}>{t("buttons.add")}</button></Col>
                                    <Col md={8}></Col>
                                </Row>
                            }
                        </div>
                    )}
                />
            </PanelGroup>
            <button type="button" className="btn btn-default" onClick={props.onCancel} disabled={isSubmitting}>{t("buttons.cancel")}</button>
            {
                values.procedures && values.procedures.length > 0 &&
                <button type="submit" className="btn btn-success btn-right" disabled={isSubmitting}>{t("buttons.save")}</button>
            }

            <div className="clearfix"></div>
        </form >
    );
}