import { useState, useEffect } from "react";
import { HandleApiError } from "../../components/Notifications/APIErrorHandler";
import { getUser, getMasterData, getPractitioners, getUsers, getUserInfo, getUserInfoSignData } from "../../api/identity";

export const useUsers = (query) => {
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalSize, setTotalSize] = useState(0);

    useEffect(() => {
        let isSubscribed = true;

        const fetchData = async () => {
            setIsLoading(true);
            try {
                setIsLoading(true);
                var response = await getUsers(query);

                if (isSubscribed) {
                    const paginatedResult = response.data;
                    setTotalSize(paginatedResult.count);
                    setUsers(paginatedResult.data);
                    setIsLoading(false);
                }
            }
            catch (error) {
                HandleApiError(error);
                setIsLoading(false);
            }
        }

        fetchData();

        return () => isSubscribed = false;
    }, [query]);

    return [users, isLoading, totalSize];
}

export const usePractitioners = (query) => {
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalSize, setTotalSize] = useState(0);

    useEffect(() => {
        let isSubscribed = true;

        const fetchData = async () => {
            setIsLoading(true);
            try {
                setIsLoading(true);
                if (!query) {
                    query = {
                        pagination: {
                            sizePerPage: 100,
                        },
                    }
                }
                var response = await getPractitioners(query);

                if (isSubscribed) {
                    const paginatedResult = response.data;
                    // console.log("GET Users: ", paginatedResult.data);

                    setTotalSize(paginatedResult.count);
                    setUsers(paginatedResult.data);
                    setIsLoading(false);
                }
            }
            catch (error) {
                HandleApiError(error);
                setIsLoading(false);
            }
        }

        fetchData();

        return () => isSubscribed = false;
    }, []);

    return [users, isLoading, totalSize];
}

export const useUser = (userId) => {
    const [user, setUser] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let isSubscribed = true;

        const fetchData = async () => {
            setIsLoading(true);
            try {
                setIsLoading(true);
                var res = await getUser(userId);

                if (isSubscribed) {
                    setUser(res.data);
                    setIsLoading(false);
                }
            }
            catch (error) {
                HandleApiError(error);
                setIsLoading(false);
            }
        }

        fetchData();

        return () => isSubscribed = false;
    }, [userId]);

    return [isLoading, user];
}

export const useUserInfo = (userId) => {
    const [user, setUser] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let isSubscribed = true;
        const fetchData = async () => {

            try {
                setIsLoading(true);
                var res = await getUserInfo(userId);

                if (isSubscribed) {
                    setUser(res.data);
                    setIsLoading(false);
                }
            }
            catch (error) {
                HandleApiError(error);
                setIsLoading(false);
            }
        }

        if (userId)
            fetchData();

        return () => isSubscribed = false;

    }, [userId]);

    return [isLoading, user];
}

export const useUserInfoSignData = (userId) => {
    const [user, setUser] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let isSubscribed = true;
        const fetchData = async () => {

            try {
                setIsLoading(true);
                var res = await getUserInfoSignData(userId);

                if (isSubscribed) {
                    setUser(res.data);
                    setIsLoading(false);
                }
            }
            catch (error) {
                HandleApiError(error);
                setIsLoading(false);
            }
        }

        if (userId)
            fetchData();

        return () => isSubscribed = false;

    }, [userId]);

    return [isLoading, user];
}

export const usePersonMasterData = () => {
    const [masterData, setMasterData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = () => {
            setIsLoading(true);
            getMasterData().then(res => {
                setMasterData(res.data);
                setIsLoading(false);
            }).catch(error => {
                HandleApiError(error);
                setIsLoading(false);
            });
        }

        fetchData();
    }, []);

    return [isLoading, masterData];
}
