import React, { useEffect, useState } from 'react';
import { getVisitsTracking } from '../../api/protocol.js';
import { HandleApiError } from '../../components/Notifications/APIErrorHandler.js';
import { FormHeader } from '../../components/Protocols/Tracking/Forms.jsx';

const VisitHeaderContainer = ({ protocolId, patientId }) => {

    const [visits, setVisits] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const resp = await getVisitsTracking(protocolId);
                var visitTracking = resp.data
                setVisits(visitTracking.visits);
                setIsLoading(false);
            }
            catch (error) {
                console.log(error);
                HandleApiError(error);
                setIsLoading(false);
            }
        }

        fetchData();
    }, [protocolId]);

    if (isLoading || !visits)
        return null;

    return (
        <FormHeader protocolId={protocolId} patientId={patientId} visits={visits} />
    );
}

export default VisitHeaderContainer;