import React from "react";
import PropTypes from 'prop-types';
import { Modal as BootstrapModal } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { AdverseEventCreateContainer } from "../CreateEntryItems/AdverseEventsContainer";
import { AnthropometricCreateContainer } from "../CreateEntryItems/AnthropometricsContainer";
import { DrugCreateContainer } from "../CreateEntryItems/DrugsContainer";
import { FamilyHistoryCreateContainer } from "../CreateEntryItems/FamilyHistoryContainer";
import { PersonalHabitCreateContainer } from "../CreateEntryItems/PersonalHabitsContainer";
import { PersonalHistoryCreateContainer } from "../CreateEntryItems/PersonalHistoryContainer";
import { ProcedureCreateContainer } from "../CreateEntryItems/ProceduresContainer";
import { VitalCreateContainer } from "../CreateEntryItems/VitalsContainer";
import { LaboratoryCreateContainer } from "../CreateEntryItems/LaboratoriesContainer";
import { PrescriptionsCreateContainer } from "../CreateEntryItems/PrescriptionsContainer";
import { OrdersCreateContainer } from "../CreateEntryItems/OrdersContainer";
import { AttachmentsCreateContainer } from "../CreateEntryItems/AttachmentsContainer";

export const ModalAddData = (props) => {
    const { isShowing, hide, contentName } = props;
    const { t } = useTranslation();

    const getContent = () => {
        switch (contentName) {
            case "personalHistory": return (<PersonalHistoryCreateContainer {...props} />);
            case "personalHabits": return (<PersonalHabitCreateContainer {...props} />);
            case "familyHistory": return (<FamilyHistoryCreateContainer {...props} />);
            case "adverseEvents": return (<AdverseEventCreateContainer {...props} />);
            case "drugs": return <DrugCreateContainer {...props} />;
            case "procedures": return <ProcedureCreateContainer {...props} />;
            case "anthropometrics": return <AnthropometricCreateContainer {...props} />;
            case "vitals": return <VitalCreateContainer {...props} />;
            case "laboratory": return <LaboratoryCreateContainer {...props} />;
            case "attachments": return <AttachmentsCreateContainer {...props} />;
            case "prescriptions": return <PrescriptionsCreateContainer {...props} />;
            case "orders": return <OrdersCreateContainer {...props} />;
            default: return null;
        }
    }

    return isShowing ? ReactDOM.createPortal(
        <React.Fragment>
            <BootstrapModal show={true} onHide={hide} bsSize="large" backdrop="static" >
                <BootstrapModal.Header closeButton>
                    <BootstrapModal.Title>{t(`medicalRecords.${contentName}.title`)}</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    {getContent()}
                </BootstrapModal.Body>
            </BootstrapModal>
        </React.Fragment>, document.body
    ) : null;
};

ModalAddData.propTypes = {
    medicalRecordNumber: PropTypes.string,
    patient: PropTypes.object,
    entryStatus: PropTypes.string,
    entryId: PropTypes.string,
    isShowing: PropTypes.bool,
    hide: PropTypes.func,
    contentName: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
};